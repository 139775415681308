import LocalStorageConstats from "../../ApplicationStrConstants/LocalStorageConstants";
import UserStateRecomendation from "../../Models/Report/UserStateRecomendation";
import LocalStorageKeys from "../LocalStorageKeys";

class ReportRecomedationWorkerStorage {

    public GetRecomendation() : UserStateRecomendation | null {
        let userRecomendation : UserStateRecomendation | null = null;
        let userRecomendationJSON = localStorage.getItem(LocalStorageKeys.recomendation);
        if(userRecomendationJSON)
            userRecomendation = JSON.parse(userRecomendationJSON) as UserStateRecomendation;
        return userRecomendation;
    }

    public SetRecomendation(userRecomendation : UserStateRecomendation) : void {
        let userRecomendationJSON  = JSON.stringify(userRecomendation); 
        localStorage.setItem(LocalStorageKeys.recomendation, userRecomendationJSON);
    }


}

export default ReportRecomedationWorkerStorage;