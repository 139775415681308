import DataScopeLinkStatus from "./DataScopeLinkStatus";

class DataScopeLink {
    public static FactoryMethod() : DataScopeLink {
        return new DataScopeLink("", "", "", "", DataScopeLinkStatus.Disconnected);
    }

    constructor(userId : string, employeeId : string, postTime : string, companyName : string, status : DataScopeLinkStatus) {
        this.userId = userId;
        this.employeeId = employeeId;
        this.postTime = postTime;
        this.companyName = companyName;
        this.status = status;
    }

    public userId : string;

    public employeeId : string;

    public postTime : string;

    public companyName : string;

    public status : DataScopeLinkStatus;
}

export default DataScopeLink;