import React, { useEffect } from 'react';
import LoaderImg from "../../Media/Img/load.png";
import "./Preloader.css";


function Preloader(){
    const [angle,useangle]=React.useState(0);

    const angleOfRotation=3;

    let nameSpinerLogo:string="spinerLogo"

    const SpinLogo=() => {
        let myElements:HTMLCollectionOf<Element>=document.getElementsByClassName(nameSpinerLogo);
        let nowElement:HTMLElement=myElements[0] as HTMLElement;
        nowElement.style.transform="rotate("+angle.toString()+"deg)"
    }

    setTimeout(() => {
        useangle(angle+angleOfRotation);
    },0.1)

    useEffect(SpinLogo);

    return(<div className="preloader">
        <div className={nameSpinerLogo}><img className={nameSpinerLogo} src={LoaderImg} alt=""/></div>
    </div>)

}

export default Preloader;