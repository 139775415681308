import Language from "../Models/Users/Language";
import LocalStorageKeys from "./LocalStorageKeys";
import UserWorkerStorage from "./UserWorkerStorage";

class LanguageWorkerStorage{

    private userWorkerStorage : UserWorkerStorage = new UserWorkerStorage();

    public ChangeLanguage(language : Language) {

        try{
            let user = this.userWorkerStorage.GetUserFromLocalStorage();
            user.languageType = language;
            this.userWorkerStorage.WriteUserToLocalStorage(user);
        }
        catch(e){}

        localStorage.setItem(LocalStorageKeys.language, language.toString());

    }

}

export default LanguageWorkerStorage;