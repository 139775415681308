import Couple from "../../Models/Collections/Couple";
import Dictionary from "../../Models/Collections/Dictionary";
import BaseService from "../BaseService";
import RequestSettings from "../RequestProvider/RequestSettings";
import ChatServiceConstants from "./ChatServiceConstants";
import IChatServices from "./IChatServices";

class ChatServices extends BaseService implements IChatServices{


    public async ChangeIsFirstTimeUserArea(value : boolean) : Promise<void> {
        let requestSettings = new RequestSettings(
            ChatServiceConstants._IsFirstStartSignature,
            new Dictionary<string,string>(new Couple("isFirstStart", String(value))),
            null);

        return await this.RequestProvider.RequestAsync(requestSettings);
    }

}

export default ChatServices;