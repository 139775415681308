import AuthUser from "./AuthUser";
import Language from "./Language";

class BaseUser extends AuthUser{

    constructor(
        id : string,
        password : string,
        userName : string,
        email : string,
        evaChatId : string,
        isFirstStart : boolean = true,
        langKey : Language = Language.Fr, 
        circleImage : string = "",
        registrationDate : string = "") {
        super(email,password);
        this.userId = id;
        this.userName=userName;
        this.evaChatId = evaChatId;
        this.isFirstStart = isFirstStart;
        this.languageType = langKey;
        this.circleImage = circleImage;
        this.registrationDate = registrationDate;
    }

    public userId : string

    public userName:string

    public evaChatId : string

    public isFirstStart : boolean

    public circleImage : string;

    public languageType : Language

    public registrationDate : string;

    public set LanguageType(value : Language){
        this.languageType = value;
    }

}

export default BaseUser;