import IUserAction from "../../../Actions/UserActions/IUserAction";
import FullUserInfo from "../../../Models/Users/FullUserInfo";
import IUserProfile from "./IUserProfile";

class AddFullUserProfile implements IUserProfile{
    
    public async UpgradeAccount(user : FullUserInfo, userActons : IUserAction) : Promise<void> {
        return await userActons.AddFullInfo(user);
    }


}

export default AddFullUserProfile;