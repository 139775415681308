import React from "react";
import "./BaseStyle.css";

import Ico from "../../Media/Img/logo_actual_small.png";
import AppContext, { IAppState } from "../../AppContext";

class BaseContainer<IProps, IState> extends React.Component<IProps,IState>{

    constructor(prop:any){
        super(prop);
        this.initLogo();
    }

    initLogo = () => {
        let head = document.getElementsByTagName('head')[0];
        let link = document.createElement('link');
        link.setAttribute('href',Ico);
        link.setAttribute('type','image/x-icon');
        link.setAttribute('rel','shortcut icon');
        head.appendChild(link);
    }



}


export default BaseContainer;
