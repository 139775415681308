import GuidGenerator from "../../Helpers/GuidGenetator";
import BaseMessage from "./BaseMessage";
import ControlCommand from "./ControlCommand";
import MessageQuestion from "./MessageQestion";

class Message extends BaseMessage{

    constructor(id:string,chatId:string,ownerId:string,messageDateTime:Date,text:string, controlCommand : ControlCommand,messageQuestion : MessageQuestion = MessageQuestion.GetMessageQuestion()){
        super(chatId, text);
        this.Id=id;
        this.ownerId=ownerId;
        this.messageDateTime=messageDateTime;
        this.controlCommand = controlCommand;
        this.messageQuestion = messageQuestion;
    }

    public Id:string;


    public ownerId:string;
    

    public Image : string | null = null;

    public messageDateTime:Date;

    public controlCommand : ControlCommand;

    public messageQuestion : MessageQuestion;


    public static CreateMessage(chatId : string, text : string, ownerId : string, messageQuestion : MessageQuestion ,controlCommand : ControlCommand = ControlCommand.Answer):Message{
        let guidGenerator = new GuidGenerator();
        let zeroGuid = guidGenerator.GenerateZeroGuid();
        return new Message(zeroGuid,chatId, ownerId, new Date(), text,controlCommand, messageQuestion);
    }

}

export default Message;