import React from "react";
import RoutesUrl from "../../Helpers/RoutesUrl";
import BaseUser from "../../Models/Users/BaseUser";

function RootComponent(){

    let userJSON = localStorage.getItem("user");
    if(!userJSON || userJSON == "null"){
        window.location.href = RoutesUrl.SignIn;
    }
    else{
        let user = JSON.parse(userJSON) as BaseUser;
        if(user)
            window.location.href = RoutesUrl.Chat + "/" + user.evaChatId;
        else
            window.location.href = RoutesUrl.SignIn;
    }

    return(<React.Fragment></React.Fragment>)
}

export default RootComponent;