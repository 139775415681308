import LocalStorageConstats from "../../../ApplicationStrConstants/LocalStorageConstants";
import ChatHub from "../../../Hubs/Chat/ChatHub";
import Message from "../../../Models/Chat/Message";
import IChatDataSource from "./IChatDataSource";

class LocalStorageDataSource implements IChatDataSource{


    
    public GetMessages(chatId : string) : Promise<Array<Message>>{
        let messages : Array<Message> = [];
        let messagesInStorageJSON = localStorage.getItem(LocalStorageConstats.Messages);
        if(messagesInStorageJSON){
            messages = JSON.parse(messagesInStorageJSON) as Array<Message>;
            messages = messages.filter(item => item.chatId == chatId);
        }
        else{
            throw new Error("messages in not exsit in localStorage");
        }
        return Promise.resolve(messages);
    }



}

export default LocalStorageDataSource;