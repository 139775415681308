import ReportSentiment from "../../Models/Report/ReportsSentiment";
import BaseHubConnection, { IReconnectCallback } from "../BaseHubConnection";
import IReportHub from "./IReportHub";
import ReportContants from "./ReportContants";


export interface IReportRecieveAction {
    (report : ReportSentiment) : void
}

class ReportHub extends BaseHubConnection implements IReportHub{

    constructor(uri : string, token : string, reconnectCallback : IReconnectCallback){
        super(uri, token, reconnectCallback);
    }

    public CreateReport(action : IReportRecieveAction) : void {
        this.hubConnection.on(ReportContants.CreateReport, (report : ReportSentiment) => {
            action(report);
        });
    }



}

export default ReportHub;