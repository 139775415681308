class BaseMessage{

    protected ChatId : string;

    public text : string;

    public get chatId(){
        return this.ChatId;
    }

    public set chatId(value : string){
        this.ChatId = value;
    }

  

    constructor(chatId : string, text : string){
        this.ChatId = chatId;
        this.text = text;
    }

}

export default BaseMessage;