import IUserService from "../../Services/User/IUserService";
import RegistrateUser from "../../Models/Users/RegistrateUser";
import BaseUser from "../../Models/Users/BaseUser";
import AuthUser from "../../Models/Users/AuthUser";
import IStateContainerCallback from "../IStateContainerCallback";
import IUserActions from "./IUserAction";
import Action from "../../Models/Delegate/Action";
import ChangePasswordUser from "../../Models/Users/ChangePasswordUser";
import GetRequestChangePassword from "../../Models/Users/GetRequestChangePassword";
import Language from "../../Models/Users/Language";
import UserService from "../../Services/User/UserService";
import Token from "../../Models/Token";
import RoutesUrl from "../../Helpers/RoutesUrl";
import LocalStorageKeys from "../../LocalStorage/LocalStorageKeys";
import Country from "../../Models/Users/Country";
import CountyTimeZones from "../../Models/Users/CountyTimeZones";
import FullUserInfo from "../../Models/Users/FullUserInfo";
import Dictionary from "../../Models/Collections/Dictionary";
import IProfileServices from "../../Services/Profile/IProfileServices";
import ProfileServices from "../../Services/Profile/ProfileServices";
import RequestProvider from "../../Services/RequestProvider/RequestProvider";
import Couple from "../../Models/Collections/Couple";

import PersonImg from  "../../Media/Img/person.png";
import DataScopeLink from "../../Models/DataScopeLink/DataScopeLink";

class UserActions implements IUserActions {

    private readonly _userService : IUserService;

    private readonly _profileService : IProfileServices;

    constructor() {
        this._userService = new UserService();
        this._profileService = new ProfileServices(new RequestProvider());
    }

    public async RegistrateAsync(
        id : string,
        password:string,
        login:string,
        email:string,
        sucsessRegCallback : IStateContainerCallback,
        errorRegCallback : Action<unknown>,
        langKey : Language,

    ) : Promise<void>{
        let user=new RegistrateUser(id,password,login,email, "", true,langKey);
        await this._userService.RegistrateAsync(user,sucsessRegCallback,errorRegCallback);
    }

    public async RegistrateAsyncFromDatascope(
        id : string,
        password:string,
        login:string,
        email:string,
        sucsessRegCallback : IStateContainerCallback,
        errorRegCallback : Action<unknown>,
        langKey : Language,

    ) : Promise<void>{
        let user=new RegistrateUser(id,password,login,email, "", true,langKey);
        await this._userService.RegistrateAsyncFromDatascope(user,sucsessRegCallback,errorRegCallback);
    }

    public async CheckAuthorizationAndGoToChatAsync() : Promise<void> {
        let token = localStorage.getItem(LocalStorageKeys.token)
        if (!token || token.length === 0) {
            window.location.href = RoutesUrl.SignIn + window.location.search;
            return;
        }

        this.GoToChat();
    }

    public async AuthorizeAsync(email:string, password:string, callback : IStateContainerCallback, unConfirmedEmailCallback : IStateContainerCallback){
        let user:AuthUser = new AuthUser(email,password);
        await this._userService.AuthorizeAsync(user, this.SuccessAuthRegistrateCallBack.bind(this), callback, unConfirmedEmailCallback );
    }

    private SuccessAuthRegistrateCallBack(obj : unknown):void{
        let token = obj as Token;
        localStorage.setItem(LocalStorageKeys.token, token.authToken);
        this.GoToChat();
    }

    private GoToChat() : void {

        this.GetUserProfile()
            .then(async (nowUser : BaseUser) => {
                let imageModel = await this._profileService.GetUserImage(new Dictionary(new Couple(400, () => {})));
                let circleImage = (imageModel != undefined && imageModel != null && imageModel.circleImageBytes != null) ? imageModel.circleImageBytes : PersonImg;
                nowUser.circleImage = circleImage; 
                localStorage.setItem(LocalStorageKeys.user, JSON.stringify(nowUser));
                window.location.href = RoutesUrl.Chat + "/" + nowUser.evaChatId + window.location.search;
            });
    }

    public async UpdateUserPassword(
        id : string,
        password : string,
        token : string,
        sucsessRegCallback : Action<unknown>,
        errorRegCallback : Action<unknown>

    ) : Promise<void>{
        let passwordUser : ChangePasswordUser = new ChangePasswordUser(id,password,token);
        await this._userService.UpdateUserPassword(passwordUser,sucsessRegCallback,errorRegCallback);
    }

    public async GetUserProfileById(userId :string) : Promise<BaseUser>{
        return await this._userService.GetUserProfileById(userId);
    }

    public async GetUserProfile() : Promise<BaseUser>{
        return await this._userService.GetUserProfile();
    }

    public async DeleteUser() : Promise<boolean> {
        return await this._userService.DeleteUser();
    }

    public async RequestChangePassword (userId : string) : Promise<GetRequestChangePassword> {
        return await this._userService.RequestChangePassword(userId);
    }

    public async ResetPassword(email : string) : Promise<void> {
        await this._userService.ResetPassword(email);
    } 

    public async UpdateUserInfo(user : BaseUser, errorHandler : IStateContainerCallback) : Promise<void> {
        await this._userService.UpdateUserInformation(user,() => {}, errorHandler);
    }

    public async GetCountries() : Promise<Array<Country>> {
        return await this._userService.GetCountries();
    }

    public async GetCountriesTimeZones() : Promise<Array<CountyTimeZones>> {
        return await this._userService.GetCountriesTimeZones();
    }

    public async GetFullProvile(actions : Dictionary<number, Action<unknown>>) : Promise<FullUserInfo> {
        return await this._userService.GetFullProvile(actions);
    }

    public async UpdateFullInfo(fullUserInfo : FullUserInfo) : Promise<void> {
        return await this._userService.UpdateUserFullProfile(fullUserInfo);
    }

    public async AddFullInfo(fullUserInfo : FullUserInfo) : Promise<void> {
        return await this._userService.AddUserFullProfile(fullUserInfo);
    }

    public async GetLinkInfo() : Promise<DataScopeLink> {
        return await this._userService.GetLinkInfo();
    }

    public async UnLink() : Promise<boolean> {
        return await this._userService.UnLink();
    }

    public async ChangeLanguageAsync(lang : Language) : Promise<void> {
        await this._userService.ChangeLanguageAsync(lang);
    }
}

export default UserActions;