import React,{FormEvent, KeyboardEvent, useEffect} from "react"
import "./MessageTextArea.css"
import Message from "../../Models/Chat/Message";
import Chat from "../../Containers/Chat/Chat";

interface IMessageTextArea{
    message:Message
    changeMessageValue(message:Message):void
    userAction(message : Message, chatContainer : Chat):void
    chatContainer : Chat
    placeholder : string
}

function MessageTextArea(props:IMessageTextArea){


    let pressedKeys = new Set();

    let lineBreakCodes = ["Enter", "CTRL"];

    let idMessageText = "textAreaMessageText";

    const onChange=(e:FormEvent<HTMLTextAreaElement>) => {
        let nowValue:string=e.currentTarget.value;
        props.message.text=nowValue;
        props.changeMessageValue(props.message);
    }   

    const userAction=(e:KeyboardEvent<HTMLTextAreaElement>) => {
        if(e.key=="Enter" && pressedKeys.size == 0){
            props.userAction(props.message, props.chatContainer);
            e.preventDefault();
        }

        pressedKeys.add(e.key);

        for(let code in lineBreakCodes){
            if(!pressedKeys.has(code)){
                return;
            }
        }

        let element = document.getElementById(idMessageText);
        if(element){
            props.message.text += "\n"+"\r";
            props.changeMessageValue(props.message);
        }

    }

    const keyUp = (e : KeyboardEvent<HTMLTextAreaElement>) => {
        pressedKeys.delete(e.key);
    }

    const focusArea=() => {
        let textArea = document.getElementById(idMessageText);
        if(textArea){
            let textAreaElement = textArea as HTMLTextAreaElement;
            textAreaElement.focus();
        }
    }


    useEffect(() => {
        let width = window.screen.width;
        if(width >= 1024)
            focusArea();
    });

    return(
            <textarea
                id = {idMessageText}
                value={props.message.text}
                onChange={onChange}
                className="mesTextArr"
                onKeyDown={userAction}
                onKeyUp={keyUp}
                placeholder = {props.placeholder}
            >
            </textarea>
    )

}

export default MessageTextArea;