import LinkPart from "../../Models/Link/LinkPart";
import LinkText from "../../Models/Link/LinkText";
import LanguagePhrase from "./LanguagePhrase"
import AppConstants from "../AppConstants";
import Language from "../../Models/Users/Language";

export default class FranchPhrase extends LanguagePhrase{

    lang = Language.Fr;

    account="account";

    widgets="widgets";

    analysis="analysis";

    methodology="methodology";

    database="database";

    emptyArea="Le champ ne doit pas être vide";

    shortPassword='Le mot de passe doit contenir au moins 8 caractéres'

    uncorrectPassword='Votre mot de passe doit contenir au moins une majuscule, un chiffre et un caractère spécial'

    uncorrectEmail='E-mail est incorrect'

    uncorrectPhoneNumber="Le numéro est incorrect "

    firstName="Prénom*"

    lastName="Nom*"

    email="E-mail*"

    phoneNumber="Numéro de téléphone*"

    login="Nom d'utilisateur*"

    password="Mot de passe*"

    privacyPolicyAgree=[
        new LinkText("J’accepte "),
        new LinkPart("les CGU ", AppConstants.getPolicyLink(this.lang)),
        new LinkText("et "),
        new LinkPart("la Politique de protection des données", AppConstants.getPolicyLink(this.lang)),
        new LinkText(", et certifie être majeur")
    ]

    privacyDataAgree = [new LinkText("Je consens expressément à ce que mes données puissent être utilisées dans les datasets de WhyEva")];

    appUseConditions ="Conditions générales d'utilisation de l'application"

    dataProtectionConditions ="Règles de la protection des données personnelles"

    unsuccsesAuth="Vous avez entré un e-mail ou un mot de passe invalide"

    duplicateUserName="Cet identifiant est déjà utilisé"

    duplicateEmail="Cette adresse mail est déjà utilisée"

    signUpBut="S'inscrire"

    signInBut="Se connecter"

    authorization="Connexion "

    registration="Inscription "

    tensity="Niveau de tension"

    anxiety = "Evolution de tension";

    forecast = "Prévision"

    period = "Période"

    currentState = "État en cours"

    dashBoard = "Dashboard"

    settings = "Paramètres"

    export = "Télécharger"

    logOut = "Déconnexion"

    byMonth= "Mois"

    byDay = "Rapport quotidien"

    byYear = "Année"

    byWeek  = "Semaine"

    reportPhrase = "Générer un rapport"

    dateReport = "Date de création du rapport"

    reportNotLoad = "En cours de traitement"

    //на перевод

    users = "Accès collaborateurs"

    balance = "Balance"

    tariff = "Tarifs"

    dataControl = "Données à charger"

    dateActivity = "Dernière connexion"

    position = "Poste occupé"

    name = "Nom d'utilisateur";

    addNewUser = "Ajouter un nouvel utilisateur"

    buyNewUser = "You have no more licenses available. Here you can buy additional licenses."

    cancel = "Retour en arrière"

    configurePlan = "Ajuster le tarif";

    tariffName = "Nom du tarif";

    changeTariff = "Changer le tarif";

    additionally = "En option";

    monthVersion = "Version mensuelle";

    addTariff = "Choisir le tarif";

    count = "Nombre";

    employee = "Employee";

    storage = "Espace de stockage";

    notes = "Notes";

    addDataSet = "Charger les données(.csv)";

    update = "Mettre à jour";

    delete = "Supprimer";

    diskSpace = "L’espace de stockage";

    userCount = "Nombre d’utilisateurs";

    invalidFormatFile = "Format incorrect";

    connected = "Fichier connecté";

    inProcessing  = "Traitement en cours";

    err = "Erreur";

    processed = "Traitement réussi";

    notConnected = "Fichier non-connecté";

    create = "Ajouter";

     //новое

    addedDataSets = "Données chargées";

    structures = "Organigrammes";

    addStructure = "Ajouter un nouvel organigramme";

    enterNameStructure = "Nom de la structure";

    subDepartments = "Services";

    mainDepartment = "Département";

    updateNote = "Mettre à jour le commentaire";

    getDemoVersion = "Avoir l’accès à la version démo";

    areYou = "Vous êtes:";

    //здесь конец нового

    sucsessRegistrate = "Vous vous êtes inscrit avec succès, vérifiez votre e-mail";

    siret = "SIRET";

    legalAddress = "Adresse juridique";

    nameCompany = "Nom d’entreprise";

    officialSite = "Site internet";

    officiaPhoneNumber = "Numéro de téléphone";

    sizeCompany = "Nombre de collaborateurs (salariés):";

    private = "Privé";

    public = "Public";

    professionalSector = "Secteur d’activité";

    activity = "Domaine d'activité ";

    commercialCompany = "Sociétés commerciales";

    stateAndPublicStructures = "État et institutions publiques";

    scienceAndEducation = "Science et éducation";

    more = "Autres activités";

    reason = "Pourquoi souhaitez-vous tester WhyEva Solution ?";

    howDoYouKnow = "Comment avez-vous connu WhyEva ?";

    uncorrectWebSite = "Invalid web-site URL";

    person = "Personne Physique";

    company = "Entreprise";

    health = "Médecine du travail";

    expertise = "Expertise";

    counselling = "Conseil";

    facebook = "Facebook… (précisez svp)";

    linkedIn = "LinkedIn…. (précisez svp)";

    less = "moins de";

    moreThen = "plus de";

    areYouSureLogOut = "Êtes-vous sûr de valoir vous déconnecter ?";

    yes = "Oui";

    no = "Non";

    firstRegDate = "Date de la première inscription";

    secondRegDate = "Date de la deuxième inscription";

    actionDate  = "Date de la dernière connexion";

    action = "Action";

    info = "Information";

    canceled = "Demande rejetée";

    confirmed = "Demande confirmée";

    request = "Demande en cours";

    open = "Ouvrir";

    confirmEmail = "Un lien vous sera envoyé pour confirmer votre adresse mail";

    resetPassword = "Réinitialiser le mot de passe";

    resetPasswordPrompt = "Pour créer le nouveau mot de passe utilisez la même adresse mail qui a servi pour la création de votre compte";

    dataManagment = "Data Managment";

    readyData = "Доступны";

    notExistData = "Отсутствуют";

    inProcessData = "В процессе обработки";

    blockedData = "Заблокированы";

    notAssigned = "Не распредлены";

    readyDateFullText = "Данные есть, расчитаны и доступны для пользователя";

    notExistDataFullText = "Данные по индексам в системе отсутствуют";

    inProcessDateFullText = "Массив обрабатывается и не доступен в отчетах";

    blockedDateFullText = "Данные есть, но к данным нет доступа ни у кого из пользователей";

    notAssignedFullText = "Данные есть, но доступ к ним не назначен никому из пользователей";

    numberDepartment = "Department number";

    reports  = "Rapports";

    selectHierarchy = "Choisissez l’organigramme";

    hierarchies = "Types d’organigramme";

    almostReadyOrNotInsufficientData = "Le rapport est partiellement prêt. Les données ne sont pas disponibles pour l'ensemble de départements ";

    volume = "Nombre de participants";

    obv = "OBV";

    optimistic = "Optimistic scenario";

    real = "Scénario le plus probable";

    pessimistic = "Scénario pessimiste";

    averageByDeps = "La moyenne par service";

    sma = "SMA";

    chat = "Conversation"

    you = "Vous"

    evaBot = "Chatbot Eva"


    greeting = "Bonjour ! Je m'appelle Eva. Ravie de vous rencontrer.\nJe suis ici pour connaître vos ressentis sur les différents aspects de votre vie professionnelle au quotidien, et donner vie à vos meilleures idées.\nDe temps en temps, nous nous retrouverons ici pour parler de sujets importants. Ces enquêtes sont formées spécialement pour vous, alors soyez honnête et formulez des réponses complètes si possible. Notre échange est totalement confidentiel !\nCliquez sur le présent message pour commencer la première enquête.";

    responcePrompt = "Bonjour. Ravie de vous revoir.\nPassons au questionnaire du jour.";

    viewCommandPrompt = "Durant notre conversation, des commandes particulières sont mises à votre disposition. Afin de les consulter, cliquez sur le bouton qui ressemble à cela :";

    choiseTheAction = "Choisissez la consigne";

    desriptionCommand = "Description des commandes :\nPrécédent – revenir à la question précédente\nSondage – refaire le questionnaire depuis le début\nAide – afficher la description des commandes";

    back = "Précédent";

    repeat = "Sondage";

    stop = "/Stop";

    help = "Aide";

    pressToStart = "Pour commencer cliquez ici:";

    commands = ["/Précédent","/Sondage", "/Aide"];

    invalidName = "Prénom ne peut contenir que des lettres ou des chiffres latins ou russes sans espaces";


    chatBotEva = "Chatbot Eva";

    personalReport = "Rapport personnel";

    personalInfo = "Votre profil";

    interfaceLanguage = "Langue d'interface";

    cancelBut = "Abandonner";

    save = "Enregistrer";

    chatbotSettings = "Chatbot settings";

    programVersion = "Version du programme";

    hello = "Bonjour";

    lookAtResults = "Jetez un coup d’œil à vos résultats.";

    recomendation = "Recommandations";

    weatherHint = "C'est la météo du jour de votre état d'esprit. L'échelle se compose de 5 valeurs:"

    partlyCloudy = "Partiellement nuageux";

    stormy = "Orageux";

    cloudy = "Nuageux";

    calm = "Globalement ensoleillé";

    sunny = "Ensoleillé";

    dynamicsOfResults = "Evolution des résultats";

    reportGeneration = "L’élaboration de votre rapport est en cours de traitement, veuillez patienter";

    promptAd = "Soutien psychologique";

    firstPromptAd = "Soutien psychologique. Approche individuelle. Confidentialité garantie";

    secondPromptAd = "Services de psychologue. Approche individuelle. Consultation visio sécurisée et anonyme";

    thirdPromptAd = "Séances avec un psychologue sont accesibles en ligne à tout moment et depuis n'importe quel appareil.";

    servicesBrain = "Les services de psychologue";

    firstStormyRecomendation = "Votre état émotionnel vous précède, vous ressentez des symptômes d’épuisement nerveux, de la tension et de la dépression, alors contactez des professionnels de santé. Ils évalueront votre état plus en détail, vous donneront un soutien et un complément de traitement.";

    secondStormyRecomendation = "En ce moment vous sentez-vous durement éprouvé ou touché par le ralentissement. Il convient de faire attention à votre état émotionnel, vous devriez peut-être discuter avec un ami, lui parler de votre humeur. Dans tous les cas, il faut demander de l’aide à un spécialiste ou un médecin.";

    thirdStormyRecomendation = "Vous faites plusieurs choses en même temps, car il est difficile de se concentrer sur une tâche à la fois. Il semble que la situation devient peu fiable et ça vous fait pleurer. Tant de choses à accomplir, que vous n'arrivez pas à résoudre les problèmes durables. Si vous avez impression d'être au bord de la crise de nerfs, consultez un médecin ou un spécialiste, pour qu'ils vous aident ou proposent un traitement.";

    firstCloudyRecomendation = "Vous savez comment c'est quand l'anxiété n'est pas associé à certains situations de la vie. Vous vous sentez épuisé  physiquement et moralement, malgré le repos du week-end. Manque de l'attention et difficultés de concentration au travail. Vous avez probablement besoin d'aide. Ne restez pas seul(e) face aux difficultés. Essayez d'en parler à un ami proche, à votre famille, encore mieux consulter des spécialistes. ";

    secondCloudyRecomendation = "Toujours de mauvaise humeur, vous manquez de motivation au travail, vous ressentez baissement  d’énergie, fatigue et apathie. Ça semble dû à une migraine, une faiblesse générale ou une difficulté à se détendre après le travail. Réfléchissez à ce qui a causé cet état de santé et cette humeur, vous ne devez en aucun cas le cacher aux autres. Prenez quelques jours de repos ou faites un pique-nique en famille, entre amis le week-end. Il est également souhaitable de consulter les spécialistes.";

    thirdCloudyRecomendation = "Tout vous ennuie et rien ne vous rend heureux. Ce n'est pas agréable d'être fatigué et déprimé, toutefois cet état fait partie intégrante de la vie. Pour contrôler votre humeur, , vous devez peut-être évaluer la charge réelle de travail, reconsidérer vos activités quotidiennes et diversifier vos loisirs. Il ne faut pas percevoir tout ce qui se passe comme des échecs - la vie est belle sous toutes ses formes ! Il serait sage d'en parler avec un spécialiste ou voir un ami.";

    firstPartlyCloudyRecomendation = "En ce moment, vous avez des sauts d'humeur. S'il vous manque de moments positifs et vous voulez du changement, regardez autour de vous, peut-être qu'il y a une opportunité que vous n'aviez pas vue auparavant. Si vous êtes entourés par les gens de confiance, écoutez leurs conseils. Les conseils de vos amis seraient un plus !";

    secondPartlyCloudyRecomendation = "La vie est un changement permanent et vous avez du blanc et du noir. À ces moments, vous avez peut-être des doutes comment y faire face : attendre, prendre des décisions, considérer ce qui se passe comme une occasion de réfléchir. Des hauts et des bas n'arrivent pas qu'à vous. Il n'y a pas de vie sans changement. Rappelez-vous que demain sera toujours meilleur qu'aujourd'hui. N'oubliez pas de passer du travail à la vie personnelle. Passez le week-end entre amis ou en famille, découvrez de nouvelles expériences et aspects positifs.";

    thirdPartlyCloudyRecomendation = "Les changements sont à venir prochainement, mais cela ne veut pas dire que vous les acceptez avec beaucoup d'enthousiasme. Écoutez vos désirs intérieurs, trouvez des vrais objectifs. N'oubliez pas qu'une amélioration dans la vie nécessite quelques changements. Si vous n'arrivez pas à faire un choix ou prendre une décision tant attendue, demandez un conseil aux gens de confiance. Passez plus de temps avec vos amis et vos proches, faites un jogging, écoutez votre voix intérieure !";

    firstCalmRecomendation = "Vous devez prendre une décision, qui demande plus d'efforts, d'attention et du temps. Si les événements semblent développer pour des raisons indépendantes de votre volonté et le résultat n'est pas claire, changez les idées et la routine en allant voir des amis. Faites preuve de savoir-faire et vous pourrez prochainement admirer votre succès !";

    secondCalmRecomendation = "Il arrive un moment dans votre vie, quand vous pouvez vous fixer de nouveaux objectifs ambitieux au travail ou commencer un nouveau projet. Vous allez y arriver avec un peu de patience. Après avoir éliminé tous les obstacles dans vos affaires, n'oubliez pas de se reposer, laissez votre succès progresser graduellement, gardez confiance en votre réussite.";

    thirdCalmRecomendation = "Vos affaires vont bien ! Le travail vous épanouit. Bien que vous avez quelques doutes, vous aurez de la chance dans la vie et la satisfaction de traveaux accomplis. Trouvez du temps pour vos proches, partagez un dîner ou allez au cinéma. Rencontrez des amis, que vous n'avez pas vus depuis long temps ou mangez avec vos collègues.";

    firstSunnyRecomendation = "Étant à l'apogée de vos activités, de nouvelles idée et affaires vous inspirent. Ce temps est très productif pour vous. Les choses sur lesquelles vous avez travaillé si dur et longtemps ont porté ses fruits. Ne vous arrêtez pas, continuez à atteindre vos objectifs. N'oubliez pas de vous amuser, pratiquer un hobby, faire du sport ou se reposer avec des proches.";

    secondSunnyRecomendation = "La vie est faite de petits bonheurs ! Vous faites vos tâches quotidiennes avec une telle aisance et plaisir. Vous avez de nouvelles idées et de nouveaux projets à venir. Profitez de ce temps pour accomplissement de soi. Vous pouvez maintenant se mettre à nouvelles tâches, aider des collègues stagiaires ou améliorer vos compétences de présentation. Développez-vous dans la vie personnelle, découvrez de nouvelles activités, faites du jogging, de la cuisine ou les danses.";

    thirdSunnyRecomendation = "Vous vous êtes faits une place au soleil et vous savez exactement ce que vous voulez de la vie. Elle vous donne de l'énergie et vous en profitez pleinement. Sûr de soi, vous êtes prêts vers accomplissement personnel aussi bien au travail que dans la vie personnelle. Essayez de nouveaux loisirs, par exemple créer un blog, faire du sport ou apprendre à dessiner. Trouvez du temps pour éducation et accomplissement de soi.";

    isAdult = "Je confirme avoir plus de 18 ans";

    confirmPassword = "Confirmer le mot de passe*";

    passwordsNotEqual = "Les mots de passe ne correspondent pas";

    daysOfWeek = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

    birthDate = "Date de naissance";

    femaleStatus = "État civil";

    civilUnion = "PACS";

    commonLawMarriage = "Concubinage";

    divorced = "Divorcé(e)";

    married = "Marié(e)";

    single = "Célibataire";

    widow = "Veuf/Veuve"

    childCount = "Nombre d'enfants";

    nextQuestion = "/Question suivante";

    timeZone = "Fuseau horaire";

    countryOfResidence = "Pays de résidence";

    sex = "Genre";

    male = "Homme";

    female = "Femme";

    notFillSettings = "J’ai remarqué que vous n’avez pas entièrement complété votre profil. Veuillez ajouter les informations manquantes afin d'améliorer la qualité de mon travail.";

    firstPrompt = "Mon objectif est d'évaluer votre qualité de vie au travail et de vous aider à l'améliorer.\nLorsque vous vous connecter à l'application je vais vous poser des questions sur les différents aspects de votre vie professionnelle. Vous trouverez mon feedback dans l'onglet \"Rapport\".\nNotre échange est confidentiel. Donc soyez sincère avec moi, pour que je puisse vous aider au mieux!"

    start = "Commençons, Eva.";

    notReadyRecomendation = "L’élaboration des recommandations est en cours de traitement, veuillez patienter.";


    notSpecified = "Ne pas indiquer";

    reportAboutError = "Contacter WhyEva";

    mandatoryFilling = "*veuillez remplir les champs obligatoires pour un meilleur fonctionnement de chatbot";

    intellectualProperty = "Copyright " + '\u00A9' + " " + new Date().getFullYear() + " - Propriété intellectuelle de WhyEva. Tous droits réservés";

    skip = "Suivant";

    skipQuestion = "Question suivante";


    loadImage = "Ajouter une photo de profil";

    confirm = "Confirmer";

    backNotAction = "Précédent";

    fullImagePrompt = "Le champs choisi va apparaitre comme votre photo de profil.";

    choiseTheFile = "Choisir le fichier";

    circleImagePrompt = "Le champs des petits photos. La photo choisis va être utilisé dans le chat et dans votre profil.";

    choisePeriod = "Choisissez la période";

    enterYourText = "Tapez votre réponse ici";

    deleteAccount = "Supprimer mon compte";

    deleteAccountConfirm = "Êtes-vous sûr de valoir supprimer votre profil?";

    brakeLink = "couper le lien";

    connectionDate = "Date de connexion au système";

    connectionToOrganization = "Entreprise/ date de connexion";

    profileStatus = "Statut du compte";

    unLinkPrompt = "Êtes-vous sûr de valoir rompre le lien?";

    registrationDate = "Date d’inscription"

    dataExchangeAgreement = "Acceptation d’échange de données"
}
