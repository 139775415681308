
interface Condition {
    (value1 : number, value2 : number) : boolean 
}

class ChartData{

    public _name:string;

    public _values:Array<number>

    constructor(name:string,values:Array<number>)
    {
        this._name=name;
        this._values=values;
    }

    private GetValueInArrByCondition(arr : Array<number>, condition : Condition) : number {
        let value = arr[0];
        for(let i = 1; i < arr.length; i++)
            if(condition(value, arr[i]))
                value = arr[i];
        return value;
    }

    public GetMaxValue() : number{
        if(this._values.length == 0 || this._values == undefined || this._values == null)
            throw new Error("empty or null array");
        let maxValue = this.GetValueInArrByCondition(this._values, (value : number, item : number) => {return (value < item && item != NaN)})
        return maxValue;
    }

    public GetMinValue() : number{
        if(this._values.length == 0 || this._values == undefined || this._values == null)
            throw new Error("empty or null array");
        let minValue = this.GetValueInArrByCondition(this._values, (value : number, item : number) => {return (value > item && item != NaN)})
        return minValue;
    }

}

export default ChartData;