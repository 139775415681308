import React, { useEffect, useState } from "react"
import "./HeaderContainer.css"
import LogoPng from "../../Media/Img/logo_actual_main.png";
import IUserAction from "../../Actions/UserActions/IUserAction";
import UserActions from "../../Actions/UserActions/UserActions";
import BaseUser from "../../Models/Users/BaseUser";
import BellImg from "../../Media/Img/bell.png";
import BurgerImgOpen from "../../Media/Img/burgerBlack.png";
import CloseImgPng from "../../Media/Img/closeWhite.png";
import PersonImg from "../../Media/Img/person.png";
import AppCosntants from "../../Helpers/AppConstants";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import AppConstants from "../../Helpers/AppConstants";
import BaseContainer from "../../Containers/BaseContainer/BaseContainer";
import AppContext, { IAppState } from "../../AppContext";
import RoutesUrl from "../../Helpers/RoutesUrl";
import { Link } from "react-router-dom";

interface IHeader{
    textProvider : LanguagePhrase
    heading:string
    isShowLogo : boolean
}

interface IState {
    isOpenSet : boolean

}


class HeaderClass extends BaseContainer<IHeader, IState> {

    private user =JSON.parse(localStorage.getItem("user") as string) as BaseUser;



    constructor(props : any){
        super(props);
        this.state = {
            isOpenSet : false
        }
    }


    clickHandler = () => {
        this.setState({isOpenSet : true});
    }

    onBlur=() => {
       this.setState({isOpenSet : !this.state.isOpenSet});
    }





    render(){

        let userImg = (this.user && this.user.circleImage && this.user.circleImage != PersonImg) ? AppConstants.outputImageFormat + this.user.circleImage : PersonImg;

        let context : IAppState = this.context;

        if(context.uiState.isOpenMenu != this.state.isOpenSet)
            this.setState({isOpenSet : context.uiState.isOpenMenu});

        let logo = (this.props.isShowLogo) ? <a target="_blank" className="logoLink" href = "https://www.whyeva.com/"><img src={LogoPng}/></a> : null;


        return <div className = "mockHeader">
         <header className="commonHeader">
        <div className="leftPartHeaderChat">
            <div id = {AppCosntants.burgerMenuId} className = {(!this.state.isOpenSet) ? "burgerMenuHeader" : "burgerMenuHeaderActive"}>
                <img  src={(!this.state.isOpenSet) ? BurgerImgOpen : CloseImgPng} />
            </div>
            {logo}
        </div>
        <div className="centerPartHeader"></div>
        <div className="headerHeading">{this.props.heading}</div>
        <div className="rightPartHeader">

            <div>{this.props.textProvider.hello + ", " + this.user.userName}</div>
            <Link to = {RoutesUrl.Settings} className="pesronImg">
                <img src={userImg} />
            </Link>
        </div>
    </header>
    </div>
    }

}

HeaderClass.contextType = AppContext;

export default HeaderClass;
