import IRequestProvider from "./RequestProvider/IRequestProvider";

class BaseService{
    constructor(requestProvider : IRequestProvider) {
        this.RequestProvider = requestProvider;
    }

    protected readonly RequestProvider : IRequestProvider;
}

export default BaseService;