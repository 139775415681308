class CheckUserProfileModel{

    constructor(message : string, userProfile : boolean){
        this.message = message;
        this.userProfile = userProfile;
    }

    public message: string

    public userProfile : boolean

}

export default CheckUserProfileModel;