enum FamilyStatus {
     /// <summary>
        /// Single
        /// </summary>
        Single = 1,

        /// <summary>
        /// Married
        /// </summary>
        Married = 2,

        /// <summary>
        /// Common-law Marriage
        /// Concubinage
        /// Совместное проживание
        /// </summary>
        CommonLawMarriage = 3,

        /// <summary>
        /// Civil Union
        /// PACS
        /// Гражданский брак
        /// </summary>
        CivilUnion = 4,

        /// <summary>
        /// Divorced
        /// </summary>
        Divorced = 5,


        /// <summary>
        /// Widow
        /// </summary>
        Widow = 6,

        /// <summary>
        /// Widower
        /// </summary>
        Widower = 7,

        NotFound = 0
}

export default FamilyStatus;