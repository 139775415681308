enum Language{
    En = 1,
    Fr = 2,
    Ru = 3
}

export function getValueByLang<T>(lang : Language, en : T, fr : T, ru : T) {
    switch (lang) {
        case Language.En: return en;
        case Language.Fr: return fr;
        case Language.Ru: return ru;

        default: throw new Error("Such lang not supprted");
    }
}

export default Language;