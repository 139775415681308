class UserImage{

    constructor(id : string, userId : string, imageBytes : string, circleImageBytes : string){
        this.id = id;
        this.userId = userId;
        this.imageBytes = imageBytes;
        this.circleImageBytes = circleImageBytes;
    }

    public id: string

    public userId : string

    public imageBytes : string

    public circleImageBytes : string

}

export default UserImage;