import React from "react";

import "./BoxPersonalReport.css";

interface IBoxPersonalReport{
    height : string
    children : JSX.Element
}

function BoxPersonalReport(props : IBoxPersonalReport){
    return(<div style = {{height : props.height}} className = "BoxPersonalReport">
            {props.children}
          </div>)
}

export default BoxPersonalReport;