import React from "react";

import SignUpUI from "./SignUpUI"
import UserActions from "../../Actions/UserActions/UserActions"
import Validator from "../../Helpers/Validator"
import RegistrationException from "../../Models/Exceptions/RegistrationException";
import BadRequestAnswer from "../../Models/Exceptions/BadRequestAnswer";
import IUserActions, { IRegistrateAsync } from "../../Actions/UserActions/IUserAction";
import TextProvider from "../../Helpers/TextProvider";
import RoutesUrl from "../../Helpers/RoutesUrl";
import Language from "../../Models/Users/Language";
import BaseContainer from "../BaseContainer/BaseContainer";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import UserNameExceptions from "../../Models/Exceptions/UserNameExceptions";
import LocalStorageKeys from "../../LocalStorage/LocalStorageKeys";
import LanguageWorkerStorage from "../../LocalStorage/LanguageWorkerStorage";
import BaseUser from "../../Models/Users/BaseUser";
import Preloader from "../../Components/Preloader/Preloader";
import { RouteComponentProps } from "react-router-dom";
import IRegistrationStrategy from "../../Helpers/Strategies/Registration/IRegistrationStrategy";
import CommonUserRegistrationStrategy from "../../Helpers/Strategies/Registration/CommonUserRegistrationStrategy";
import DatascopeUserRegistrationStrategy from "../../Helpers/Strategies/Registration/DatascopeUserRegistrationStrategy";
import MessageObject from "../../Models/Exceptions/MessageObject";

interface IPropsSigUpParametrs {
    email : string
    employeeId : string
    username : string
}

export interface ISignUpPageState{
    email:string
    login:string
    password:string
    confirmPassword : string
    language : Language
    emailErrorPrompt:string
    loginErrorPrompt:string
    confirmPasswordErrorPrompt : string
    passwordErrorPrompt:string
    isAgree:boolean
    isAdult : boolean
    isLoad : boolean
    isSucsessRegisrtrate : boolean
    languageContext : LanguagePhrase
}

class SignUpPage extends BaseContainer<RouteComponentProps<IPropsSigUpParametrs>, ISignUpPageState>{
    constructor(){
        super(0);
        let language = TextProvider.GetEnumLanguage();
        let user = localStorage.getItem(LocalStorageKeys.user);
        if(user){
            let userModel = JSON.parse(user) as BaseUser;
            if(userModel.languageType)
                language = userModel.languageType;
        }

        this._userActions = new UserActions();

        this._registrationStrategy = new CommonUserRegistrationStrategy(this._userActions);

        this.state={
            email:"",
            login:"",
            password:"",
            confirmPassword : "",
            language : language,
            emailErrorPrompt:"",
            loginErrorPrompt:"",
            passwordErrorPrompt:"",
            confirmPasswordErrorPrompt : "",
            isAgree:false,
            isAdult : false,
            isLoad : true,
            isSucsessRegisrtrate : false,
            languageContext : TextProvider.GetPhrase()
        }
    }

    private readonly _languageWorkerStorage = new LanguageWorkerStorage();


    private readonly _userActions : IUserActions;

    private _registrationStrategy : IRegistrationStrategy;

    private _userId : string = "";
                                        
    _validator:Validator=new Validator();

    componentDidMount = () => {
        let search = window.location.search;
        let parametrs = new URLSearchParams(search);
        if(search != ""){
            let email = parametrs.get("Email");
            if(email == null)
                window.location.href = RoutesUrl.SignIn;
            let login = parametrs.get("UserName");
            if(login == null)
                window.location.href = RoutesUrl.SignIn;
            let userId = parametrs.get("EmployeeId");
            if(userId == null)
                window.location.href = RoutesUrl.SignIn;
            this._registrationStrategy =  new DatascopeUserRegistrationStrategy(this._userActions);
            this._userId = userId as string;
            this.setState({email : email as string,
                           login : login as string});
        } 
    }

    changeStateWithoutError=(name:string,value:any) => {
        this.setState((state) => {
            return{
                ...state,
                [name]:value
            }
        })
    }

    changeState=(name:string,value:string,errorName:string,errorValue:string) => {
        this.setState((state) => {
            return{
                ...state,
                [name]:value,
                [errorName]:errorValue
            }
        })
    }

    changeLanguage = (valueStr : string) => {
        let value = Language[valueStr as keyof typeof Language];
        this._languageWorkerStorage.ChangeLanguage(value);
        this.setState({language : value, languageContext : TextProvider.GetPhrase()});
    }

    changeValue=(value:string,name:string) => {
        let errorPromptValue:string="";
        if(this._validator.isEmpty(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().emptyArea;
        this.changeState(name,value,name+"ErrorPrompt",errorPromptValue)
    }
    
    changeName = (value:string,name:string) => {
        let errorPromptValue:string="";
        if(this._validator.isEmpty(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().emptyArea;
        if(!this._validator.isValidName(value))
            errorPromptValue = TextProvider.GetPhrase().invalidName;
        this.changeState(name,value,name+"ErrorPrompt",errorPromptValue)
    }

    changeEmail=(value:string,name:string="email") => {
        let errorPromptValue:string="";
        if(!this._validator.IsEmailTemplate(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().uncorrectEmail;
        this.changeState("email",value,"emailErrorPrompt",errorPromptValue);
    }

    changePassword=(value:string,name:string="password") => {
        let errorPromptValue:string="";
        if(!this._validator.IsPasswordLenght(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().shortPassword;
        if(!this._validator.IsHaveNumeral(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().uncorrectPassword;
        if((this.state.confirmPassword.trim() == value.trim()))
            this.setState({confirmPasswordErrorPrompt : ""});
        this.changeState("password",value,"passwordErrorPrompt",errorPromptValue);
    }

    changePasswordConfirm=(value:string,name:string="password") => {
        let errorPromptValue:string="";
        if(this._validator.isEmpty(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().emptyArea;
        if((this.state.password.trim() != value.trim()))
            errorPromptValue=TextProvider.GetPhrase().passwordsNotEqual;
        this.changeState("confirmPassword",value,"confirmPasswordErrorPrompt",errorPromptValue);
    }


    isValidValueAreas = ():boolean => {
        let result:boolean=false;
        if(this.isEmptyErrorPrompts())
            if(!this.isFullValuesState() || !this.isEqConfirmPasWithPas())
                this.WarnUser();
            else
                result=true;
        return result;
    }

    isEmptyErrorPrompts = ():boolean => {
        return (this.state.emailErrorPrompt == "" 
            && this.state.loginErrorPrompt == "" 
            && this.state.passwordErrorPrompt == ""
            && this.state.confirmPasswordErrorPrompt == "");
    }

    isFullValuesState=():boolean => {
        return (this.state.email.trim() !="" && this.state.password.trim() !=""
             && this.state.login.trim() !="");
    }

    isEqConfirmPasWithPas = () : boolean => {
        return this.state.confirmPassword == this.state.password;
    }

    WarnUser=() => {
        let textProvider=TextProvider.GetPhrase();
        if(this.state.email=="")
            this.setState({emailErrorPrompt:textProvider.emptyArea})
        if(this.state.password=="")
            this.setState({passwordErrorPrompt:textProvider.emptyArea})
        if(this.state.login=="")
            this.setState({loginErrorPrompt:textProvider.emptyArea})
        if(this.state.confirmPassword == "")
            this.setState({confirmPasswordErrorPrompt : textProvider.emptyArea})
        if(this.state.confirmPassword != this.state.password)
            this.setState({confirmPasswordErrorPrompt : textProvider.passwordsNotEqual});
    }

    registerUser=() => {
        if(this.isValidValueAreas()){
            let sucReg = () => this.setState({isSucsessRegisrtrate : true, isLoad : true});
            localStorage.setItem("language", this.state.language.toString());
            this.setState({isLoad : false});
            this._registrationStrategy.Registrate(
                this._userId,
                this.state.password.trim(),
                this.state.login.trim(),
                this.state.email.trim(),
                sucReg,
                this.stateContainerCallback,
                this.state.language);
        }
    }


    stateContainerCallback=(obj : unknown) => {
        this.setState({isLoad : true});
        let answer:BadRequestAnswer=obj as BadRequestAnswer;
        let regExp:RegistrationException | UserNameExceptions | MessageObject | string  = answer.data;
        let textProvider=TextProvider.GetPhrase();

        let registrationException = regExp as RegistrationException;
        let userNameExceptions = regExp as UserNameExceptions;
        let messageObject = regExp as MessageObject;
        let strMessage = regExp as string;
        if(registrationException){
            if(registrationException.DuplicateEmail!=undefined)
                this.setState({emailErrorPrompt:textProvider.duplicateEmail})
            if(registrationException.DuplicateUserName!=undefined)
                this.setState({loginErrorPrompt:textProvider.duplicateUserName})
        }
        if(userNameExceptions){
            if(userNameExceptions.InvalidUserName != undefined)
                this.setState({loginErrorPrompt : userNameExceptions.InvalidUserName});
            if(registrationException.Email != undefined && registrationException.Email.length != 0)
                this.setState({emailErrorPrompt : registrationException.Email[0]});
        }
       
    }

    changeStateSucRegitrate = () => {
        window.location.href = RoutesUrl.SignIn + window.location.search;
    }

    render(){
        let content = <Preloader/>;
        if(this.state.isLoad)
            content = (<SignUpUI
                            isValidValueAreas = {this.isValidValueAreas}
                            state={this.state}
                            changeEmailAction={this.changeEmail}
                            changeLanguageAction = {this.changeLanguage}
                            changePasswordAction={this.changePassword}
                            changeConfirmPasswordAction = {this.changePasswordConfirm}
                            changeNameAction = {this.changeName}
                            changeValueAction={this.changeValue}
                            changeValueWithoutError={this.changeStateWithoutError}
                            registerUser={this.registerUser}
                            changeStateSucReg = {this.changeStateSucRegitrate}
                            textProvider={this.state.languageContext}
                        />)
        return content;
    }
}

export default SignUpPage;