import ILabelPeriodChoiser from "./ILabelPeriodChoiser";

class MonthPeriodChoiser implements ILabelPeriodChoiser{

    public GetPeriods(period : Array<Date>, strPeriods : Array<string> = []) : Array<string> {
        return period.map((item,index) => {
            let day = (item.getDate() < 10) ? "0" + item.getDate() : item.getDate().toString();
            let month = (item.getMonth() < 9) ? "0" + (item.getMonth() + 1).toString() : (item.getMonth() + 1).toString();
            return day + "/" + month});
    }
}

export default MonthPeriodChoiser;