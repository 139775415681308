class Validator{
    
    readonly globalLetter:RegExp=/[A-ZAА-Я-ÂÀÇËÉÊÈÏÎÔÛÙÜŸ]/;
    
    readonly numeral:RegExp=/[0-9]/;

    readonly name : RegExp = /^[a-zA-Zа-яА-ЯÂâÀàÇçËëÉéÊêÈèÏïÎîÔôÛûÙùÜüŸÿ0-9]*$/
    
    readonly emailTemplate:RegExp=/^.+@.+\..+$/;

    readonly phoneTemplate:RegExp=/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){9,14}(\s*)?$/;

    readonly URLTemplate:RegExp = /^((https?|ftp)\:\/\/)?([a-z0-9]{1})((\.[a-z0-9-])|([a-z0-9-]))*\.([a-z]{2,6})(\/?)$/;
  
    private CheckRegExp(str:string, exp:RegExp):Boolean{
        return (Boolean)(str.match(exp) ?? false);
    }
  
    public isEmpty(str:string):Boolean{
        return str==""
    }

    public isValidName(str : string) : Boolean{
        return this.CheckRegExp(str, this.name);
    }

    public IsPasswordLenght(str:string):Boolean{
        return str.length>=8;
    }
  
    public IsHaveGlobalLetter(str:string):Boolean{
        return this.CheckRegExp(str,this.globalLetter);
    }
  
    public IsHaveNumeral(str:string):Boolean{
        return this.CheckRegExp(str,this.numeral);
    }
  
    public IsEmailTemplate(str:string):Boolean{
        return this.CheckRegExp(str,this.emailTemplate);
    }

    public IsPhoneTemplate(str:string):Boolean{
        return this.CheckRegExp(str,this.phoneTemplate);
    }

    public IsURLTemplate(str : string) : Boolean {
        return this.CheckRegExp(str,this.URLTemplate);
    }
  
  }
  
  export default Validator;
  