import React, { useEffect } from 'react';
import {BrowserRouter, Route,Router,Switch}  from "react-router-dom";

import Chat from "./Containers/Chat/Chat";
import SignInPage from './Containers/SignIn/SignInPage';
import SignUpPage from './Containers/SignUpPage/SignUpPage';

import "./App.css";

import RoutesUrl from './Helpers/RoutesUrl';
import ResetPassword from './Containers/ResetPassword/ResetPassword';
import RootComponent from './Containers/RootComponent/RootComponent';
import Settings from './Containers/Settings/Settings';
import PersonalReport from './Containers/PersonalReport/PersonalReport';
import BaseContainer from './Containers/BaseContainer/BaseContainer';
import GuidGenerator from './Helpers/GuidGenetator';
import AppContext, { IAppState } from './AppContext';
import ChatHandler from './Containers/ChatHandler/ChatHandler';



class AppClass extends BaseContainer<{}, IAppState> {

  constructor(props : any){
    super(props);
    this.state = {
      uiState : {
        isOpenMenu : false,
        changeIsOpenState : this.changeIsOpenMenu
      }
    }
  }

  changeIsOpenMenu = (value : boolean) => {
    this.setState({uiState : {isOpenMenu : value, changeIsOpenState : this.state.uiState.changeIsOpenState}});
  }

  render(){
    return(
      <AppContext.Provider value = {this.state}>
        <React.Fragment>
          <BrowserRouter>
            <BrowserRouter>
              <Route exact path = "/" component = {RootComponent}></Route>
              <Route exact path = {RoutesUrl.Chat} component={ChatHandler}></Route>
              <Route exact path={RoutesUrl.Chat + "/:id"} component={Chat}></Route> 
              <Route exact path = {RoutesUrl.SignUp} component = {SignUpPage}></Route>
              <Route exact path = {RoutesUrl.SignUp + "?Email=" + "/:email" + 
                                  "&EmployeeId=" + ":/employeeId" + "&UserName=" + ":/username"} component = {SignUpPage}></Route>
              <Route exact path = {RoutesUrl.SignIn} component = {SignInPage}></Route>
              <Route exact path = {RoutesUrl.ResetPassword} component = {ResetPassword}></Route>
              <Route exact path = {RoutesUrl.Settings} component = {Settings}></Route>
              <Route exact path = {RoutesUrl.PersonalReport} component = {PersonalReport}></Route>
            </BrowserRouter>
          </BrowserRouter>
        </React.Fragment>
      </AppContext.Provider>)
  }
}

export default AppClass;
