import BaseService from "../BaseService"
import BaseUser from "../../Models/Users/BaseUser";
import AuthUser from "../../Models/Users/AuthUser";
import IUSerService from "./IUserService";
import UserServiceConstants from "./UserServiceConstants";
import Dictionary from "../../Models/Collections/Dictionary";
import Couple from "../../Models/Collections/Couple";
import Action from "../../Models/Delegate/Action";
import RequestSettings from "../RequestProvider/RequestSettings";
import ChangePasswordUser from "../../Models/Users/ChangePasswordUser";
import GetRequestChangePassword from "../../Models/Users/GetRequestChangePassword";
import IStateContainerCallback from "../../Actions/IStateContainerCallback";
import RequestProvider from "../RequestProvider/RequestProvider";
import Country from "../../Models/Users/Country";
import CountyTimeZones from "../../Models/Users/CountyTimeZones";
import FullUserInfo from "../../Models/Users/FullUserInfo";
import CheckUserProfileModel from "../../Models/Users/CheckUserProfileModel";
import RegistrateUser from "../../Models/Users/RegistrateUser";
import DataScopeLink from "../../Models/DataScopeLink/DataScopeLink";
import Language from "../../Models/Users/Language";

class UserService extends BaseService implements IUSerService {

    constructor() {
        super(new RequestProvider())
    }

    public async RegistrateAsync(user:RegistrateUser,sucsessCallBack:Action<unknown>,errorCallback:Action<unknown>):Promise<void>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._registrationRequestSignature,
            new Dictionary<string, string>(),
            JSON.stringify(user));
        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallBack),new Couple(400,errorCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async RegistrateAsyncFromDatascope(user:RegistrateUser,sucsessCallBack:Action<unknown>,errorCallback:Action<unknown>):Promise<void>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._registrationRequestFromDatascopeSignature,
            new Dictionary<string, string>(),
            JSON.stringify(user));
        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallBack),new Couple(400,errorCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async AuthorizeAsync(user:AuthUser,sucsessCallBack:Action<unknown>,errorCallBack:Action<unknown>, unConfirmedEmailCallback : IStateContainerCallback):Promise<void>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._authorizationRequestSignature,
            new Dictionary<string, string>(),
            JSON.stringify(user));
        
        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallBack),new Couple(400,errorCallBack), new Couple(403, unConfirmedEmailCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings,actions);
    }

    public async GetProfileAsyns(sucsessCallBack:Action<object>) : Promise<void>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._getProfileSignature,
            new Dictionary<string,string>(),
            null);

            let user = await this.RequestProvider.RequestWithResponceAsync<Array<BaseUser>>(requestSettings);
            sucsessCallBack(user ?? new Object());
    }

    public async RegistrationInOrganization(user : BaseUser, sucsessCallBack:Action<unknown>,errorCallback:Action<unknown>) : Promise<void> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._registrationInOrganization,
            new Dictionary<string,string>(),
            JSON.stringify(user));

        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallBack),new Couple(400,errorCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async RegistrationAdmin(user : BaseUser,sucsessCallback:Action<unknown>,errorCallback:Action<unknown>): Promise<void> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._adminRegistration,
            new Dictionary<string,string>(),
            JSON.stringify(user));

        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallback),new Couple(400,errorCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async UpdateUserInformationById(user : BaseUser,sucsessCallback:Action<unknown>,errorCallback:Action<unknown>) : Promise<void> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._updateUserInformationById,
            new Dictionary<string,string>(),
            JSON.stringify(user),user.userId);


        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallback),new Couple(400,errorCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async UpdateUserInformation(user : BaseUser,sucsessCallback:Action<unknown>,errorCallback:Action<unknown>) : Promise<void>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._updateUserInformation,
            new Dictionary<string,string>(),
            JSON.stringify(user));


        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallback),new Couple(400,errorCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async UpdateUserPassword(userPassword : ChangePasswordUser,sucsessCallback:Action<unknown>,errorCallback:Action<unknown>) : Promise<void>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._changePassword,
            new Dictionary<string,string>(),
            JSON.stringify(userPassword));


        let actions = new Dictionary<number,Action<unknown>>(new Couple(200,sucsessCallback),new Couple(400,errorCallback));
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async GetUserProfileById(userId : string) : Promise<BaseUser>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._getUserProfileById,
            new Dictionary<string,string>(),
            null,userId);

        
        return await this.RequestProvider.RequestWithResponceAsync(requestSettings) as BaseUser;
    }

    public async GetUserProfile() : Promise<BaseUser> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._getUserProfile,
            new Dictionary<string,string>(),
            null);

        return await this.RequestProvider.RequestWithResponceAsync(requestSettings) as BaseUser;
    }

    public async DeleteUser() : Promise<boolean> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._deleteUser,
            new Dictionary<string,string>(),
            null);

        let result = false;
        await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, 
            new Dictionary<Number, Action<unknown>>(new Couple(200, () => result = true)));
        
        return result;
    }

    public async RequestChangePassword(userId : string) : Promise<GetRequestChangePassword> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._requestChangePassword,
            new Dictionary<string,string>(new Couple("userId",userId)),
            null);

        return await this.RequestProvider.RequestWithResponceAsync(requestSettings) as GetRequestChangePassword;
    }

    public async ResetPassword(email : string) : Promise<void> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._resetPassword,
            new Dictionary<string,string>(new Couple("email",email)),
            null);

        await this.RequestProvider.RequestAsync(requestSettings);
    } 

    public async GetCountries() : Promise<Array<Country>>{
        let requestSettings = new RequestSettings(
            UserServiceConstants._getCountries,
            new Dictionary<string,string>(),
            null);

        return await this.RequestProvider.RequestWithResponceAsync(requestSettings) as Array<Country>;
    }

    public async GetCountriesTimeZones() : Promise<Array<CountyTimeZones>> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._getTimeZones,
            new Dictionary<string,string>(),
            null);

        return await this.RequestProvider.RequestWithResponceAsync(requestSettings) as Array<CountyTimeZones>;
    }

    public async GetFullProvile(actions : Dictionary<number, Action<unknown>>) : Promise<FullUserInfo> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._getFullUserInfo,
            new Dictionary<string,string>(),
            null);

        return await this.RequestProvider.RequestWithResponceAsync<FullUserInfo>(requestSettings, actions) as FullUserInfo;
    }

    public async UpdateUserFullProfile(fullUserProfile : FullUserInfo) : Promise<void> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._updateFullUser,
            new Dictionary<string,string>(),
            JSON.stringify(fullUserProfile));

        return await this.RequestProvider.RequestAsync(requestSettings);
    }

    public async AddUserFullProfile(fullUserProfile : FullUserInfo) : Promise<void> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._addFullUser,
            new Dictionary<string,string>(),
            JSON.stringify(fullUserProfile));

        return await this.RequestProvider.RequestAsync(requestSettings);
    }

    public async CheckUserProfile() : Promise<CheckUserProfileModel> {
        let requestSettings = new RequestSettings(
            UserServiceConstants._checkUserProfile,
            new Dictionary<string,string>(),
            null);

        return await this.RequestProvider.RequestWithResponceAsync<CheckUserProfileModel>(requestSettings) as CheckUserProfileModel;
    }

    public async GetLinkInfo() : Promise<DataScopeLink> {
        let result : DataScopeLink = DataScopeLink.FactoryMethod();

        const f = (s: unknown) => { 
            result = s as DataScopeLink;
        }

        await this.RequestProvider.RequestWithCodeHundlerAsync(
            new RequestSettings(UserServiceConstants._getLinkInfo, new Dictionary<string, string>(), null),
            new Dictionary<number, Action<unknown>>(
                new Couple<number, Action<unknown>>(200, o => f(o)),
                new Couple<number, Action<unknown>>(204, _ => { })));

        return result;
    }

    public async UnLink() : Promise<boolean> {
        try {
            await this.RequestProvider.RequestAsync(new RequestSettings(UserServiceConstants._unlink, new Dictionary<string, string>(), null))
            return true;
        } catch {
            return false;
        }
    }

    public async ChangeLanguageAsync(lang : Language) : Promise<void> {
        const queries = new Dictionary<string, string>(new Couple("language", lang.toString()));
        await this.RequestProvider.RequestAsync(new RequestSettings(UserServiceConstants._changeLanguage, queries, {}));
    }
}

export default UserService;