import React from "react";

import ChatArea from "../../Components/ChatArea/ChatArea";
import ControlChatArea from "../../Components/ControlChartArea/ControlChatArea";
import Header from "../../Components/Header/Header";

import "./Chat.css";

import Message from "../../Models/Chat/Message";
import Couple from "../../Models/Collections/Couple"
import TextProvider from "../../Helpers/TextProvider";
import ChatMiddlewares from "../../ContainersMiddlewares/ChatMiddlewares/ChatMiddlewares";
import { RouteComponentProps } from "react-router-dom";
import Preloader from "../../Components/Preloader/Preloader";
import RoutesUrl from "../../Helpers/RoutesUrl";
import PopUp from "../../Components/PopUp/PopUp";
import BaseUser from "../../Models/Users/BaseUser";
import BaseContainer from "../BaseContainer/BaseContainer";
import ControlCommand from "../../Models/Chat/ControlCommand";
import MessageQuestion from "../../Models/Chat/MessageQestion";
import GuidGenerator from "../../Helpers/GuidGenetator";
import ActionImg from "../../Media/Img/actions.png";
import StartChatSettings from "../../Models/Chat/StartChatSettings";
import SideMenu from "../../Components/SideMenu/SideMenu";
import HalfHiddenMenu from "../../Components/HalfHiddenMenu/HalfHiddenMenu";
import SideMenuContainer from "../../Components/SideMenuContainer/SideMenuContainer";
import CheckUserProfileModel from "../../Models/Users/CheckUserProfileModel";
import Footer from "../../Components/Footer/Footer";
import AppConstants from "../../Helpers/AppConstants";

interface IChatPropsHeader{
    id:string
}

interface IChart {
    messages : Array<Message>
    nowMessage : Message
    nowQuestion : MessageQuestion
    isError : boolean
    isLoad : boolean
    isCommandMood : boolean
    preMessage : Array<Message>
    postMessage : Array<Message>
    checkUserProfileModel : CheckUserProfileModel
}

class Chart extends BaseContainer<RouteComponentProps<IChatPropsHeader>,IChart>{
    private readonly _chatMiddlewares : ChatMiddlewares;

    private _user : BaseUser = new BaseUser("","","","","");

    constructor(params : any){
        super(params);

        let user = JSON.parse(localStorage.getItem("user") as string) as BaseUser;
        if(user)
            this._user = user;
        else
            window.location.href = RoutesUrl.SignIn;

        this._chatMiddlewares = new ChatMiddlewares(localStorage.getItem("token") as string, this._user.userId, 
                                                    new StartChatSettings(this.props.match.params.id, 
                                                                          this.errorConnection, 
                                                                          this.getMessageFromInterlocutorHandler,
                                                                          () => {this.setState({isLoad : false})},
                                                                          this.load));
        let textProvider = TextProvider.GetPhrase();
        let imageMessage =  Message.CreateMessage("",textProvider.viewCommandPrompt, "", MessageQuestion.GetMessageQuestion());
        imageMessage.Image = ActionImg;

        this.state = {

            preMessage : (this._user.isFirstStart) ? [Message.CreateMessage("",textProvider.greeting, "", MessageQuestion.GetMessageQuestion()),
                                                        imageMessage,
                                                        Message.CreateMessage("", textProvider.desriptionCommand,"", MessageQuestion.GetMessageQuestion()),
                                                        Message.CreateMessage("", textProvider.start,user.userId,MessageQuestion.GetMessageQuestion(), ControlCommand.StartInterview)] 
                                                   : [Message.CreateMessage("",textProvider.responcePrompt, "", MessageQuestion.GetMessageQuestion())],


            postMessage : [],
            messages : [],
            nowMessage : Message.CreateMessage(this.props.match.params.id,"", this._user.userId, MessageQuestion.GetMessageQuestion() ),
            nowQuestion : MessageQuestion.GetMessageQuestion(),
            checkUserProfileModel : new CheckUserProfileModel("", false),
            isLoad : false,
            isError : false,
            isCommandMood : false
        }
    }

    load = () => {
        this.setState({isLoad : true});
    }

    getMessageFromInterlocutorHandler = (userName : string ,message : Message) => {
        this.setState({messages : this.state.messages.concat([message]), nowQuestion : message.messageQuestion});
    }

    endLoad = (messages : Array<Message>, checkUserProfileModel : CheckUserProfileModel) => {
        let nowQuestion = this.getQuestion(messages);
        this.setState({messages, isLoad : true, nowQuestion});
        if(!this._user.isFirstStart && !checkUserProfileModel.userProfile){
            let textProvider = TextProvider.GetPhrase();
            this.setState({preMessage : this.state.preMessage.concat([Message.CreateMessage("",textProvider.notFillSettings, "", MessageQuestion.GetMessageQuestion())])})
        }
    }

    componentDidMount = () => {
        this._chatMiddlewares.InitChat(this.endLoad).then(_ => this.checkArgsOnLoad()).then(_ => { 
            if (!this._user.isFirstStart) return;
            
            const message = Message.CreateMessage(
                this.props.match.params.id, TextProvider.GetPhrase().start, this._user.userId, this.state.nowQuestion, ControlCommand.StartInterview);
            this._chatMiddlewares.SendToChat(message).then(async _ => 
                await this._chatMiddlewares.ChangeIsFirstTimeUserArea(false)).then(_ => {
                    const us = JSON.parse(localStorage.getItem("user") as string) as BaseUser;
                    us.isFirstStart = false;
                    localStorage.setItem("user", JSON.stringify(this._user = us));
                });
        });
        window.addEventListener("beforeunload", this.handleCloseWindow);
    }

    checkArgsOnLoad = () => {
        const search = new URLSearchParams(window.location.search);
        const restart = search.get(AppConstants.chatRestartKey);
        if (!restart || restart != 'true') return;

        this.actionHandler(ControlCommand.StartOver, TextProvider.GetPhrase().repeat);
        let address = window.location.href.split('?')[0];
        window.history.replaceState({ }, "", address);
    }

    componentWillUnmount = () => {
        this._chatMiddlewares.DestroyComponentAction(this.props.match.params.id, this.state.messages, this._user.userId);
        
    }

    handleCloseWindow = () => {
        this._chatMiddlewares.DestroyComponentAction(this.props.match.params.id, this.state.messages, this._user.userId);
        document.removeEventListener("beforeunload",this.handleCloseWindow);
    }

    getQuestion = (messages : Array<Message>) : MessageQuestion => {
        let question : MessageQuestion = MessageQuestion.GetMessageQuestion();
        let zeroGuild = new GuidGenerator().GenerateZeroGuid();
        messages.forEach(item => {
            if(item.messageQuestion)
                if(item.messageQuestion.questionId != zeroGuild && item.messageQuestion.questionId != ""){
                    question = item.messageQuestion;
                }
        });  
        return question;
    }

    errorConnection = (value : string) => {
        window.location.href = RoutesUrl.SignIn;
    }

    showCommandMessage = () => {
        this.setState({isCommandMood : !this.state.isCommandMood, 
                    nowMessage : Message.CreateMessage(this.props.match.params.id, "", this._user.userId, MessageQuestion.GetMessageQuestion())});
    }



    actionHandler = (actionNumber : ControlCommand, actionName : string) => {
        let condition = true;
        let additionalMessage : Array<Message> = [];
        if(actionNumber == ControlCommand.Help){
            this.setState({messages : this.state.messages.concat([Message.CreateMessage("", TextProvider.GetPhrase().desriptionCommand,"", MessageQuestion.GetMessageQuestion())])});
            additionalMessage = additionalMessage.concat([]);
            condition = false;
        }
        if(actionNumber == ControlCommand.StartOver){
            condition = false;
            this.setState({messages : [], preMessage : [Message.CreateMessage("",TextProvider.GetPhrase().responcePrompt, "eva", MessageQuestion.GetMessageQuestion("", "", "", "", 1), ControlCommand.Help)]});
            let message = Message.CreateMessage(this.props.match.params.id, actionName, this._user.userId, this.state.nowQuestion, actionNumber);
            this._chatMiddlewares.SendToChat(message);
        }

        if(condition){
            let message = Message.CreateMessage(this.props.match.params.id, actionName, this._user.userId, this.state.nowQuestion, actionNumber);
            this._chatMiddlewares.SendToChat(message);
            this.setState({messages : this.state.messages.concat([message]).concat(additionalMessage)});
        }
        this.setState({isCommandMood : false});
    }
 

    changeMessageVarValue = (message : Message) => {
        this.setState((state) => {
            return({
                ...state,
                nowMessage : message
            })
        })
    }


    addMessageHandler = () => {
        let nowMessage = this.state.nowMessage;
        nowMessage.messageQuestion = this.state.nowQuestion;
        let messageText = nowMessage.text.trim();
        if(messageText != ""){
            this.setState({messages : this.state.messages.concat([nowMessage]), 
                           nowMessage : Message.CreateMessage(this.props.match.params.id, "", this._user.userId, MessageQuestion.GetMessageQuestion(),ControlCommand.Answer)});
            this._chatMiddlewares.SendToChat(nowMessage);
        }
    } 

    render(){
        let popUp = null;
        let textProvider = TextProvider.GetPhrase();
        if(this.state.isError)
            popUp = <PopUp
                    text = "Server error"
                    closePopUp = {() => {window.location.href = RoutesUrl.SignIn}}
                />
        let content = <Preloader/>;
        if(this.state.isLoad)
            content = (<React.Fragment>
                            {popUp}
                            <div className='chat'>
                            <Header
                                textProvider = {textProvider}
                                heading = {""}
                                isShowLogo = {true}
                            />
                            <SideMenuContainer
                                  textProvider = {textProvider}
                                  uri = {window.location.pathname}
                                  uriChat = {RoutesUrl.Chat + "/" + this._user.evaChatId}
                            />
                            <div className="rightSideChat"></div>
                                <div className="activeChatBox">
                                    <div className="chatBox">
                                        <ChatArea
                                            checkUserProfileModel = {this.state.checkUserProfileModel}
                                            preMessages = {this.state.preMessage}
                                            postMessages = {this.state.postMessage}
                                            textProvider = {textProvider}
                                            messagesTape={this.state.messages}
                                            user = {this._user}
                                            commandActionHandler = {this.actionHandler}
                                            isCommandAction = {this.state.isCommandMood}
                                        />
                                        <ControlChatArea
                                            textProdiver = {textProvider}
                                            message={this.state.nowMessage}
                                            commandAction = {this.showCommandMessage}
                                            changeMessageValue={this.changeMessageVarValue}
                                            chatContainer = {this}
                                            userAction = {this.addMessageHandler}
                                            isControlCommand = {!this.state.isCommandMood}
                                        />
                                    </div>
                                </div>  
                            </div>
                        </React.Fragment>)
        return content;
    }
} 

export default Chart;