import React, { FormEvent } from "react";
import "./FileInput.css";

interface IFileInput {
    onClick(e : FormEvent<HTMLInputElement>) : void
    choiseFilePhrase : string
    accept : string
}

function FileInput(props : IFileInput) {
    return(<div className="input__wrapper">
                <input onChange = {props.onClick} accept = {props.accept} name="file" type="file"  id="input__file" className="input input__file" multiple/>
                <label htmlFor="input__file" className="input__file-button">
                <span className="input__file-button-text">{props.choiseFilePhrase}</span>
                </label>
          </div>)
}

export default FileInput;