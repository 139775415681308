import LinkPartBase from "../../Models/Link/LinkPartBase";
import Language from "../../Models/Users/Language";

export default class LanguagePhrase{

    public lang : Language = -1;

    public account:string="";
    
    public widgets:string="";
    
    public analysis:string="";
    
    public methodology:string="";
    
    public database:string="";
    
    public settingsPointsListMenu:Array<string>=[];
    
    public emptyArea:string="";
    
    public shortPassword:string="";
    
    public uncorrectPassword:string=""
    
    public uncorrectEmail:string=""
    
    public uncorrectPhoneNumber:string=""

    public firstName:string=""

    public lastName:string=""

    public email:string=""

    public phoneNumber:string=""

    public login:string=""

    public password:string=""

    public privacyPolicyAgree:LinkPartBase[]=[]

    public privacyDataAgree:LinkPartBase[]=[];

    public appUseConditions:string =""

    public dataProtectionConditions:string =""

    public unsuccsesAuth:string=""

    public duplicateUserName:string=""

    public duplicateEmail:string=""

    public signUpBut:string=""

    public signInBut:string=""
    
    public authorization:string=""

    public registration:string=""

    public tensity:string=""

    public anxiety : string = ""

    public forecast : string = "";

    public period : string = "";

    public currentState :string = "";

    public dashBoard : string = "";

    public settings : string = "";

    public export : string = "";

    public logOut : string = "";

    public byMonth : string = ""

    public byDay : string = ""

    public byYear : string = ""

    public byWeek : string = ""

    public reportPhrase : string = ""

    public interval : string = ""

    public dateReport : string = ""

    public reportNotLoad : string = ""

    public users : string = ""

    public balance : string = ""

    public tariff : string = ""

    public dataControl : string = ""

    public dateActivity : string = ""

    public position : string = ""

    public name : string = ""

    public addNewUser : string = ""

    public buyNewUser : string = ""

    public cancel : string = ""

    public configurePlan : string = "";

    public tariffName : string = "";

    public changeTariff : string = "";

    public additionally : string = "";

    public monthVersion : string = "";

    public addTariff : string = "";

    public count : string = "";

    public storage : string = "";

    public storageDescription : string = "";
    
    public employee : string = "";

    public licenceDescription : string = "";

    public notes : string = "";
 
    public addedDataSets : string = "";

    public addDataSet : string ="";

    public update : string = "";

    public delete : string = ""; 

    public diskSpace : string = "";

    public userCount : string = "";

    public invalidFormatFile : string = "";

    public connected : string = "";

    public inProcessing : string = "";

    public err : string = "";

    public processed : string = "";

    public notConnected : string = "";

    //новое

    public structures : string = "";

    public addStructure : string = "";

    public enterNameStructure : string = "";

    public ajouter : string = "";

    public create : string = "";

    public subDepartments : string = "";

    public mainDepartment : string = "";

    public updateNote : string = "";

    public getDemoVersion : string = "";

    public sucsessRegistrate : string = "";

    public siret : string = "";
    
    public nameCompany : string = "";

    public legalAddress : string = "";

    public officialSite : string = "";

    public officiaPhoneNumber : string ="";

    public sizeCompany : string = "";

    public public : string = "";

    public private : string = "";

    public professionalSector : string = "";

    public commercialCompany : string = "";

    public stateAndPublicStructures : string = "";

    public scienceAndEducation : string = "";

    public more : string = "";

    public activity : string = "";

    public howDoYouKnow : string = "";

    public reason : string = "";

    public uncorrectWebSite : string = "";

    public company : string = "";

    public person : string = "";

    public areYou : string = "";

    public health : string = "";

    public expertise : string = "";

    public counselling : string = "";

    public facebook : string = "";

    public linkedIn : string = "";

    public less : string = "";

    public moreThen : string = "";

    public areYouSureLogOut : string = "";

    public yes : string = "";

    public no : string = "";

    public firstRegDate : string = "";

    public secondRegDate : string = "";

    public actionDate : string = "";

    public action : string = "";

    public info : string = "";

    public canceled : string = "";

    public request : string = "";

    public confirmed : string = "";

    public open : string = "";

    public confirmEmail : string = "";

    public resetPassword : string = "";

    public resetPasswordPrompt : string = "";

    //новое
    public readyData : string = "";

    public notExistData : string = "";

    public inProcessData : string = "";

    public blockedData : string = "";

    public notAssigned : string = "";

    public readyDateFullText : string = "";

    public notExistDataFullText : string = "";

    public inProcessDateFullText : string = "";

    public blockedDateFullText : string = "";

    public notAssignedFullText : string = "";

    public dataManagment : string = "";

    public numberDepartment : string = "";

    public english : string = "English";

    public russian : string = "Русский";

    public france : string = "Français";

    public reports : string = "";

    public selectHierarchy : string = "";

    public hierarchies : string = "";

    public almostReadyOrNotInsufficientData : string = "";

    public volume : string = "";

    public obv : string = "";

    public optimistic : string = "";

    public real : string = "";

    public pessimistic : string = "";

    public averageByDeps : string = "";

    //new
    public sma : string = "";

    public chat : string = "";

    public you : string = "";

    public evaBot : string = "";

    public greeting : string = "";

    public responcePrompt : string = "";

    public viewCommandPrompt : string = "";

    public desriptionCommand : string = "";

    public back : string = "";

    public choiseTheAction : string = "";

    public repeat : string = "";

    public stop : string = "";

    public help : string = "";

    public pressToStart : string = "";

    public commands : Array<string> = [];

    public invalidName : string = "";

    public chatBotEva : string = "";

    public personalReport : string = "";

    public personalInfo : string = "";

    public interfaceLanguage : string = "";

    public cancelBut : string = "";

    public save : string = "";

    public chatbotSettings : string = "";

    public programVersion : string = "";

    public hello : string = "";

    public lookAtResults : string = "";

    public recomendation : string = "";

    public weatherHint : string = "";

    public partlyCloudy : string = "";

    public stormy : string = "";

    public cloudy : string = "";

    public calm : string = "";

    public sunny : string = "";

    public dynamicsOfResults : string = "";

    public reportGeneration : string = "";

    public promptAd : string = "";

    public firstPromptAd : string = "";

    public secondPromptAd : string = "";

    public thirdPromptAd : string = "";

    public servicesBrain : string = "";

    public firstStormyRecomendation : string = "";

    public secondStormyRecomendation : string = "";

    public thirdStormyRecomendation : string = "";

    public firstCloudyRecomendation : string = "";

    public secondCloudyRecomendation : string = "";

    public thirdCloudyRecomendation : string = "";

    public firstCalmRecomendation : string = "";

    public secondCalmRecomendation : string = "";

    public thirdCalmRecomendation : string = "";

    public firstSunnyRecomendation : string = "";

    public secondSunnyRecomendation : string = "";

    public thirdSunnyRecomendation : string = "";

    public firstPartlyCloudyRecomendation : string = "";

    public secondPartlyCloudyRecomendation : string = "";

    public thirdPartlyCloudyRecomendation : string = "";

    public isAdult : string = "";

    public confirmPassword : string = "";

    public passwordsNotEqual : string = "";

    public daysOfWeek : Array<string> = [];

    public birthDate : string = "";

    public femaleStatus : string = "";

    public civilUnion : string = "";

    public commonLawMarriage : string = "";

    public divorced : string = "";

    public married : string = "";

    public single : string = "";

    public widow : string = "";

    public childCount : string = "";

    public nextQuestion : string = "";

    public timeZone : string = "";

    public countryOfResidence : string = "";

    public sex : string = "";

    public male : string = "";

    public female : string = "";

    public notFillSettings : string = "";

    public firstPrompt : string = "";

    public start : string = "";

    public notReadyRecomendation : string = "";

    public notFillSettingsAres : string  = "";

    public notSpecified : string = "";

    public reportAboutError : string = "";

    public mandatoryFilling : string = "";

    public intellectualProperty : string = "";

    public skip : string = "";

    public skipQuestion : string = "";

    public loadImage : string = "";

    public confirm : string = "";

    //new

    public fullImagePrompt = "";

    public choiseTheFile = "";

    public circleImagePrompt = "";

    public backNotAction = "";

    public choisePeriod : string = "";

    public enterYourText : string = "";

    public deleteAccount : string = "";

    public deleteAccountConfirm : string = "";

    public brakeLink : string = "";

    public connectionDate : string = "";

    public connectionToOrganization : string = "";

    public profileStatus : string = "";

    public unLinkPrompt : string = "";

    public registrationDate : string = "";

    public dataExchangeAgreement : string = "";
}