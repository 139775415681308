import DateType from "./DateType";
import SentimentAnalysis from "./SentimentAnalysis";

class ReportSentiment {

    

    public static GetReportSentiment(){
        return new ReportSentiment("", "", "", DateType.ByDays, []);
    }

    constructor(queryId : string, firstDate : string, secondDate : string, dateType : DateType, sentimentValues : Array<SentimentAnalysis>){
        this.queryId = queryId;
        this.firstDate = firstDate;
        this.secondDate = secondDate;
        this.dateType = dateType;
        this.sentimentAnalysisValues = sentimentValues;
    }

    public queryId : string;

    public firstDate : string;

    public secondDate : string;

    public dateType : DateType;

    public sentimentAnalysisValues : Array<SentimentAnalysis>;

}

export default ReportSentiment;