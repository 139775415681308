import React from "react"
import LinkPart from "../../Models/Link/LinkPart";
import LinkPartBase from "../../Models/Link/LinkPartBase"
import LinkText from "../../Models/Link/LinkText";
import "./LinkArea.css"

interface ILinkArea {
    linkParts: LinkPartBase[];
}

function LinkArea(props: ILinkArea) {
    const getLinkElement = (item: LinkPart) : JSX.Element => {
        return <a href={item.address} target="_blank">{item.text}</a>
    }
    
    const getTextElement = (item: LinkText) : JSX.Element => {
        return <span>{item.text}</span>
    }

    let children : JSX.Element[] = props.linkParts
                                        .map(item => item instanceof LinkPart 
                                            ? getLinkElement(item as LinkPart) 
                                            : getTextElement(item));

    return <div className="linkContainer">{children}</div>;
}

export default LinkArea;