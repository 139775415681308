import GuidGenerator from "../../Helpers/GuidGenetator";

class MessageQuestion {

    constructor(id : string, messageId : string, question : string, questionId : string, numberInSession : number, header : string){
        this.Id = id;
        this.MessageId = messageId;
        this.Question = question;
        this.questionId = questionId;
        this.numberInSession = numberInSession;
        this.header = header;
    }

    public static GetMessageQuestion(id : string = "", messageId : string = "", question : string = "", questionId : string = "", numberInSession : number = 1, header : string = ''){
        const guidGenerator = new GuidGenerator();
        const zeroGuid = guidGenerator.GenerateZeroGuid();
        return new MessageQuestion(
            (id == "") ? zeroGuid : id, 
            (messageId == "" ? zeroGuid : messageId), 
            question, 
            (questionId == "") ? zeroGuid : zeroGuid, 
            numberInSession, 
            header);
    }

    public Id : string

    public MessageId : string

    public Question : string

    public questionId : string;

    public numberInSession : number;

    public header : string;
 
}

export default MessageQuestion;