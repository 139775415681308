import ReportSentiment from "../../../../Models/Report/ReportsSentiment";
import IPeriodConverterStrategy from "./IPeriodConverterStrategy";

class WeekStrategy implements IPeriodConverterStrategy{

    public GetPeriod(report : ReportSentiment) : Array<Date> {

        let period : Array<Date> = [];

        var firstDate = new Date(report.firstDate);
        var secondDate = new Date(report.secondDate);

        let offset = firstDate.getTimezoneOffset();

        firstDate.setMinutes(firstDate.getMinutes() + offset);

        if(firstDate < secondDate){
        let nowDate = firstDate;
        let dif = nowDate.getTimezoneOffset();
            while(nowDate <= secondDate) {
                period.push(nowDate);
                nowDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1, nowDate.getHours(), nowDate.getMinutes() + offset);
            };
        }
        return period;
    }

}

export default WeekStrategy;