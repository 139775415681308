import React from "react";
import Calendar from 'react-calendar';

import DateImg from "../../Media/Img/calendar.png";
import 'react-calendar/dist/Calendar.css';
import "./DateInput.css";

import CloseImg from "../../Media/Img/closeWhite.png";

interface IProps{
    nowValue : Date
    changeDate(value : Date) : void
    language : string
}

function DateInput(props : IProps){

    const [isOpen, useisOpen] = React.useState(false);

    let calendar = (isOpen ) ? <div className = "CalendarContainer">
                                    <div onClick = {() => {useisOpen(false)}} className = "CloseCalendarContainer">
                                        <img src = {CloseImg}/>
                                    </div>
                                    <Calendar
                                        value = {props.nowValue}
                                        onChange = {props.changeDate}
                                        onClickDay = {(date : Date) => {props.changeDate(date);
                                                                        useisOpen(false)}}
                                        locale = {props.language}
                                        onClickMonth = {props.changeDate}
                                        onClickYear = {props.changeDate}
                                    />
                                </div>
                             : null;

    return(<React.Fragment>
             {calendar}
            <div className = "DateInput">
                <div className = "DateInputImgContainer">
                    <img src={DateImg} />
                </div>
                <input onClick = {() => useisOpen(!isOpen)} value = {props.nowValue.toLocaleDateString()} />
            </div>
            </React.Fragment>);
}

export default DateInput;
