import Action from "../../../Models/Delegate/Action";
import UserImage from "../../../Models/Users/UserImage";
import IProfileServices from "../../../Services/Profile/IProfileServices";

abstract class UserImageBaseClass {

    protected readonly _profileServices : IProfileServices;

    constructor(profileServices : IProfileServices){
        this._profileServices = profileServices;
    }

    abstract ModifyUserImage(userImage : UserImage, overflowException : Action<unknown>) : Promise<void>;

}

export default UserImageBaseClass;