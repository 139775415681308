import Language, { getValueByLang } from "../Models/Users/Language";

class AppConstants {
    
    public static readonly burgerMenuId : string = "burgerMenu";

    public static readonly closeMenuImg : string = "closeMenuImg";

    public static readonly burgerContainer : string = "burgerContainer";

    public static readonly isOpenMenu : string = "isOpenMenu";

    public static readonly workImageFormat : string = "data:image/jpeg;base64,";

    public static readonly outputImageFormat : string = "data:image/jpeg;base64,";

    public static getPolicyLink(lang : Language) : string {
        const en = 'en';
        const langCode = getValueByLang<string>(lang, en, 'fr', en);

        return `https://whyeva.com/${langCode}/docs.html`;
    }
    
    public static readonly chatRestartKey : string = 'reload';
}

export default AppConstants;