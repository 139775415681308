import Couple from "./Couple";

class Dictionary<K,V>{
    public dictionary : Array<Couple<K,V>> = new Array<Couple<K, V>>();

    constructor(...couples:Array<Couple<K,V>>){
        couples.forEach(item => this.AddItem(item));
    }

    public GetLenghtDictionary(){
        return this.dictionary.length;
    }

    public GetValueByKey(key:K): V | null{
        return this.dictionary.find((item:Couple<K,V>) => item.key==key)?.value ?? null;
    }

    public GetKeyByValue(value:V):K | null{
        return this.dictionary.find((item:Couple<K,V>) => item.value==value)?.key ?? null;
    }

    public GetAllValues():Array<V>{
        return this.dictionary.map((item) => {return item.value})
    }

    public GetAllKeys() : Array<K> {
        return this.dictionary.map(item => item.key);
    }

    public AddPair(key : K, value : V) {
        this.AddItem(new Couple(key, value));
    }

    public AddItem(item: Couple<K, V>) {
        this.dictionary.forEach(couple => {
            if (couple.key == item.key)
                throw new Error("Same key already exists in dictionary")
        })

        this.dictionary.push(item);
    }
}

export default Dictionary;