import React, { FormEvent, useRef } from "react";
import AppConstants from "../../Helpers/AppConstants";
import ImageWorker from "../../Helpers/ImageWorker/ImageWorker";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import UserImage from "../../Models/Users/UserImage";
import Button from "../Button/Button";
import CropperComponent from "../CropperComponent/CropperComponent";
import FileInput from "../FileInput/FileInput";
import PopUpBackground from "../PopUpBackground/PopUpBackground";

import StandartPersomImg from "../../Media/Img/person.png";

interface ICropperComponet {
    nowImage : UserImage
    textProvider : LanguagePhrase
    modifyUserImageAction(userImage : UserImage) : void
    closeAction() : void
}

function CropperContainer(props : ICropperComponet) {

    let cropperRefFullImage = useRef<HTMLImageElement>(null);

    let croppedRefCircleImage = useRef<HTMLImageElement>(null);

    const [nowImage, usenowImage] = React.useState((props.nowImage.imageBytes != StandartPersomImg) ? AppConstants.outputImageFormat + props.nowImage.imageBytes : props.nowImage.imageBytes);
    
    const [nowCicleImage, usenowCicleImage] = React.useState(nowImage);

    const [nowPage, usenowPage] = React.useState(0);

    const imageWorker : ImageWorker = new ImageWorker();

    const getCroppedImageArrByte = (refImage : React.RefObject<HTMLImageElement>) : string => {
        const imageElement: any = refImage?.current;
        const cropper: any = imageElement?.cropper;
        return cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.8);
        
    };


    const choiseFile=(e:FormEvent<HTMLInputElement>) => {
        if(e.currentTarget.files){
            try{
                let file = imageWorker.getCurrentImage(e.currentTarget.files);
                if(imageWorker.isValidImageFormat(file) && imageWorker.isValidSizeFile(file)){
                    imageWorker.convertFileToArrByte(file, (byteArr : string) => {
                        usenowImage(AppConstants.outputImageFormat + byteArr);
                    })
                }
                else
                    alert("Image The file cannot exceed 6MB, please select another file");
            }
            catch(e) {
                console.log(e);
            }
        }  
      }
    
      const clickHandlerChoiseFullImage = () => {
        let imageArrByte = getCroppedImageArrByte(cropperRefFullImage);
        usenowImage(imageArrByte);
        usenowCicleImage(imageArrByte);
        usenowPage(1);
      }

      const clickHandlerBackToFullImageState = () => {
          usenowImage(AppConstants.outputImageFormat + props.nowImage.imageBytes);
          usenowPage(0);
      }

      const clickHandlerChoiseCircleImage = () => {
          let imageArrByte = getCroppedImageArrByte(croppedRefCircleImage);
          usenowCicleImage(imageArrByte);
          
          let circleImage = imageArrByte.replace(AppConstants.workImageFormat, "");
          let fullImage = nowImage.replace(AppConstants.workImageFormat, "");

          props.modifyUserImageAction(new UserImage(props.nowImage.id, props.nowImage.userId, fullImage, circleImage));
          props.closeAction();
      }
    
      let accepts : string = "";
      imageWorker.GetImagesFormat.forEach((item, index) => {
        (index == imageWorker.GetImagesFormat.length - 1) ? accepts += item : accepts += item + ",";
      });
            
    let content = (nowPage == 0) ? <CropperComponent 
                                        classNameContainer = "croppedPopUp"
                                        prompt = {props.textProvider.fullImagePrompt}
                                        cropperRef = {cropperRefFullImage} 
                                        nowImage = {nowImage}  
                                        leftButton = {(<FileInput
                                                            onClick = {choiseFile}
                                                            choiseFilePhrase = {props.textProvider.choiseTheFile}
                                                            accept = {accepts}
                                                        />)}
                                        rightButton = {(<Button
                                                            prompt = {props.textProvider.confirm}
                                                            clickAction = {clickHandlerChoiseFullImage}
                                                            isAgree = {true}
                                                        />)}
                                    />
                                 :  < CropperComponent 
                                        classNameContainer = "circleCroppedPopUp"
                                        prompt = {props.textProvider.circleImagePrompt}
                                        cropperRef = {croppedRefCircleImage} 
                                        nowImage = {nowCicleImage} 
                                        leftButton = {(<Button
                                                            prompt = {props.textProvider.backNotAction}
                                                            clickAction = {clickHandlerBackToFullImageState }
                                                            isAgree = {true}
                                                        />)}
                                        rightButton = {(<Button
                                                            prompt = {props.textProvider.confirm}
                                                            clickAction = {clickHandlerChoiseCircleImage}
                                                            isAgree = {true}
                                                        />)}
                                    />

    return(<PopUpBackground
                closeAction = {props.closeAction}
                children = {content}
          />);

}

export default CropperContainer;