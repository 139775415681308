import AllTypeReport from "../../../Models/Report/AllTypeReport";
import ReportSentiment from "../../../Models/Report/ReportsSentiment";
import IPeriodChoise from "./IPeriodChoise";

class MonthChoisePeriod implements IPeriodChoise {

    public GetReport(reports : AllTypeReport) : ReportSentiment {
        return reports.MonthReport;
    }

}

export default MonthChoisePeriod;