class LocalStorageKeys {

    public static readonly user : string = "user";

    public static readonly language : string = "language";

    public static readonly token : string = "token";

    public static readonly recomendation : string = "recomendation";

}

export default LocalStorageKeys;