import LanguagePhrase from "./LanguagePhrase/LanguagePhrase"
import languageDictionary, {languageEnum} from "../Models/Collections/LanguageDictionary";
import EnglishPhrase from "./LanguagePhrase/EnglishPhrase";
import Language from "../Models/Users/Language";
import LocalStorageKeys from "../LocalStorage/LocalStorageKeys";
import User from "../Models/User";
import BaseUser from "../Models/Users/BaseUser";

class TextProvider{

    public static GetPhrase():LanguagePhrase{
        let langKeyStr = this.GetLetterDesignation();
        return languageDictionary.GetValueByKey(langKeyStr) ?? new EnglishPhrase();

    }

    public static GetLetterDesignation() : string {
        let langKey = this.GetLangKeyFromStorage();
        let langKeyStr = "en";
        if(langKey){
            langKeyStr = languageEnum.GetKeyByValue(+langKey) ?? "en";
        }
        else{
            langKeyStr = this.DeclareLanguage();

        }
        return langKeyStr;
    }

    public static DeclareLanguage() : string{
        let fullLanguage:string=navigator.language;
        if(fullLanguage.length >=2)
            return fullLanguage.substr(0,2).toLowerCase();
        else
            return "en";
    }
    
    public static GetLangKeyFromStorage() {
        let userStr = localStorage.getItem(LocalStorageKeys.user);
        if(userStr){
            let user = JSON.parse(userStr) as BaseUser;
            return user.languageType.toString();

        }
        else
            return localStorage.getItem(LocalStorageKeys.language);
    }


    public static GetEnumLanguage() : Language {
        let langKey:string = this.GetLetterDesignation();
        
        return languageEnum.GetValueByKey(langKey) ?? Language.En;
    }

}

export default TextProvider;