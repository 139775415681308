import React, { SyntheticEvent } from "react";
import GuidGenerator from "../../Helpers/GuidGenetator";
import ResponceCase from "../../Models/Chat/ResponceCase";
import PersonImg from "../../Media/Img/person.png";

import "./SelectMessage.css";
import LocalStorageKeys from "../../LocalStorage/LocalStorageKeys";
import BaseUser from "../../Models/Users/BaseUser";
import AppConstants from "../../Helpers/AppConstants";

interface ISelectMessage{
    headerSelectMessage : string
    cases : Array<ResponceCase>
    choiseAction(value : number, name : string) : void
}

function SelectMessage(props : ISelectMessage){

    const clickCaseHandler = (e : SyntheticEvent) => {
        let value = +e.currentTarget.id;
        let name = e.currentTarget.innerHTML;
        props.choiseAction(value, name);
    }

    let cases : Array<JSX.Element> = props.cases.map(item => {
        return <div  className="blockOption">
                    <label onClick = {clickCaseHandler}  id = {item.value.toString()}>{item.prompt}</label>
                </div>
    });

    let userStr = localStorage.getItem(LocalStorageKeys.user);
    let userImg = PersonImg;
    if(userStr){
        let user = JSON.parse(userStr) as BaseUser;
        if(user.circleImage && user.circleImage != PersonImg)
            userImg = AppConstants.outputImageFormat + user.circleImage;
    }
    let avatarPart = <img src= {userImg}></img>

    cases.push(<div className ="userImageAction">{avatarPart}</div>)

    return(<div className = "selectMessage">
                <div className="options">
                    {cases}
                </div>
           </div>);

}

export default SelectMessage;