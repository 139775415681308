enum ControlCommand{
    Message = 0,
    StartInterview = 1,
    StopInterview = 2,
    Answer = 3,
    ChangeAnswer = 4,
    StartOver = 5,
    NextQuestion = 6,
    BackQuestion = 7,
    SkipQuestion = 8,
    Question = 9,
    Help = 10
}

export default ControlCommand;