import Country from "./Country";
import TimeZone from "./TimeZone";

class CountyTimeZones extends Country{

    constructor(id : string, countryCodeIso : string, countryCodeIso3 : string, nameRu : string, nameEn : string, nameFr : string, timeZoneCollecction : Array<TimeZone>){
        super(id,countryCodeIso,countryCodeIso3, nameRu, nameEn, nameFr);
        this.timeZoneCollection = timeZoneCollecction;
    }

    public timeZoneCollection : Array<TimeZone>;

}

export default CountyTimeZones;