import RequestSignature from "../RequestProvider/RequestSignature";

class UserServiceConstants{

    public static readonly _registrationRequestSignature:RequestSignature=new RequestSignature("POST","/api/v1.0/Account/Registration");

    public static readonly  _registrationRequestFromDatascopeSignature : RequestSignature=new RequestSignature("POST","/api/v1.0/Account/BindingRegistration");

    public static readonly _authorizationRequestSignature:RequestSignature=new RequestSignature("POST","/api/v1.0/Account/Login");

    public static readonly _getProfileSignature:RequestSignature = new RequestSignature("GET","​/api/v1.0/Profile/Info");

    public static readonly _registrationInOrganization: RequestSignature = new RequestSignature("POST", "/api/RegistrationInOrganization");

    public static readonly _adminRegistration : RequestSignature = new RequestSignature("POST","/api/v1.1/Account/AdminRegistration");

    public static readonly _updateUserInformationById : RequestSignature = new RequestSignature("PUT","/api/v1.0/Profile/Info");

    public static readonly _changePassword : RequestSignature = new RequestSignature("POST","/api/v1.0/Account/ChangePassword");

    public static readonly _updateUserInformation : RequestSignature = new RequestSignature("POST","/api/v1.0/Account/UserInfo");

    public static readonly _getUserProfileById : RequestSignature = new RequestSignature("GET","/api/v1.0/Profile/Info");

    public static readonly _getUserProfile : RequestSignature = new RequestSignature("GET","/api/v1.0/Account/UserInfo");

    public static readonly  _deleteUser : RequestSignature = new RequestSignature("DELETE","/api/v1.0/Account/DeleteUser");

    public static readonly _requestChangePassword : RequestSignature = new RequestSignature("POST", "/api/v1.0/Account/RequestChangePassword");

    public static readonly _resetPassword : RequestSignature = new RequestSignature("POST","/api/v1.0/Account/Restore");

    public static readonly _getCountries : RequestSignature = new RequestSignature("GET","/api/v1.0/Profile/Countries");

    public static readonly _getTimeZones : RequestSignature = new RequestSignature("GET","/api/v1.0/Profile/TimeZones");

    public static readonly _getFullUserInfo : RequestSignature = new RequestSignature("GET", "/api/v1.0/Profile/UserProfile");

    public static readonly _updateFullUser : RequestSignature = new RequestSignature("PUT", "/api/v1.0/Profile/UserProfile");

    public static readonly _addFullUser : RequestSignature = new RequestSignature("POST", "/api/v1.0/Profile/UserProfile");

    public static readonly _checkUserProfile : RequestSignature = new RequestSignature("GET", "/api/v1.0/Profile/CheckUserProfile");

    public static readonly _unlink : RequestSignature = new RequestSignature("POST", "/api/v1.0/Profile/DisconnectedAccount");

    public static readonly _getLinkInfo : RequestSignature = new RequestSignature("GET", "/api/v1.0/Profile/BindingStatus")

    public static readonly _changeLanguage : RequestSignature = new RequestSignature("POST", "/api/v1.0/Account/UpdateLanguage")
}

export default UserServiceConstants;