
export interface IConvertToByteArrCallback {
    (arrByte : string) : void
}

class ImageWorker{

    private readonly _imagesFormat : Array<string> = ["image/jpg", "image/png", "image/jpeg"];

    private readonly _maxSizeFileByte = 6291456;

    public get GetImagesFormat() {
        return this._imagesFormat;
    }

    public getCurrentImage(fileList : FileList) : File {
        let files:Array<File>=[];
        for(let i=0; i < fileList.length; i++){
            files.push(fileList[i])
        }
        if(fileList.length != 0)
            return fileList[0];
        throw new Error("file not selected");
        
    }

    public convertFileToArrByte(file : File, loadCallback : IConvertToByteArrCallback) : void {
        let reader:FileReader=new FileReader();
        reader.readAsBinaryString(file);
        reader.onload=(() => {
            let byteArr : string = btoa(reader.result as string);
            loadCallback(byteArr);
        });
    }

    public isValidImageFormat(file : File) : boolean {
        return this._imagesFormat.some(item => item == file.type.toLowerCase())
    }

    public isValidSizeFile(file : File) : boolean {
        return file.size < this._maxSizeFileByte;
    }

}

export default ImageWorker;