import React from "react";
import BoxPersonalReport from "../../Components/BoxPersonalReport/BoxPersonalReport";
import Header from "../../Components/Header/Header";
import PersonalReportHeadingBlock from "../../Components/PersonalReportHeadingBlock/PersonalReportHeadingBlock";
import PersonalReportRecomendationBlock from "../../Components/PersonalReportRecomendationBlock/PersonalReportRecomendationBlock";
import SideMenuContainer from "../../Components/SideMenuContainer/SideMenuContainer";
import RoutesUrl from "../../Helpers/RoutesUrl";
import BaseUser from "../../Models/Users/BaseUser";

import HintImage from "../../Media/Img/HintImage.png"
import FirstState from "../../Media/Img/1.png";
import SecondState from "../../Media/Img/2.png";
import ThirdState from "../../Media/Img/3.png";
import FourthState from "../../Media/Img/4.png";
import FifthState from "../../Media/Img/5.png";
import FullAdImage from "../../Media/Img/fullAdImage.png";
import MinAdImage from "../../Media/Img/minAdImage.png";

import "./PersonalReport.css";
import PersonalReportImageBlock from "../../Components/PersonalReportImageBlock/PersonalReportImageBlock";
import AdUnitImageHeading from "../../Components/AdUnitImageHeading/AdUnitImageHeading";
import AdIUnitmage from "../../Components/AdUnitImages/AdIUnitmage";
import AdUnitMin from "../../Components/AdUnitMin/AdUnitMin";
import ChartWithHeader from "../../Components/ChartWithHeader/ChartWithHeader";
import { IState } from "./PersonalReport";
import DateType from "../../Models/Report/DateType";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import chartPeriodDictionary from "../../Models/Dictionarys/ChartPeriodDictionary";
import ChartOptions from "../../Models/Chat/ChartOptions";
import UserStateContainer from "../../Models/UI/UserStateContainer";
import UserStateEnum from "../../Models/Report/UserStateEnum";
import IReportMiddlewares from "../../ContainersMiddlewares/ReportMiddlewares/IReportMiddlewares";
import UserStateRecomendation from "../../Models/Report/UserStateRecomendation";
import Footer from "../../Components/Footer/Footer";


interface IPersonalReport{
    reportMiddlewares : IReportMiddlewares
    user : BaseUser
    isLoadReport : boolean
    state : IState
    userStateRec : UserStateRecomendation
    textProvider : LanguagePhrase
}

function PersonalReportUI(props : IPersonalReport){


    const [dateType, usedateType] = React.useState(DateType.ByWeek);


    const changeDateType = (dateTypeStr : string) => {
        let dateType : DateType = DateType[dateTypeStr as keyof typeof DateType];   
        usedateType(dateType);
    }

    let chartStrategy = chartPeriodDictionary.GetValueByKey(dateType) as ChartOptions;

    let nowReport = chartStrategy?.PeriodChoiser.GetReport(props.state.reports);

    let periods : Array<Date> = props.reportMiddlewares.GetReportPeriod(nowReport);

    let periodsView = chartStrategy.PeriodBuilder.GetPeriods(periods, props.textProvider.daysOfWeek);

    let chartOptions = props.reportMiddlewares.GetValuesReport(nowReport, periods);

    let contentRecomedation = (<div style = {{marginBottom : "5vh"}}><BoxPersonalReport height = "39vh"  children = {<div className = "waitChartBlock">{props.textProvider.notReadyRecomendation}</div>}/> </div>)

    if(props.isLoadReport){

        let nowRec = props.reportMiddlewares.GetRecomendationFullText(props.textProvider, props.userStateRec.recomendationNumber, props.userStateRec.userState);

        contentRecomedation =  (<div className = "PersonalReportsCouple">
                                    <div>
                                    <BoxPersonalReport
                                        height = "100%"
                                        children = {<PersonalReportRecomendationBlock
                                                        heading = {props.textProvider.recomendation}
                                                        text = {nowRec}
                                                    />}
                                    />
                                </div>
                                <div>
                                    <BoxPersonalReport
                                        height = "100%"
                                        children = {<PersonalReportImageBlock 
                                            textProvider={props.textProvider}
                                            state={props.reportMiddlewares.GetUserState(props.textProvider, props.userStateRec.userState)} />}
                                    />
                                </div>
                            </div>);
        
    }


    return (<div>
                <Header
                    textProvider = {props.textProvider}
                    heading = {""}
                    isShowLogo = {true}
                />
                <SideMenuContainer
                    textProvider = {props.textProvider}
                    uri = {window.location.pathname}
                    uriChat = {RoutesUrl.Chat + "/" + props.user.evaChatId}
                />
                <div className = "PersonalReports">
                    <div className = "PersonalReportsHeadingText">
                        {props.textProvider.personalReport}
                    </div>
                    <PersonalReportHeadingBlock
                        textProvider = {props.textProvider}
                        userName = {props.user.userName}
                    />
                        {contentRecomedation}
                       
                    <div className = "PersonalReportsCouple">
                        <div className = "chartChildrenBox">
                        <BoxPersonalReport
                                height = "auto"
                                children = {
                                    (props.isLoadReport) ?
                                                    <ChartWithHeader
                                                        images = {[FirstState, SecondState, ThirdState, FourthState, FifthState]}
                                                        textProvider = {props.textProvider}
                                                        chartsOption={chartOptions}
                                                        periods={periodsView}
                                                        dateType = {dateType}
                                                        maxValue = {5}
                                                        minValue = {0}
                                                        stepSize = {1}
                                                        changeDateType = {changeDateType}
                                                    />
                                                        :
                                                    <div className = "waitChartBlock">{props.textProvider.reportGeneration}</div>
                                }
                            />
                        </div>
                    </div>
                </div>
                <Footer
                    text = {props.textProvider.intellectualProperty}
                />
            </div>);

}

export default PersonalReportUI;