class UserState {

    constructor(rec : Array<string>, textState : string, image : string){
        this.recomendations = rec;
        this.textState = textState;
        this.image = image;
    }

    private recomendations : Array<string>;

    private textState : string;

    private image : string;

    public GetRecomendation(num : number) : string {
        return this.recomendations[num];
    }

    public get TextState() {
        return this.textState;
    }

    public get Image() {
        return this.image;
    }
}

export default UserState;