import React from "react";

import BaseContainer from "../BaseContainer/BaseContainer";
import { RouteComponentProps } from "react-router-dom";
import IUserAction from "../../Actions/UserActions/IUserAction"
import UserActions from "../../Actions/UserActions/UserActions";

interface IChatHandlerPropsHeader {
    reload:string
}

interface IChatHandler {

}

class ChatHandler extends BaseContainer<RouteComponentProps<IChatHandlerPropsHeader>,IChatHandler>{
    private readonly _userAction : IUserAction;

    constructor() {
        super(0)
        this._userAction = new UserActions();
    }

    componentDidMount = () => {
        this._userAction.CheckAuthorizationAndGoToChatAsync(window.location.search);
    }

    render(){ return <React.Fragment></React.Fragment> }
}

export default ChatHandler;