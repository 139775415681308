import ILabelPeriodChoiser from "./ILabelPeriodChoiser";

class YearPeriodChoiser implements ILabelPeriodChoiser{

    public GetPeriods(period : Array<Date>, strPeriods : Array<string> = []) : Array<string> {
        return period.map(item => { 
            return (item.getMonth() < 9) ? "0" + (item.getMonth() + 1).toString()
                               : (item.getMonth() + 1).toString()});
    }

}

export default YearPeriodChoiser;