import { IViewAction } from "../../Hubs/Chat/ChatHub";
import Callback from "../Exceptions/Callback";


interface WaitViewAction{
    (): void
}

class StartChatSettings{

    private chatId : string;

    private errorCallback : Callback;

    private viewAction : IViewAction;

    private startWaitViewAction : WaitViewAction

    private stopWaitViewAction : WaitViewAction;

    constructor(chatId : string, errorCallback : Callback, viewAction : IViewAction, startWaitViewAction : WaitViewAction, stopWaitViewAction : WaitViewAction){
        this.chatId = chatId;
        this.errorCallback = errorCallback;
        this.viewAction = viewAction;
        this.startWaitViewAction = startWaitViewAction;
        this.stopWaitViewAction = stopWaitViewAction;
    }

    public get ChatId() : string {
        return this.chatId;
    }

    public get ErrorCallback() : Callback {
        return this.errorCallback;
    }

    public get ViewAction() : IViewAction {
        return this.viewAction;
    }

    public get StartWaitViewAction() {
        return this.startWaitViewAction;
    }

    public get StopWaitViewAction(){
        return this.stopWaitViewAction;
    }

}

export default StartChatSettings;