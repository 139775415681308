import React from "react"
import {Link} from "react-router-dom"

import Input from "../../Components/Input/Input"
import LinkArea from "../../Components/LinkArea/LinkArea";
import Button from "../../Components/SignButton/Button";

import "./SignUpPage.css"

import PenImg from "../../Media/Img/pen.png"
import PasswordImg from "../../Media/Img/password.png";
import Logo from "../../Media/Img/logo_actual_signin.png";

import { ISignUpPageState } from "./SignUpPage";
import PopUp from "../../Components/PopUp/PopUp";

import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase"
import Select from "../../Components/Select/Select";
import Language from "../../Models/Users/Language";
import RoutesUrl from "../../Helpers/RoutesUrl";

interface ISignUpUI{
    state:ISignUpPageState
    changeEmailAction(name:string,value:string):void
    changeLanguageAction(value : string) : void
    changePasswordAction(name:string,value:string):void
    changeConfirmPasswordAction(name : string, value : string) : void
    changeNameAction(name:string,value:string):void
    changeValueAction(name:string,value:any):void
    changeValueWithoutError(name:string,value:any):void
    registerUser():void
    changeStateSucReg():void
    textProvider:LanguagePhrase
    isValidValueAreas() : boolean
}

function SignUpUI(props:ISignUpUI){
    let popUp = null;

    if(props.state.isSucsessRegisrtrate)
        popUp = <PopUp
                    text = {props.textProvider.sucsessRegistrate}
                    closePopUp = {props.changeStateSucReg}
                />

    return (<React.Fragment>
            {popUp}
            <div className="SignUp">
                <div className="SignUpForm">
                    <div className="SignInFormText" ><img src={Logo} /></div>
                    <Select prompts = {[props.textProvider.english, props.textProvider.france]}
                            values = {[Language[Language.En], Language[Language.Fr]]}
                            name = "language"
                            mainPrompt = ""
                            nowValue = {Language[props.state.language]}
                            changeValue = {props.changeLanguageAction} />
                    <Input isActiveInput = {true} colorBottomBorder = {"#798F96;"} isStateValue = {true} autocomplete={false}  type = "common" errorPrompt={props.state.loginErrorPrompt} prompt={props.textProvider.firstName}  imgPrompt={PenImg} value={props.state.login} changeValueAction={props.changeNameAction} name="login"/>
                    <Input isActiveInput = {true} colorBottomBorder = {"#798F96;"} isStateValue = {true} autocomplete={false}  type = "common" errorPrompt={props.state.emailErrorPrompt} prompt={props.textProvider.email}  imgPrompt={PenImg} value={props.state.email} changeValueAction={props.changeEmailAction} name="email"/>
                    <Input  isActiveInput = {true} colorBottomBorder = {"#798F96;"} isStateValue = {true} autocomplete={false}  type = "password" errorPrompt={props.state.passwordErrorPrompt} prompt={props.textProvider.password}  imgPrompt={PasswordImg} value={props.state.password} changeValueAction={props.changePasswordAction} name="password"/>
                    <Input  isActiveInput = {true} colorBottomBorder = {"#798F96;"} isStateValue = {true} autocomplete={false}  type = "password" errorPrompt={props.state.confirmPasswordErrorPrompt} prompt={props.textProvider.confirmPassword}  imgPrompt={PasswordImg} value={props.state.confirmPassword} changeValueAction={props.changeConfirmPasswordAction} name="password"/>
                    <div className = "agreeButtonsSignUp">
                        <input checked = {props.state.isAgree} onChange={() => props.changeValueWithoutError("isAgree",!props.state.isAgree)} type="checkbox"/>
                        {LinkArea({linkParts: props.textProvider.privacyPolicyAgree})}

                        <input checked = {props.state.isAdult} onChange={() => props.changeValueWithoutError("isAdult",!props.state.isAdult)} type="checkbox"/>
                        {LinkArea({linkParts: props.textProvider.privacyDataAgree})}
                    </div>
                    <Button isAgree={props.state.isAgree && props.state.isAdult && props.isValidValueAreas()} prompt={props.textProvider.signUpBut} clickAction={props.registerUser}/>
                    <Link className="NavigationLink" to={RoutesUrl.SignIn + window.location.search}>{props.textProvider.signInBut}</Link>
                </div>
            </div>
            </React.Fragment>);
}

export default SignUpUI;
