import {ChartConfiguration, ChartDataset} from 'chart.js';

import ChartOptionClass from "../../Models/ChartModels/ChartOption";
import ArrayChartOption from "../../Models/ChartModels/ArrayChartOption";

class ChartOption{


     public GetOption(chartsOption : ArrayChartOption, periods:Array<string>, max : number, min : number, step : number):ChartConfiguration {

        let datasets=this.GetDatasets(chartsOption.GetGhartOptions);
        let option : ChartConfiguration = {
            type: 'bar',
            data:{
                datasets,
                labels:periods,
            },
            options : {
                animation : {
                    duration : 0
                },
                scales : {

                    y: {
                        min,
                        max,
                        ticks: {
                          display : false,
                          stepSize: step
                        }
                      }
                }
            }
        }
        return option;
     }

     private GetDatasets(chartsOption : Array<ChartOptionClass>)
     {
        let dataSets : Array<ChartDataset> = chartsOption.map(item => {
            return {
                data : item.data._values,
                label : item.data._name,
                stepped: true,
                borderColor : item.color,
                backgroundColor : item.color,
                fill : item.isFill,
            }
        });
        return dataSets;
     }

}

export default ChartOption;

