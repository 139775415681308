class ActionChatHub {

    public static readonly enterChat : string = "EnterChat";

    public static readonly sendToChat : string = "SendToChat";

    public static readonly createChat : string = "CreateChat";

    public static readonly getMessages : string = "GetMessages";

    public static readonly loadingOffsetMessages : string = "LoadingOffsetMessages";

    public static readonly getChats : string = "GetChats";

    public static readonly receive : string = "Receive";
    
}

export default ActionChatHub;