class ActionsConstants{

    public static readonly receive : string="Receive";

    public static readonly notify : string="Notify";

    public static readonly error : string = "Error";

}

export default ActionsConstants;