interface IAction {
    () : void
}

class SideMenuModel {


    public static GetSideMenuModel(text : string, image : string, darkImage : string ,linkTo : string ,action : IAction = () => {}){
        return new SideMenuModel(text, image, darkImage ,linkTo,action);
    }

    constructor(text : string, image : string, darkImage : string, linkTo : string,action : IAction = () => {}){
        this.text = text;
        this.brightImage = image;
        this.darkImage = darkImage;
        this.linkTo = linkTo;
        this.action = action;
    }

    private text : string;

    private brightImage : string;

    private darkImage : string;

    private linkTo : string;

    private action : IAction;

    public get Text(){
        return this.text;
    }

    public isEqualsUriPattern(uri : string){
        return uri.includes(this.linkTo);
    }

    public get BrightImage(){
        return this.brightImage;
    }

    public get DarkImage(){
        return this.darkImage;
    }

    public get LinkTo(){
        return this.linkTo;
    }

    public get Action(){
        return this.action;
    }

}

export default SideMenuModel;