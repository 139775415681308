import React, { CSSProperties, useEffect } from "react";

import "./SideMenu.css";

import LogoImg from "../../Media/Img/logo_actual_main.png";
import { Link } from "react-router-dom";
import SideMenuModel from "../../Models/UI/SideMenu";
import CloseImg from "../../Media/Img/close.png";
import CloseWhiteImg from "../../Media/Img/closeWhite.png";
import BurgerBlackImg from "../../Media/Img/burgerBlack.png";
import GuidGenerator from "../../Helpers/GuidGenetator";
import AppConstants from "../../Helpers/AppConstants";
import BaseContainer from "../../Containers/BaseContainer/BaseContainer";
import AppContext, { IAppState } from "../../AppContext";


interface IState {
    isOpenMenu : boolean
}

interface ISideMenu{
    points : Array<SideMenuModel>
    activeNumber : number
    closeAction() : void
}

interface IOperation {
    (step : number, element : HTMLElement) : string
}

class SideMenuClass extends BaseContainer<ISideMenu, IState>{

    constructor(props : any){
        super(props);
        window.addEventListener("resize", this.changeWindowSizeHandler)
        let displayWidth = document.documentElement.clientWidth;
        this.initWidthSideMenu();
        this.state = {
          isOpenMenu : false
        };
    }

    changeWindowSizeHandler = () => {
        this.initWidthSideMenu();
    }

    initWidthSideMenu = () => {
        let displayWidth = document.documentElement.clientWidth;
        if(displayWidth <= 1024){
            this.maxWidth = 80;
            this.step = 8;
        }
        else{
            this.maxWidth = 25;
            this.step = 2;
        }
    }

    private readonly linkId = new GuidGenerator().GenerateGuid();

    private maxWidth : number = 0;

    private readonly minWidth = 6;

    private readonly id = "hiddenSideMenu";

    private step : number = 0;

    private readonly time = 10;

    private nowWidthMenu : number = 8;

    private openInterval : NodeJS.Timeout | null = null

    private closeInterval : NodeJS.Timeout | null = null;


    addFunc = (step : number, element : HTMLElement) => {
        if(this.nowWidthMenu < this.maxWidth){
            this.nowWidthMenu += step;
            return this.nowWidthMenu + "vw";
        }
        else{
            this.changeViewStateUIElemets(element, "fullSideMenu", "0 10px 10px 0");
            this.changeViewStateLinkTexts("block");
            throw new Error("stop");
        }
    }

    subFunc = (step : number, element : HTMLElement) => {
        this.changeViewStateLinkTexts("none");
        if(this.nowWidthMenu > this.minWidth){
            this.nowWidthMenu -= step;
            return this.nowWidthMenu + "vw";
        }
        else{
            this.changeViewStateUIElemets(element, "hiddenSideMenu", "10px 0 0 10px");
            throw new Error("stop");
        }
    }

    changeViewStateLinkTexts = (newDisplayLink : string) => {
        let elements = document.getElementsByClassName(this.linkId);
        for(let i = 0; i < elements.length; i++){
                let nowElement = elements[i] as HTMLElement;
                nowElement.style.display = newDisplayLink;
        }
    }

    changeViewStateUIElemets = (sideMenu : HTMLElement, newClassNameSideMenu : string, borderRadiusActiveElem : string) => {
        sideMenu.className = newClassNameSideMenu;
        let activeBlock = document.getElementsByClassName("activePointSideMenu");
        for(let i = 0; i < activeBlock.length; i++){
            let nowActiverElem = activeBlock[i] as HTMLElement;
            nowActiverElem.style.borderRadius = borderRadiusActiveElem;
        }
    }

    animationFunc = (opFunc : IOperation) => {
        let menu = document.getElementById(this.id) as HTMLElement;
        if(menu.style){
            menu.style.width = opFunc(this.step, menu);
        }
    }

    closeAnimation = () => {
        try {
            this.animationFunc(this.subFunc);
        }
        catch(e) {
            let context = this.context as IAppState;
            context.uiState.changeIsOpenState(false);
            this.setState({ isOpenMenu: false });
                clearInterval(this.closeInterval as NodeJS.Timeout);
        }
    }

    openAnimation = () => {
        try {
            this.animationFunc(this.addFunc);
        }
        catch(e) {
            let context = this.context as IAppState;
            context.uiState.changeIsOpenState(true);
            this.setState({ isOpenMenu: true });
                clearInterval(this.openInterval as NodeJS.Timeout);
        }
    }

    openFunction = () => {
        this.openInterval = setInterval(this.openAnimation, this.time);
    }

    closeFunction = () => {
        let menu = document.getElementById(this.id) as HTMLElement;
        if(menu)
            menu.className = "fullSideMenu";
        this.closeInterval = setInterval(this.closeAnimation, this.time);
    }


    componentDidMount = () => {

        let context : IAppState = this.context;

        if(this.state.isOpenMenu != context.uiState.isOpenMenu){
            this.setState({isOpenMenu : context.uiState.isOpenMenu});
        }

        if(this.state.isOpenMenu){
            this.nowWidthMenu = this.maxWidth;
        }
        else
            this.nowWidthMenu = this.minWidth;

        let burger = document.getElementById(AppConstants.burgerMenuId) as HTMLElement;

        let closeMobile = document.getElementById(AppConstants.closeMenuImg);

        if(closeMobile){
            closeMobile.addEventListener('click', () => {
                this.setState({isOpenMenu : false});
                context.uiState.changeIsOpenState(false);
                this.closeFunction();
            })
        }

        burger.addEventListener("click", () => {
            context.uiState.changeIsOpenState(!context.uiState.isOpenMenu);
            this.setState({isOpenMenu : !this.state.isOpenMenu});
            if (this.state.isOpenMenu) {
                this.openFunction();
            }
            else {
                this.closeFunction();

            }
        })

    }

    componentWillUnmount = () => {
        let context : IAppState = this.context;
        context.uiState.changeIsOpenState(false);
    }

    render(){
        let pointsHTML = this.props.points.map((item, index) => {
            return <Link to = {item.LinkTo} onClick = {item.Action} className = {(this.props.activeNumber == index) ? "activePointSideMenu" : "pointSideMenu"}>
                <img src={(this.props.activeNumber != index) ? item.DarkImage : item.BrightImage}/>
                <div style = {{display : (this.state.isOpenMenu) ? "block" : "none"}} className = { ((this.props.activeNumber == index) ? "linkSideMenuActive " : "linkSideMenu ") + this.linkId } >{item.Text}</div>
            </Link>
        });

        return(<React.Fragment>

                    <div  id = {this.id}  className={(!this.state.isOpenMenu) ? "hiddenSideMenu" : "fullSideMenu"}>

                    <div id = {AppConstants.closeMenuImg} className="closeSideMenuMobile">
                        <img  onClick = {this.props.closeAction} src={CloseImg} />
                    </div>
                    <div className="logoSideMenuContainer">
                        <img src = {LogoImg}></img>
                    </div>
                    {pointsHTML}
                </div>
            </React.Fragment>)
    }

}

SideMenuClass.contextType = AppContext;

export default SideMenuClass;
