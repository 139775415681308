import React from "react";
import IReportMiddlewares from "../../ContainersMiddlewares/ReportMiddlewares/IReportMiddlewares";
import ReportMiddlewares from "../../ContainersMiddlewares/ReportMiddlewares/ReportMiddlewares";
import RoutesUrl from "../../Helpers/RoutesUrl";
import TextProvider from "../../Helpers/TextProvider";
import NotReadyException from "../../Models/Exceptions/Reports/NotReadyException";
import AllTypeReport from "../../Models/Report/AllTypeReport";
import UserStateEnum from "../../Models/Report/UserStateEnum";
import UserStateRecomendation from "../../Models/Report/UserStateRecomendation";
import BaseUser from "../../Models/Users/BaseUser";
import BaseContainer from "../BaseContainer/BaseContainer";
import PersonalReportUI from "./PersonalReportUI";


interface IProps {

}

export interface IState {
    reports : AllTypeReport
    isLoadReport : boolean
    userStateRec : UserStateRecomendation
}

class PersonalReport extends BaseContainer<IProps, IState>{

    private readonly _user : BaseUser;

    private readonly _reportMiddlewares : IReportMiddlewares | null = null;

    constructor(props : any){
        super(props);
        let token = localStorage.getItem("token");
        if(token)
            this._reportMiddlewares = new ReportMiddlewares(token);
        else {
            window.location.href = RoutesUrl.SignIn;
        }
        this._user = JSON.parse(localStorage.getItem("user") as string) as BaseUser;

        //this._reportMiddlewares?.HandleGetReport(this.getReports);

        this.state = {
            reports : AllTypeReport.GetAllTypeReports(),
            isLoadReport : false,
            userStateRec : new UserStateRecomendation(UserStateEnum.Calm, 0)
        }
    }

    componentDidMount = () => {
        /*this._reportMiddlewares?.GetAllTypeReports()
            .then(reports => {
                this.setState({reports, isLoadReport : true});
                let lastReportValue = (reports.WeekReport) ? reports.WeekReport.sentimentAnalysisValues[reports.WeekReport.sentimentAnalysisValues.length - 1].weatherValue
                                                           : UserStateEnum.Cloudy;
            
                let numberRec = this._reportMiddlewares?.GetRecomendationNumber(lastReportValue) as number;

                let userStateRec = new UserStateRecomendation(lastReportValue, numberRec);
                this.setState({reports, isLoadReport : true, userStateRec});
                this._reportMiddlewares?.SaveRecomendation(userStateRec);

            })
            .catch((e : NotReadyException) => {
                
            })
            .catch(e => {
                //this.componentDidMount();
            })*/
    }


    render(){
        return (<PersonalReportUI
                    reportMiddlewares = {this._reportMiddlewares as ReportMiddlewares}
                    textProvider = {TextProvider.GetPhrase()}
                    state = {this.state}
                    userStateRec = {this.state.userStateRec}
                    user = {this._user}
                    isLoadReport = {this.state.isLoadReport}
               />)
    }

}

export default PersonalReport;