import ILabelPeriodChoiser from "../../Helpers/Strategies/LabelPeriod/ILabelPeriodChoiser";
import IPeriodChoise from "../../Helpers/Strategies/Period/IPeriodChoise";

class ChartOptions {

    constructor(periodChoiser : IPeriodChoise, periodBuilder : ILabelPeriodChoiser){
        this.periodBuilder = periodBuilder;
        this.periodChoiser = periodChoiser;
    }

    private periodChoiser : IPeriodChoise;

    private periodBuilder : ILabelPeriodChoiser;

    public get PeriodChoiser() {
        return this.periodChoiser;
    }

    public get PeriodBuilder() {
        return this.periodBuilder;
    }

}

export default ChartOptions;