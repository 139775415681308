import IRequestProvider from "./IRequestProvider"
import RequestSettings from "./RequestSettings"
import Dictionary from "../../Models/Collections/Dictionary";
import Action from "../../Models/Delegate/Action";
import Couple from "../../Models/Collections/Couple";
import RequestSignature from "./RequestSignature";
import RoutesUrl from "../../Helpers/RoutesUrl";

class RequestProvider implements IRequestProvider{

    private readonly uriApi = "https://api.whyeva.com";

    public async RequestAsync(requestSettings:RequestSettings):Promise<void>{
       let res = await this.DoRequest(requestSettings.RequestSignature, requestSettings.Queries, requestSettings.Data, requestSettings.Parametrs);
       if(res.status == 401)
        window.location.href = RoutesUrl.SignIn;
    }

    public async RequestWithCodeHundlerAsync(requestSettings:RequestSettings, actions:Dictionary<Number,Action<unknown>>):Promise<void>{
        await this.InternalRequest(requestSettings, actions);
    }

    public async RequestWithResponceAsync<R>(requestSettings:RequestSettings, actions : Dictionary<Number,Action<unknown>> = new Dictionary<Number, Action<unknown>>()) : Promise<R | null>{
        let res : R | null = null;
        let dictionary = new Dictionary<Number, Action<unknown>>();
        dictionary.AddItem(new Couple<Number, Action<unknown>>(200, (item) => { res = item as R }));
        //dictionary.AddItem(new Couple<Number, Action<unknown>>(204,() => {res = new Object() as R}));
        var keys = actions.GetAllKeys();
        keys.forEach(item => {
            let value = actions.GetValueByKey(item);
            if(value)
                dictionary.AddItem(new Couple(item, value))
        })
        await this.InternalRequest(requestSettings, dictionary);

        return res;
    }
    
    private async InternalRequest(requestSettings : RequestSettings, actions : Dictionary<Number, Action<unknown>>) : Promise<void> {
        
        if (!this.Is401Expected(actions)) {
            actions.AddItem(new Couple<Number, Action<unknown>>(401, (object) => {
                window.location.href = RoutesUrl.SignIn
                ;}))
                
        }

        let res = await this.DoRequest(requestSettings.RequestSignature, requestSettings.Queries, requestSettings.Data, requestSettings.Parametrs)
        let handler = this.GetHandler(actions, res.status);

        if (handler == null )
            throw new Error(this.GetErrorMessage(requestSettings, res));
        
        let result = await this.DeserializeResponce(res);
        
        handler(result || new Object());

    }

    private async DeserializeResponce(res : Response) : Promise<any>{
        try{
            return await res.json();
        }
        catch{
            return "";
        }
    }

    private Is401Expected(actions : Dictionary<Number, Action<object>>) : boolean {
        for (let key in actions) {
            if (key == "401")
                return true;
        }

        return false;
    }

    private GetHandler(actions: Dictionary<Number, Action<object>>, code : Number) : Action<object> | null {
        let action = actions.GetValueByKey(code);
        return action;
    }

    private GetErrorMessage(requestSettings : RequestSettings, res : Response) {
        return requestSettings.RequestSignature.HttpMethod + " " +
         requestSettings.RequestSignature.Uri + " returned " + res.status + " : " + res.statusText + " no handler found";
    }

    private DoRequest(signature : RequestSignature, queries : Dictionary<string, string>, data : any | null, parametrs : string):Promise<Response>{
        let uriWithQueries = this.GetUriWithQueries(this.uriApi + signature.Uri, queries);
        let completeUri = this.GetParamerts(uriWithQueries,parametrs);
        let token = localStorage.getItem("token") ?? "";
        if (signature.HttpMethod == "GET")
            return this.Get(completeUri, signature.ContentType,token);
        else{
            return this.Common(completeUri, signature.HttpMethod ,data,signature.ContentType,token);
           
        }
    }

    private GetUriWithQueries(uri : string, queries : Dictionary<string, string>) : string {
        let result = uri;
        if (queries.GetLenghtDictionary() != 0){
            result += "?";
            for (let key in queries.dictionary) {
                result += queries.dictionary[key].key + "=" + queries.GetValueByKey(queries.dictionary[key].key) + "\&";
            }
            result = result.substr(0, result.length - 1);
        }
        return result;
    }

    private GetParamerts(uri : string,parametrs : string) : string{
        if (parametrs != "")
            return uri+ "/" + parametrs;
        else 
            return uri
    }

    private async Get(uri:string, contentType : string, token : string): Promise<Response> {
        let res:Response=await fetch(uri,{
          method: "GET",
          headers: new Headers({
            'Content-Type': contentType,
            "Authorization": "Bearer " + token
          })
        });
        return res;
    }

    private async Common(uri : string, typeRequest : string, parametr : any | null, contentType : string,token : string): Promise<Response>{
        let res:Response = new Response();
        let header = new Headers({
            'Content-Type': contentType,
        });

        if(token){
            header = new Headers({
                'Content-Type': contentType,
                "Authorization": "Bearer " + token
            })
        }

        try{
        res = await fetch(uri, {
            method: typeRequest,
            headers: header,
            body : parametr
            });
        }
        catch(e){
            console.log(e);
        }
        return res;
    }

    

    
}

export default RequestProvider;