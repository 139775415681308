import React, { CSSProperties, KeyboardEvent } from "react";

import "./SettingsButton.css";

interface IButton{
    prompt:string
    clickAction():void
    isAgree:boolean
    colorText : string
    background : string
}

function SettingsButton(props : IButton){
    const onPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter") {
            props.clickAction();
            e.preventDefault();
        }
    }

    const onClick=() => {
        if(props.isAgree)
            props.clickAction();
    }

    let style : CSSProperties = {
        color : props.colorText,
        background : props.background
    }

    return(<input style = {style} value={props.prompt} type="button" onKeyDown={(props.isAgree) ? onPress : () => {}}  onClick={onClick} className={(props.isAgree) ? "SettingsButton" : "DisabledSettingsButton"}>
          </input>)
}

export default SettingsButton;