import IUserAction from "../../../Actions/UserActions/IUserAction";
import FullUserInfo from "../../../Models/Users/FullUserInfo";
import IUserProfile from "./IUserProfile";

class UpdateFullUserProfile implements IUserProfile {
    public async UpgradeAccount(user : FullUserInfo, userActons : IUserAction) : Promise<void> {
        return await userActons.UpdateFullInfo(user);
    }
}

export default UpdateFullUserProfile;