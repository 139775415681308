import IStateContainerCallback from "../../../Actions/IStateContainerCallback";
import Couple from "../../../Models/Collections/Couple";
import Dictionary from "../../../Models/Collections/Dictionary";
import Action from "../../../Models/Delegate/Action";
import UserImage from "../../../Models/Users/UserImage";
import IProfileServices from "../../../Services/Profile/IProfileServices";
import UserImageBaseClass from "./UserImageBaseClass";

class UpdateUserImage extends UserImageBaseClass{

    constructor(profileServices : IProfileServices){
        super(profileServices);
    }

    public async ModifyUserImage(userImage : UserImage, overflowException : Action<unknown>) : Promise<void> {
        await this._profileServices.UpdateUserImage(userImage, new Dictionary<Number, Action<unknown>>(new Couple(200, () => {}), new Couple(400, overflowException)));
    }

}

export default UpdateUserImage;