import LocalStorageConstats from "../../../ApplicationStrConstants/LocalStorageConstants";
import ChatHub from "../../../Hubs/Chat/ChatHub";
import Message from "../../../Models/Chat/Message";
import LocalStorageDataSource from "./LocalStorageDataSource";
import ServerDataSource from "./ServerDataSource";

abstract class IChatDataSource{

    constructor(){}

    abstract GetMessages(chatId : string) : Promise<Array<Message>>;

    static SaveMessages(chatId : string, messages : Array<Message>) : void{
        localStorage.setItem(LocalStorageConstats.Messages, JSON.stringify(messages));
    }


    static GetDataSource(chatHub : ChatHub, userId : string) : IChatDataSource{
        let dataSource : IChatDataSource = new ServerDataSource(chatHub);
        let messages = localStorage.getItem(LocalStorageConstats.Messages);
        if(messages){
            let messagesModel = JSON.parse(messages) as Array<Message>;
            if(messagesModel.length != 0){
                let lastMessage = messagesModel[messagesModel.length -1];
                if(lastMessage.ownerId != userId)
                    dataSource = new LocalStorageDataSource();
            }
        }
        return dataSource;
    }

}

export default IChatDataSource;