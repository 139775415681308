import ReportsSentiment from "../../Models/Report/ReportsSentiment";
import ReportHub, { IReportRecieveAction } from "../../Hubs/Report/ReportHub";
import IReportsServices from "../../Services/Reports/IReportsServices";
import ReportsServices from "../../Services/Reports/ReportsServices";
import RequestProvider from "../../Services/RequestProvider/RequestProvider";
import IReportMiddlewares from "./IReportMiddlewares";
import AllTypeReport from "../../Models/Report/AllTypeReport";
import DateType from "../../Models/Report/DateType";
import PeriodConverter from "../../Helpers/Strategies/Converters/PeriodConverter";
import ChartOptions from "../../Models/Chat/ChartOptions";
import ReportSentiment from "../../Models/Report/ReportsSentiment";
import ArrayChartOption from "../../Models/ChartModels/ArrayChartOption";
import ReportSentimentToChartOption from "../../Helpers/Strategies/Converters/ReportSentimentToChartOption";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import UserStateContainer from "../../Models/UI/UserStateContainer";
import UserStateEnum from "../../Models/Report/UserStateEnum";
import UserState from "../../Models/UI/UserState";
import ReportRecomedationWorkerStorage from "../../LocalStorage/Reports/ReportRecomedationWorkerStorage";
import UserStateRecomendation from "../../Models/Report/UserStateRecomendation";
import Dictionary from "../../Models/Collections/Dictionary";
import Couple from "../../Models/Collections/Couple";

import FirstState from "../../Media/Img/1.png";
import SecondState from "../../Media/Img/2.png";
import ThirdState from "../../Media/Img/3.png";
import FourthState from "../../Media/Img/4.png";
import FifthState from "../../Media/Img/5.png";

class ReportMiddlewares implements IReportMiddlewares{

    private readonly _reportWorkerLocalStorage : ReportRecomedationWorkerStorage = new ReportRecomedationWorkerStorage();

    private readonly _reportHub : ReportHub;

    private readonly _reportServices : IReportsServices;

    private readonly _dictionary : Dictionary<UserStateEnum, string> = 
                    new Dictionary<UserStateEnum, string>(new Couple(UserStateEnum.Calm, SecondState),
                                                          new Couple(UserStateEnum.Cloudy, FourthState),
                                                          new Couple(UserStateEnum.PartyCloudy, ThirdState),
                                                          new Couple(UserStateEnum.Stormy, FifthState),
                                                          new Couple(UserStateEnum.Sunny, FirstState));

    constructor(token : string){
        this._reportHub = new ReportHub("https://api.whyeva.com/report", 
                                        token, 
                                        async () => {});
        this._reportServices = new ReportsServices(new RequestProvider());
    }


    public async HandleGetReport(viewAction : IReportRecieveAction) : Promise<void> {
        await this._reportHub.OpenConnection();
        this._reportHub.CreateReport(viewAction);
    }

    public async GetReportByWeek() : Promise<ReportsSentiment> {
        return this._reportServices.GetReportByWeek();
        
    }

    public async GetReportByMonth() : Promise<ReportsSentiment> {
        return this._reportServices.GetReportByMonth();
    }

    public async GetReportByYear() : Promise<ReportsSentiment> {
        return this._reportServices.GetReportByYear();
    }

    public async GetAllTypeReports() : Promise<AllTypeReport> {
        return this._reportServices.GetAllTypeReports();
    }

    public GetValuesReport(report : ReportSentiment, periods : Array<Date>) : ArrayChartOption {
        const _converter : ReportSentimentToChartOption = new ReportSentimentToChartOption();
        return _converter.ConvertToArrayChartOption([report], periods)
    }

    public GetReportPeriod(report : ReportSentiment) : Array<Date> {
        let _periodConverter : PeriodConverter = new PeriodConverter();
        let periods : Array<Date>;
        try{
            periods = _periodConverter.GetPeriod(report);
        }
        catch(e){
            periods = [];
        }
        return periods;
    } 

    private GetRandom(min : number, max : number)  {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; 
    }

    public GetUserState(textProvider : LanguagePhrase, userStateEnum : UserStateEnum) : UserState{
        let userStateContainer = new UserStateContainer(textProvider);
        return userStateContainer.GetUserState(userStateEnum);
    }

    public GetRecomendationFullText(textProvider : LanguagePhrase, numberRec : number, userStateEnum : UserStateEnum) : string {
        let userState = this.GetUserState(textProvider, userStateEnum);
        return userState.GetRecomendation(numberRec);
    }


    public UserStateRec() : UserStateRecomendation | null{
        return this._reportWorkerLocalStorage.GetRecomendation();
    }

    public GetRecomendationNumber(userStateEnum : UserStateEnum) {
        let userStateRecomendation = this.UserStateRec();
        let numberRec : number = this.GetRandom(0, 3);
        if(userStateRecomendation){
            if(userStateRecomendation.userState == userStateEnum)
                numberRec = userStateRecomendation.recomendationNumber;
        }
        return numberRec;
    }

    public SaveRecomendation(userRecomendation : UserStateRecomendation) : void {
        this._reportWorkerLocalStorage.SetRecomendation(userRecomendation);
    }

    public GetTextState(textProvider : LanguagePhrase, userStateEnum : UserStateEnum) : string {
        let userState = this.GetUserState(textProvider, userStateEnum);
        return userState.TextState;
    }

    public GetImage(userStateEnum : UserStateEnum) : string | null {
        return this._dictionary.GetValueByKey(userStateEnum);
    }

}

export default ReportMiddlewares;