import React, { CSSProperties } from 'react'
import MessageTextArea from "../MessageTextArea/MessageTextArea"; 
import SendButton from "../SendButton/SendButton";
import "./ControlChat.css"

import Chat from "../../Containers/Chat/Chat";
import Message from '../../Models/Chat/Message';
import CommandButton from '../CommandButton/CommandButton';
import ControlCommand from '../../Models/Chat/ControlCommand';
import LanguagePhrase from '../../Helpers/LanguagePhrase/LanguagePhrase';

interface IControlChatArea{
    message:Message
    changeMessageValue(message:Message):void
    chatContainer : Chat
    userAction(message : Message, chat : Chat):void
    commandAction() : void
    isControlCommand : boolean
    textProdiver : LanguagePhrase
}

function ControlChatArea(props:IControlChatArea){
    return(<div className="controlChat">
        <MessageTextArea
            message={props.message}
            changeMessageValue={props.changeMessageValue}
            userAction={props.userAction}
            chatContainer = {props.chatContainer}
            placeholder = {props.textProdiver.enterYourText}
        />
        <CommandButton
            isActive = {props.isControlCommand}
            clickAction = {props.commandAction}
        />
        <SendButton
            userAction={props.userAction}  
            message = {props.message}
            chatContainer = {props.chatContainer}  
        /> 
    </div>)
}

export default ControlChatArea;