import ReportSentiment from "./ReportsSentiment";

class AllTypeReport {

    public static GetAllTypeReports() {
        let reportSentiment = ReportSentiment.GetReportSentiment();
        return new AllTypeReport(reportSentiment, reportSentiment, reportSentiment);
    }

    private _weekReport : ReportSentiment;

    private _monthReport : ReportSentiment;

    private _yearReport : ReportSentiment;

    constructor(weekReport : ReportSentiment, monthReport : ReportSentiment, yearReport : ReportSentiment){
        this._weekReport = weekReport;
        this._monthReport = monthReport;
        this._yearReport = yearReport;
    }


    //get
    public get WeekReport() {
        return this._weekReport;
    }

    public get MonthReport() {
        return this._monthReport;
    }

    public get YearReport() {
        return this._yearReport;
    }

}

export default AllTypeReport;