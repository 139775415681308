import React from "react"
import IUserActions from "../../Actions/UserActions/IUserAction";
import TextProvider from "../../Helpers//TextProvider";
import IUserAction from "../../Actions/UserActions/IUserAction";

import ResetPasswordUI from "./ResetPasswordUI";
import RoutesUrl from "../../Helpers/RoutesUrl";
import Preloader from "../../Components/Preloader/Preloader";
import UserActions from "../../Actions/UserActions/UserActions";
import BaseContainer from "../BaseContainer/BaseContainer";

export interface IResetPasswordState{
    email:string
    emailErrorPrompt:string
    isCorrectData:boolean
    isLoad : boolean,
    isResetPassword : boolean

 
}

class ResetPassword extends BaseContainer<{},IResetPasswordState>{
    constructor(){
        super(0);
        this.state={
            email:"",
            emailErrorPrompt:"",
            isCorrectData:true,
            isLoad : true,
            isResetPassword : false
           
        }
    }

    private readonly _userActions : IUserActions = 
                                        new UserActions();

    changeValueStateAction=(name:string,value:string) => {
        this.setState(state => {
            return {
                ...state,
                [name]:value
            }
        })
    }

    changeEmailValueAction=(value:string) => {
        if(value=="")
            this.changeValueStateAction("emailErrorPrompt",TextProvider.GetPhrase().emptyArea)
        else{
            this.changeValueStateAction("emailErrorPrompt","")
        }
        this.changeValueStateAction("email",value)
    }


    warnUser=() => {
        let textProvider=TextProvider.GetPhrase();
        if(this.state.email=="")
            this.setState({emailErrorPrompt:textProvider.emptyArea})

    }



    closePopUp = () => {
        window.location.href = RoutesUrl.SignIn;
    }

    isEmptyErrorPrompts = ():boolean => {
        return (this.state.emailErrorPrompt) == "";
    }

    isFullValuesState=():boolean => {
        return (this.state.email ) != "";
    }

    isValidValueAreas = ():boolean => {
        let result:boolean=false;
        if(this.isEmptyErrorPrompts())
            if(!this.isFullValuesState())
                this.warnUser();
            else
                result=true;
        return result;
    }

    resetPassword = () => {
        if(this.isValidValueAreas()){
            this.setState({isLoad : false});
            this._userActions.ResetPassword(this.state.email)
                .then(_ => {
                    this.setState({isResetPassword : true, isLoad : true})
                })
        }
            
    }

 
    render(){
        let content = <Preloader/>  
        if(this.state.isLoad)
            content = (<ResetPasswordUI
                state={this.state}
                changeEmailAction={this.changeEmailValueAction}
                textProvider={TextProvider.GetPhrase()}
                resetPassword = {this.resetPassword}
                closePopUp = {this.closePopUp}
            />);
        return(content);
    }
}



export default ResetPassword;