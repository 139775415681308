import React from "react";

import Input from "../../Components/Input/Input"
import Button from "../../Components/Button/Button"

import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";

import PenImg from "../../Media/Img/pen.png";

import { IResetPasswordState } from "./ResetPassword";
import RoutesUrl from "../../Helpers/RoutesUrl";
import { Link } from "react-router-dom";
import PopUp from "../../Components/PopUp/PopUp";

interface IProps{
    textProvider : LanguagePhrase
    state : IResetPasswordState
    changeEmailAction(name:string,value:string):void
    resetPassword() : void
    closePopUp() : void
}

function ResetPasswordUI(props : IProps){ 

    let popUp = null;

    if(props.state.isResetPassword)
        popUp = <PopUp
                    text = {props.textProvider.resetPasswordPrompt}
                    closePopUp = {() => {props.closePopUp();}}
                />

    return (<div className="SignIn">
        {popUp}
    <div className="SignInForm">
        <div className="SignInFormText">{props.textProvider.resetPassword}</div>
        <Input  isActiveInput = {true} colorBottomBorder = {"#798F96;"} isStateValue = {true} autocomplete={true}  type ="common" errorPrompt={props.state.emailErrorPrompt} prompt={props.textProvider.email} imgPrompt={PenImg} value={props.state.email} changeValueAction={props.changeEmailAction} name="email"/>
        <Button isAgree={true} prompt={props.textProvider.resetPassword} clickAction={props.resetPassword}/>
        <Link className="NavigationLink" to={RoutesUrl.SignIn}>{props.textProvider.signInBut}</Link>
    </div>
</div>)
}

export default ResetPasswordUI;