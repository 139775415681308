import Couple from "../../Models/Collections/Couple";
import Dictionary from "../../Models/Collections/Dictionary";
import Action from "../../Models/Delegate/Action";
import UserImage from "../../Models/Users/UserImage";
import BaseService from "../BaseService";
import RequestSettings from "../RequestProvider/RequestSettings";
import IProfileServices from "./IProfileServices";
import ProfileServicesConstants from "./ProfileServicesConstants";

class ProfileServices extends BaseService implements IProfileServices{

    public async GetUserImage(errorAction : Dictionary<Number, Action<unknown>>) : Promise<UserImage> {
        let requestSettings = new RequestSettings(
            ProfileServicesConstants.GetImage,
            new Dictionary<string,string>(),
            null);
        return await this.RequestProvider.RequestWithResponceAsync<UserImage>(requestSettings, errorAction) as UserImage;
    }

    public async UpdateUserImage(userImage : UserImage, actions : Dictionary<Number, Action<unknown>>) : Promise<void> {
        let requestSettings = new RequestSettings(
            ProfileServicesConstants.UpdateImage,
            new Dictionary<string,string>(),
            JSON.stringify(userImage));
        return await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async AddUserImage(userImage : UserImage, actions : Dictionary<Number, Action<unknown>>) : Promise<void> {
        let requestSettings = new RequestSettings(
            ProfileServicesConstants.CreateImage,
            new Dictionary<string,string>(),
            JSON.stringify(userImage));
        return await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, actions);
    }

    public async DeleteImage(id : string) : Promise<void> {
        let requestSettings = new RequestSettings(
            ProfileServicesConstants.DeleteImage,
            new Dictionary<string,string>(),
            JSON.stringify(id));
        return await this.RequestProvider.RequestWithCodeHundlerAsync(requestSettings, new Dictionary<Number, Action<unknown>>());
    }

}

export default ProfileServices;