import React, { MouseEventHandler, SyntheticEvent, useState } from "react"
import Message from "../../Models/Chat/Message";

import EvaImg from "../../Media/Img/smileEva.png";
import PersonImg from "../../Media/Img/person.png";

import "./MessageBlock.css"
import LocalStorageKeys from "../../LocalStorage/LocalStorageKeys";
import BaseUser from "../../Models/Users/BaseUser";
import AppConstants from "../../Helpers/AppConstants";
import ControlCommand from "../../Models/Chat/ControlCommand";

interface IMessageBlock{
    message:Message
    name:string
    userName:string
}

function MessageBlock(props:IMessageBlock){

    const [isShowEdit, useisShowEdit] = React.useState(false);


    const mouseOnMessageHandler = (e : SyntheticEvent)  => {
        if(e.currentTarget.className == "user")
            useisShowEdit(true);
    }

    const mouseOutMessageHandler = (e : SyntheticEvent) => {
        if(e.currentTarget.className == "user")
            useisShowEdit(false);
    }

    let imageContainer = (props.message.Image != null) ? <div className = 'imageMessage'><img src={props.message.Image} /></div> : null;

    let avatarPart : JSX.Element;
    if(props.name != "other"){
        let userStr = localStorage.getItem(LocalStorageKeys.user);
        let userImg = PersonImg;
        if(userStr){
            let user = JSON.parse(userStr) as BaseUser;
            if(user.circleImage && user.circleImage != PersonImg)
                userImg = AppConstants.outputImageFormat + user.circleImage;
        }
        avatarPart = <div className = "avatarPartUser"><img className="avatarImgUser" src= {userImg}></img></div>
    } else
        avatarPart =  <div className = "avatarPartOther"><img className="avatarImgOther" src= {EvaImg}></img></div>

    let textMessageLines : Array<string> = [];
    if(props.message.text != null && props.message.text != undefined)
        textMessageLines = textMessageLines.concat(props.message.text.split("\n"));

    let header : string[] = [];
    if (props.name == 'other' && props.message.messageQuestion && props.message.messageQuestion.header)
        header = props.message.messageQuestion.header.split('\n');
    let textMessage = header.map(h => <p>{h}</p>).concat(textMessageLines.map(item => <p>{item}</p>));
    
    /*ControlCommand.SkipQuestion ControlCommand.BackQuestion ControlCommand.StartOver ControlCommand.Help*/

    let isCommandContainer = props.name != "other" && 
        (props.message.controlCommand == ControlCommand.SkipQuestion ||
         props.message.controlCommand == ControlCommand.BackQuestion ||
         props.message.controlCommand == ControlCommand.StartOver ||
         props.message.controlCommand == ControlCommand.Help)

    let contentContainerClassName = "messageBlockContentContainer" + (isCommandContainer ? " commandContainer" : ""); 
    let contentContainer = <div className={contentContainerClassName}>{textMessage} {imageContainer} </div>

    if (props.name != 'other') {
        return(<div className={props.name}>
            {contentContainer}
            {avatarPart}
        </div>);
    } else{
        return(<div className={props.name}>
            {avatarPart}
            {contentContainer}
        </div>);
    }
}

export default MessageBlock;