import React from "react"
import IUserActions from "../../Actions/UserActions/IUserAction";
import SignInUI from "./SignInUI"
import TextProvider from "../../Helpers/TextProvider";
import IUserAction from "../../Actions/UserActions/IUserAction";
import UserActions from "../../Actions/UserActions/UserActions";
import Preloader from "../../Components/Preloader/Preloader";
import BaseContainer from "../BaseContainer/BaseContainer";

export interface ISignInPageState{
    email:string
    password:string
    emailErrorPrompt:string
    passwordErrorPrompt:string
    isCorrectData:boolean
    isLoad : boolean
    isConfirmedEmail : boolean
}

class SignInPage extends BaseContainer<{},ISignInPageState>{
    constructor(){
        super(0);

        localStorage.setItem('user', '');
        localStorage.setItem('token', '');

        this.state={
            email:"",
            password:"",
            emailErrorPrompt:"",
            passwordErrorPrompt:"",
            isCorrectData:true,
            isConfirmedEmail : true,
            isLoad : true
        }
    }

    private readonly _userActions : IUserActions = new UserActions();

    changeValueStateAction=(name:string,value:string) => {
        this.setState(state => {
            return {
                ...state,
                [name]:value
            }
        })
    }

    changeEmailValueAction=(value:string) => {
        if(value=="")
            this.changeValueStateAction("emailErrorPrompt",TextProvider.GetPhrase().emptyArea)
        else{
            this.changeValueStateAction("emailErrorPrompt","")
        }
        this.changeValueStateAction("email",value)
    }

    changePasswordValueAction=(value:string) => {
        if(value=="")   
            this.changeValueStateAction("passwordErrorPrompt",TextProvider.GetPhrase().emptyArea)
        else{
            this.changeValueStateAction("passwordErrorPrompt","")
        }
        this.changeValueStateAction("password",value)
    }

    isEmptyErrorPrompts = ():boolean => {
        return (this.state.emailErrorPrompt && this.state.passwordErrorPrompt) == ""
    }

    isFullValuesState=():boolean => {
        return (this.state.email.trim() && this.state.password.trim()) != ""
    }

    isValidValueAreas = ():boolean => {
        let result:boolean=false;
        if(this.isEmptyErrorPrompts())
            if(!this.isFullValuesState())
                this.warnUser();
            else
                result=true;
        return result;
    }

    warnUser=() => {
        let textProvider=TextProvider.GetPhrase();
        if(this.state.email=="")
            this.setState({emailErrorPrompt:textProvider.emptyArea})
        if(this.state.password=="")
            this.setState({passwordErrorPrompt:textProvider.emptyArea})
    }

    authUser=() => {
        if(this.isValidValueAreas()){
            this.setState({isLoad : false})
            this._userActions.AuthorizeAsync(this.state.email.trim(), this.state.password.trim(), this.stateContainerCallBack, this.unConfirmedEmail);
        }
    }

    stateContainerCallBack=(obj : unknown) => {
        this.setState({isCorrectData:false, isLoad : true})
    }

    unConfirmedEmail = (obj:unknown) => {
        this.setState({isConfirmedEmail : false, isLoad : true});
    }

 
    render(){
        let content = <Preloader/>
        if(this.state.isLoad)
            content = (<SignInUI
                state={this.state}
                changeEmailAction={this.changeEmailValueAction}
                changePasswordAction={this.changePasswordValueAction}
                authUser={this.authUser}
                textProvider={TextProvider.GetPhrase()}
                isCorrectDate={this.state.isCorrectData}
            />)
        return content;
    }
}



export default SignInPage;