import React from "react";
import DateType from "../../Models/Report/DateType";

import "./ChartHeader.css";
import Select from "../Select/Select";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";

interface IChartHeader{
    isShowPeriod : boolean
    textProvider : LanguagePhrase
    image : string
    text : string
    periodText : string
    periodType : DateType
    changeTypePeriod(periodType : string) : void
    changeViewChart() : void
}

function ChartHeader(props : IChartHeader) {

    let periodBlock = (props.isShowPeriod) ? <div className = "secondPartHeaderChart">
                                        <div className = "chartHeaderPeriodText">{props.periodText}</div> 
                                        <div>
                                            <Select nowValue = {props.periodType.toString()} 
                                                    mainPrompt = {""} 
                                                    name = {""} 
                                                    values = {[DateType[DateType.ByWeek], DateType[DateType.ByMonth]]}
                                                    prompts = {[props.textProvider.byWeek, props.textProvider.byMonth]}
                                                    changeValue = {props.changeTypePeriod}
                                            />
                                        </div>
                                    </div> : null

    return(<div className = "headerChart">
                <div className = "firstPartHeaderChart">
                    <div>{props.text}</div>
                    <div className = "firstPartHeaderChartImageBlock"><img onClick = {props.changeViewChart} src = {props.image}></img></div>
                </div>
                {periodBlock}
          </div>);
}

export default ChartHeader;