import React, { FormEvent, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageWorker from "../../Helpers/ImageWorker/ImageWorker";

import "./CropperComponent.css";
import FileInput from "../FileInput/FileInput";
import Button from "../Button/Button";

interface ICropperComponet {
    classNameContainer : string
    prompt : string
    nowImage : string
  
    cropperRef : React.RefObject<HTMLImageElement>
    leftButton : JSX.Element
    rightButton : JSX.Element
}

const CropperComponent = (props : ICropperComponet) => {

  const imageWorker : ImageWorker = new ImageWorker();

  let accepts : string = "";
  imageWorker.GetImagesFormat.forEach((item, index) => {
      (index == imageWorker.GetImagesFormat.length - 1) ? accepts += item : accepts += item + ",";
  });

  let content =  (<React.Fragment>
        
        <div className = {props.classNameContainer}>
                <div className = "croppedBlockBody">
                    <div className = "promptCroppedPopUp">{props.prompt}</div>
                    <div className = "croppedAreaBlock">
                        <Cropper
                            aspectRatio = {1}
                            src={props.nowImage}
                            guides={false}
                            ref={props.cropperRef}
                        />
                    </div>
                    <div className = "buttonCoupleImageLoad">
                        <div>
                            {props.leftButton}
                        </div>
                        <div>
                            {props.rightButton}  
                        </div>
                    </div>
                </div>
            
        </div>
        </React.Fragment>
  );
  return content;
}

export default CropperComponent;