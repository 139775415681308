import React, { useEffect } from "react";
import HalfHiddenMenu from "../HalfHiddenMenu/HalfHiddenMenu";
import SideMenu from "../SideMenu/SideMenu";
import ChatBotImgBright from "../../Media/Img/chatbotWhite.png";
import SettingsImgBright from "../../Media/Img/settingsSideMenu.png";
import LogOutImgBright from "../../Media/Img/exit.png";
import SideMenuModel from "../../Models/UI/SideMenu";
import ChatBotImg from "../../Media/Img/chatbotBlack.png";
import SettingsImg from "../../Media/Img/settingsSideMenuBlack.png";
import LogOutImg from "../../Media/Img/exitBlack.png";
import PersonalReportDark from "../../Media/Img/personalReportBlack.png";
import PersonalReportBright from "../../Media/Img/personalReportWhite.png";

import RoutesUrl from "../../Helpers/RoutesUrl";

import "./SideMenuContainer.css";
import AppConstants from "../../Helpers/AppConstants";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";

interface ISideMenuContainer{
    uri : string
    uriChat : string
    textProvider : LanguagePhrase
}

function SideMenuContainer(props : ISideMenuContainer){

    useEffect(() => {
        let element = document.getElementById(AppConstants.burgerMenuId);
        if(element){
            element.addEventListener("click", () => {changeState(true)});
        }
    })

    const changeState = (value : boolean) => {
        useisFullMenu(value);
    }
    
    let menu =  [SideMenuModel.GetSideMenuModel(props.textProvider.chatBotEva, ChatBotImgBright, ChatBotImg, props.uriChat), 
                 SideMenuModel.GetSideMenuModel(props.textProvider.personalReport, PersonalReportBright, PersonalReportDark ,RoutesUrl.PersonalReport),
                 SideMenuModel.GetSideMenuModel(props.textProvider.settings, SettingsImgBright, SettingsImg ,RoutesUrl.Settings),
                 SideMenuModel.GetSideMenuModel(props.textProvider.logOut , LogOutImgBright, LogOutImg ,RoutesUrl.SignIn, () => {localStorage.removeItem("token")})];

    let activePointNumber = menu.findIndex(item =>  item.isEqualsUriPattern(props.uri));

    const [isFullMenu, useisFullMenu] = React.useState(false);

    let content = <SideMenu closeAction = {() => {changeState(false);}} activeNumber = {activePointNumber} points = {menu}/> 

    return <React.Fragment>{content}</React.Fragment>;


}

export default SideMenuContainer;