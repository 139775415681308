import periodConverterDictionary from "../../../Models/Dictionarys/PeriodConverterDictionary";
import ReportSentiment from "../../../Models/Report/ReportsSentiment";

class PeriodConverter {
    
    public GetPeriod(report : ReportSentiment) : Array<Date> {
        let periodConverterStrategy = periodConverterDictionary.GetValueByKey(report.dateType);
        if(periodConverterStrategy){
            let periods = periodConverterStrategy.GetPeriod(report);
            return periods;
        }
        else
            throw new Error("unknow date type");
    }

}

export default PeriodConverter;