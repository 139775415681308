import React from "react";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import TextProvider from "../../Helpers/TextProvider";

import HeadingPeopleImage from "../../Media/Img/headingPeople.png";

import "./PersonalReportHeadingBlock.css";

interface IPersonalReportHeadingBlock{
    textProvider : LanguagePhrase
    userName : string
}

function PersonalReportHeadingBlock(props : IPersonalReportHeadingBlock){

    return(<div className ="personalReportHeadingBlock">
                <div className = "personalReporstHeadingBlockText">
                    <div>{props.textProvider.hello + ", " + props.userName + "!"}</div>
                    <div>{props.textProvider.lookAtResults}</div>
                </div>
                <div className = "headingImageBlock">
                    <img src={HeadingPeopleImage}/>
                </div>
           </div>);
}

export default PersonalReportHeadingBlock;