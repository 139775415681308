import React from "react";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";

import HintImage from "../../Media/Img/HintImage.png"
import UserStateEnum from "../../Models/Report/UserStateEnum";
import UserState from "../../Models/UI/UserState";
import UserStateContainer from "../../Models/UI/UserStateContainer";

import "./PersonalReportImageBlock.css";

interface IPersonalReportImageBlock{
    state : UserState,
    textProvider : LanguagePhrase
}

function PersonalReportImageBlock(props : IPersonalReportImageBlock){
    let container = new UserStateContainer(props.textProvider);

    const clickHandler = () => {
        if (window.innerWidth >= 1024)
            return;

        mouseHandler();
    };
    
    const getHint = () : HTMLElement | null => document.getElementById("personalReportHint");

    const mouseHandler = () => {
        let hint = getHint();
        if (!hint)
            return;

        hint.style.display = hint.style.display == 'none' || !hint.style.display ? 'block' : 'none';
    };

    let children = Object.keys(UserStateEnum)
                         .filter(key => !Number.isNaN(Number(key)))
                         .sort(key => Number(key))
                         .map(key => <p><b>{key}</b> - {container.GetUserState(Number(key) as UserStateEnum).TextState}</p>)

    return(<div className = "PersonalReportImageBlock">
            <div className="PersonalReportHintImageBlock">
                <img src={HintImage} onClick={clickHandler} onMouseOver={mouseHandler} onMouseOut={mouseHandler}/>
                <div id="personalReportHint">
                    <p>{props.textProvider.weatherHint}</p>
                    {children}
                </div>
            </div>

            <img className="PersonalReportImage" src ={props.state.Image}></img>
            <p>{props.state.TextState}</p>
          </div>);
}

export default PersonalReportImageBlock;