import Couple from "../../Models/Collections/Couple";
import Dictionary from "../../Models/Collections/Dictionary";
import Action from "../../Models/Delegate/Action";
import NotReadyException from "../../Models/Exceptions/Reports/NotReadyException";
import AllTypeReport from "../../Models/Report/AllTypeReport";
import ReportSentiment from "../../Models/Report/ReportsSentiment";
import BaseService from "../BaseService";
import RequestSettings from "../RequestProvider/RequestSettings";
import IReportsServices from "./IReportsServices";
import ReportsServicesConstants from "./ReportsServicesConstants";

class ReportsServices extends BaseService implements IReportsServices{

    public async GetReportByWeek() : Promise<ReportSentiment> {
        let requestSettings = new RequestSettings(
            ReportsServicesConstants.GetReportByWeek,
            new Dictionary<string,string>(),
            null);
        return await this.RequestProvider.RequestWithResponceAsync(requestSettings, 
            new Dictionary<number, Action<unknown>>(new Couple(204, () => {throw new NotReadyException()}))) as ReportSentiment;
    }

    public async GetReportByMonth() : Promise<ReportSentiment> {
        let requestSettings = new RequestSettings(
            ReportsServicesConstants.GetReportByMonth,
            new Dictionary<string,string>(),
            null);
        return await this.RequestProvider.RequestWithResponceAsync(requestSettings,
            new Dictionary<number, Action<unknown>>(new Couple(204, () => {throw new NotReadyException()}))) as ReportSentiment;
    }

    public async GetReportByYear() : Promise<ReportSentiment> {
        let requestSettings = new RequestSettings(
            ReportsServicesConstants.GetReportDayOfYear,
            new Dictionary<string,string>(),
            null);
        return await this.RequestProvider.RequestWithResponceAsync(requestSettings,
            new Dictionary<number, Action<unknown>>(new Couple(204, () => {throw new NotReadyException()}))) as ReportSentiment;
    }

    public async GetAllTypeReports() : Promise<AllTypeReport> {
        let reportsArr = await Promise.all([this.GetReportByWeek(), this.GetReportByMonth()]);
        return new AllTypeReport(reportsArr[0], reportsArr[1], reportsArr[1]);
    }

}

export default ReportsServices;