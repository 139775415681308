import React, { CSSProperties, useEffect } from "react";
import { Chart, ChartConfiguration, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarController, BarElement} from 'chart.js';
import ChartOptions from "./ChartOptions";
import ArrayChartOption from "../../Models/ChartModels/ArrayChartOption";
import GuidGenerator from "../../Helpers/GuidGenetator";

import "./ChartPersonalReport.css";

export interface IChartPersonalReport{
    chartsOption : ArrayChartOption
    images : Array<string>
    periods : Array<string>
    maxValue : number
    minValue : number
    stepSize : number
}

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarController, BarElement);
function ChartPersonalReport(props : IChartPersonalReport) {

    let id = new GuidGenerator().GenerateGuid();

    useEffect(() => {
        let chartCanvas:HTMLCanvasElement=document.getElementById(id) as HTMLCanvasElement;
        let chartOption:ChartOptions=new ChartOptions();
        let option=chartOption.GetOption(props.chartsOption, props.periods, props.maxValue, props.minValue, props.stepSize);
        let chart:Chart=new Chart(chartCanvas,option);
        return () => {
            chart.destroy()
        } 
    });

    let imagesBlock = props.images.map((item, index) => {
        let values = [-1, 2.5, 6.5, 10, 14];
        let style : CSSProperties = {
            top : values[index] + "vw"
        }
        return <div style = {style} className = "ImageChartY"><img src = {item} /></div>
    });

    return(<div className="ChartBlockCommon">
                {imagesBlock}
                <canvas id={id}/>
           </div>)

}

export default ChartPersonalReport;