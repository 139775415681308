import IPeriodConverterStrategy from "../../Helpers/Strategies/Converters/PeriodConverterStrategy/IPeriodConverterStrategy";
import MonthStrategy from "../../Helpers/Strategies/Converters/PeriodConverterStrategy/MonthStrategy";
import WeekStrategy from "../../Helpers/Strategies/Converters/PeriodConverterStrategy/WeekStrategy";
import YearStrategy from "../../Helpers/Strategies/Converters/PeriodConverterStrategy/YearStrategy";
import Couple from "../Collections/Couple";
import Dictionary from "../Collections/Dictionary";
import DateType from "../Report/DateType";

let periodConverterDictionary = new Dictionary<DateType, IPeriodConverterStrategy>(
    new Couple(DateType.ByMonth, new MonthStrategy()),
    new Couple(DateType.ByWeek, new WeekStrategy()),
    new Couple(DateType.OnYears, new YearStrategy()),
);

export default periodConverterDictionary;