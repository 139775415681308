import React, { Children } from "react";

import "./PopUpBackground.css";

import CloseImg from "../../Media/Img/closeWhite.png";

interface IPopUpBackground {
    closeAction() : void
    children : JSX.Element
}

function PopUpBackground(props : IPopUpBackground) {
        return (<div className = "PopUpContainerBackground">
                    <div onClick = {props.closeAction} className = "ClosePopUpContainerBackground">
                        <img src = {CloseImg}/>
                    </div>
                    {props.children}
                </div>);
}

export default PopUpBackground;