import React from "react"

import "./SignInUI.css"

import Input from "../../Components/Input/Input"
import Button from "../../Components/Button/Button"

import PenImg from "../../Media/Img/pen.png"
import PasswordImg from "../../Media/Img/password.png"

import { ISignInPageState } from "./SignInPage";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import { Link } from "react-router-dom"
import ActionButton from "../../Components/ActionButton/ActionButton"
import RoutesUrl from "../../Helpers/RoutesUrl"
import Logo from "../../Media/Img/logo_actual_signin.png";
import PrivacyArea from "../../Components/PrivacyArea/PrivacyArea"

interface ISignInUI{
    state:ISignInPageState
    changeEmailAction(name:string,value:string):void
    changePasswordAction(name:string,value:string):void
    authUser():void
    textProvider:LanguagePhrase
    isCorrectDate:boolean
}

function SignInUi(props:ISignInUI){


    let errorPrompt="";
    if(!props.isCorrectDate)
        errorPrompt=props.textProvider.unsuccsesAuth
    if(!props.state.isConfirmedEmail)
        errorPrompt = props.textProvider.confirmEmail;

    return (<div className="SignIn">
                <div className="SignInForm">
                    <div className="SignInFormText" ><img src={Logo} /></div>
                    <Input  isActiveInput = {true} colorBottomBorder = {"#798F96"} isStateValue = {true} autocomplete={true}  type ="common" errorPrompt={props.state.emailErrorPrompt} prompt={props.textProvider.email} imgPrompt={PenImg} value={props.state.email} changeValueAction={props.changeEmailAction} name="email"/>
                    <Input   isActiveInput = {true}colorBottomBorder = {"#798F96"} isStateValue = {true} autocomplete={true}  type = "password" errorPrompt={props.state.passwordErrorPrompt} prompt={props.textProvider.password} imgPrompt={PasswordImg} value={props.state.password} changeValueAction={props.changePasswordAction} name="password"/>
                    <div className="ErrorPromptAuth">{errorPrompt}</div>
                    <Button isAgree={true} prompt={props.textProvider.signInBut} clickAction={props.authUser}/>
                    {/* <Link className="NavigationLink" to={RoutesUrl.SignUp + window.location.search}>{props.textProvider.signUpBut}</Link> */}
                    <Link className="NavigationLink" to={RoutesUrl.ResetPassword + window.location.search}>{props.textProvider.resetPassword}</Link>
                </div>
            </div>)
}

export default SignInUi;
