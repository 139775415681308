import React, { KeyboardEventHandler } from "react"
import Chat from "../../Containers/Chat/Chat";

import SendButImg from "../../Media/Img/sendButton.png";
import Message from "../../Models/Chat/Message";

import "./SendBut.css"

interface ISendBut{
    userAction(message : Message, chatContainer : Chat):void
    message : Message
    chatContainer : Chat
}

function SendBut(props:ISendBut){
    
    const onClick = () => {
        props.userAction(props.message, props.chatContainer);
    }

    return (
        <div className="sendBut" >
            <img onClick={onClick} src={SendButImg} /> 
        </div>
    )
}

export default SendBut;