import React from "react";
import DateType from "../../Models/Report/DateType";
import ChartHeader from "../ChartHeader/ChartHeader";

import OpenImage from "../../Media/Img/open.png";
import MinusImg from "../../Media/Img/newMinus.png";

import ChartPersonalReport, { IChartPersonalReport } from "../ChartPersonalReport/ChartPersonalReport";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";

interface IChartHeader extends IChartPersonalReport{
    images : Array<string>
    textProvider : LanguagePhrase
    dateType : DateType
    maxValue : number
    minValue : number
    stepSize : number
    changeDateType(dateType : string) : void
}

function ChartWithHeader(props : IChartHeader) { 

    const [isShowChart, useisShowChart] = React.useState(true);

    const changeIsShowChart = () => {
        useisShowChart(!isShowChart);
    }

    let image = (isShowChart) ? MinusImg : OpenImage;
 
    let chart = (isShowChart) ? (<ChartPersonalReport
                                    images = {props.images}
                                    chartsOption= {props.chartsOption}
                                    periods={props.periods}
                                    maxValue = {props.maxValue}
                                    minValue = {props.minValue}
                                    stepSize = {props.stepSize}
                                />)
                              : null;
    return(
        <div className = "chartBlock">
            <ChartHeader
                isShowPeriod = {isShowChart}
                textProvider = {props.textProvider}
                image = {image}
                text={props.textProvider.dynamicsOfResults}
                periodText={props.textProvider.choisePeriod + ":"}
                periodType={props.dateType}
                changeTypePeriod={props.changeDateType}
                changeViewChart={changeIsShowChart}
            />
            {chart}
        </div>
    );

}

export default ChartWithHeader;