import GuidGenerator from "../../Helpers/GuidGenetator";
import ChatHub, { IViewAction } from "../../Hubs/Chat/ChatHub";
import BaseMessage from "../../Models/Chat/BaseMessage";
import ControlCommand from "../../Models/Chat/ControlCommand";
import Message from "../../Models/Chat/Message";
import MessageQuestion from "../../Models/Chat/MessageQestion";

import PartMessage from "../../Models/Chat/PartMessages";
import StartChatSettings from "../../Models/Chat/StartChatSettings";
import Couple from "../../Models/Collections/Couple";
import Dictionary from "../../Models/Collections/Dictionary";
import Action from "../../Models/Delegate/Action";
import Callback from "../../Models/Exceptions/Callback";
import Session from "../../Models/Session/Session";
import SessionStatus from "../../Models/Session/SessionStatus";
import CheckUserProfileModel from "../../Models/Users/CheckUserProfileModel";
import ChatServices from "../../Services/Chat/ChatServices";
import RequestProvider from "../../Services/RequestProvider/RequestProvider";
import SessionServices from "../../Services/Session/SessionServices";
import IUserService from "../../Services/User/IUserService";
import IUSerService from "../../Services/User/IUserService";
import UserService from "../../Services/User/UserService";
import IChatDataSource from "./ChatDataSource/IChatDataSource";
import ServerDataSource from "./ChatDataSource/ServerDataSource";

export interface IEndLoadCallBack {
    (messages : Array<Message>, checkUserProfile : CheckUserProfileModel) : void
}

class ChatMiddlewares{

    private readonly chatHub : ChatHub;

    private dataSource : IChatDataSource;

    private chatServices : ChatServices;

    private sessionServices : SessionServices;

    private session : Session | null = null;

    private startChatSettings : StartChatSettings;

    private userServices : IUserService;

    constructor( token : string, userId : string, startChatSettings : StartChatSettings){
        this.chatHub = new ChatHub("https://api.whyeva.com/chat", token, async () => {window.location.reload()});
        this.userServices = new UserService();
        this.dataSource = new ServerDataSource(this.chatHub); 
        let requestProvider = new RequestProvider();
        this.chatServices = new ChatServices(requestProvider);
        this.sessionServices = new SessionServices(requestProvider);
        this.startChatSettings = startChatSettings;
    }

    public DestroyComponentAction(chatId : string, messages : Array<Message>, userId : string) : void {
        IChatDataSource.SaveMessages(chatId, messages);
        let session = this.session;
        if(session){ 
            session.status = SessionStatus.Stop;
            this.sessionServices.UpdateSession(session);
        }
        this.chatHub.CloseConnection();
    }

    public async ChangeIsFirstTimeUserArea(value : boolean) : Promise<void>{
        await this.chatServices.ChangeIsFirstTimeUserArea(false);
    }

    
    public async InitChat(endLoadCallback : IEndLoadCallBack ) : Promise<void> {
        let checkUserProfile : CheckUserProfileModel = new CheckUserProfileModel("", false);
        let messages : Array<Message> = [];
        try{
            await this.OpenChatConnection();
            let responces = await Promise.all([ 
                                               this.dataSource.GetMessages(this.startChatSettings.ChatId), 
                                               this.sessionServices.GetCurrentSession(this.startChatSettings.ChatId, 
                                                                                      new Dictionary<number, Action<unknown>>(new Couple(204, () => {}))),
                                               this.chatHub.GetMessageInRealTime(this.startChatSettings.ViewAction),
                                               this.chatHub.Init(this.startChatSettings.ErrorCallback, () => {}),
                                               this.GetUserProfileState()
                                              ]);
            messages = responces[0];
            checkUserProfile = responces[4];
            let session : Session = responces[1];
            if(session){
                await this.ChangeSessionState(session);
                this.session = session;
            }
        }
        catch(e){
            this.startChatSettings.ErrorCallback("");
        }
        endLoadCallback(messages, checkUserProfile);
        
    }

    public async GetUserProfileState() : Promise<CheckUserProfileModel> {
        return this.userServices.CheckUserProfile();
    }

    public async OpenChatConnection() : Promise<void> {
        await this.chatHub.OpenConnection();
        await this.chatHub.EnterChat(this.startChatSettings.ChatId)
    }

    public async ReconnectionAction() : Promise<void> {
        this.startChatSettings.StartWaitViewAction();
        await this.OpenChatConnection();
        this.startChatSettings.StopWaitViewAction();

    }

    private async ChangeSessionState(session : Session) : Promise<void> {
        if(session.status == SessionStatus.Stop){
            session.status = SessionStatus.Open;
            await this.sessionServices.UpdateSession(session);
        }
    }

    public async GetPartMessages(partMessages : PartMessage) : Promise<Array<BaseMessage>>{
        return await this.chatHub.LoadingOffsetMessages(partMessages);
    }

    public InitGetMessage(viewAction : IViewAction){
        this.chatHub.GetMessageInRealTime(viewAction);
    }

    public async SendToChat(message : Message) : Promise<string> {
        return await this.chatHub.SendToChat(message);
    }

}

export default ChatMiddlewares;