import LocalStorageConstats from "../../../ApplicationStrConstants/LocalStorageConstants";
import ChatHub from "../../../Hubs/Chat/ChatHub";
import Message from "../../../Models/Chat/Message";
import IChatDataSource from "./IChatDataSource";

class ServerDataSource implements IChatDataSource{

    private _chatHub : ChatHub;

    constructor(chatHub : ChatHub){

        this._chatHub = chatHub;
    }

    public GetMessages(chatId : string) : Promise<Array<Message>>{
        return this._chatHub.GetMessages(chatId);
    }

   

}

export default ServerDataSource;