import React, {KeyboardEvent} from "react"

import "./Button.css"

interface IButton{
    prompt:string
    clickAction():void
    isAgree:boolean
}

function Button(props:IButton){
    let className:string="DisabledButton";
    if(props.isAgree)
        className="Button"


    const onPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter") {
            props.clickAction();
            e.preventDefault();
        }
    }

    const onClick=() => {
        if(props.isAgree)
            props.clickAction();
    }

    return(<input value={props.prompt} type="button" onKeyDown={onPress}  onClick={onClick} className={className}>
          </input>)
}

export default Button;