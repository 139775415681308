import React from "react";
import Input from "../../Components/Input/Input";
import Language from "../../Models/Users/Language";

import Select from "../../Components/Select/Select";
import { ISettings } from "./Settings";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import Header from "../../Components/Header/Header";

import StandartPersomImg from "../../Media/Img/person.png";

import "./Settings.css";
import SideMenuContainer from "../../Components/SideMenuContainer/SideMenuContainer";
import SettingsButton from "../../Components/SettingsButton/SettingsButton";
import RoutesUrl from "../../Helpers/RoutesUrl";
import Gender from "../../Models/Users/Gender";
import FamilyStatus from "../../Models/Users/FamilyStatus";
import DateInput from "../../Components/DateInput/DateInput";
import EnglishPhrase from "../../Helpers/LanguagePhrase/EnglishPhrase";
import FranchPhrase from "../../Helpers/LanguagePhrase/FranchPhrase";
import TextProvider from "../../Helpers/TextProvider";
import CountyTimeZones from "../../Models/Users/CountyTimeZones";
import Footer from "../../Components/Footer/Footer";
import CropperContainer from "../../Components/CropperContainer/CropperContainer";
import UserImage from "../../Models/Users/UserImage";
import DatascopeConnection from "../../Components/DatascopeConnection/DatascopeConnection"
import PopUp from "../../Components/PopUp/PopUp";
import AppConstants from "../../Helpers/AppConstants";
import DataScopeLink from "../../Models/DataScopeLink/DataScopeLink";



interface ISettingsPageUI{
    state : ISettings
    modifyUserAction(userImage :UserImage) : void
    changeLanguage(nowLang : string) : void
    changeEmailAction(value : string, name : string):void
    changeNameAction(value : string, name : string):void
    changeUserProfile() : void
    changeSex(value : string, name : string) : void
    changeBirthDate(value : Date) : void
    changeFamilyStatus(value : string, name : string) : void
    changeCountry(value : string, name : string) : void
    changeChildCount(value : string, name : string) : void
    changeTimezone(value : string, name : string) : void
    rollbackChanges() : void
    isValidValueAreas() : boolean
    getLinkInfo() : Promise<DataScopeLink>
    unLink() : Promise<boolean>
    textProvider : LanguagePhrase
    backUri : string
}

function SettingsUI(props:ISettingsPageUI){

    const deleteUserHandler = () : void => {
        let result = window.confirm(props.textProvider.deleteAccountConfirm)
        if (!result)
            return;

        props.state.deleteUser()
    }

    const [isOpenCropper, useisOpenCropper] = React.useState(false);

    const changeOpenStateCropper = () => {
        useisOpenCropper(!isOpenCropper);
    }

    let allCountries = props.state.allCountries.sort((a, b) => {
        if(props.textProvider instanceof EnglishPhrase)
            return (a.nameEn < b.nameEn ? -1 : 1);
        if (props.textProvider instanceof FranchPhrase)
            return (a.nameFr < b.nameFr ? -1 : 1);
        return (a.nameRu < b.nameRu ? -1 : 1);
    });

    let allTimeZones = props.state.allTimeZones.map(item => {
        return new CountyTimeZones(item.id, item.countryCodeIso, item.countryCodeIso3, item.nameRu, item.nameEn, item.nameFr, item.timeZoneCollection.sort((a, b) => a.utc < b.utc ? -1 : 1))
    });

    const [popUpText, usepopUpText] = React.useState("");

    let popUp = (popUpText != "") ? <PopUp text = {popUpText} closePopUp = {() => {usepopUpText("")}} />  : null;

    let cropperComponent = (isOpenCropper) ? <CropperContainer textProvider = {props.textProvider} modifyUserImageAction = {props.modifyUserAction} closeAction = {changeOpenStateCropper} nowImage = {props.state.userImage}/> : null; 
    return(<div className = "userSettingsContainer">
        {cropperComponent}

        <Header
            textProvider = {props.textProvider}
            isShowLogo = {true}
            heading = {""}
        />
        <SideMenuContainer
            textProvider = {props.textProvider}
            uri = {window.location.pathname}
            uriChat = {RoutesUrl.Chat + "/" + props.state.chatId}
        />
        <div className = "settingsPage">

            <div className = "settingPageUserInfoContainer">
                <div >
                    <div  onClick = {changeOpenStateCropper} className="settingsUserAvatar">
                        <img src={(props.state.userImage.imageBytes != StandartPersomImg) ? AppConstants.outputImageFormat  + props.state.userImage.imageBytes : props.state.userImage.imageBytes} />
                    </div>
                    <p  onClick = {changeOpenStateCropper}  className = "buttonForAddImage">{props.textProvider.loadImage}</p>
                </div>
                <div className="settingsPageForm">
                    <div className="BlockNameSettings">{props.textProvider.personalInfo}</div>
                    <div className = "BlockNameSettingsMandatory">{props.textProvider.mandatoryFilling}</div>
                    <div className="coupleAreaSettings">
                        <div className="inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.interfaceLanguage}</div>
                            <Select
                                nowValue = {Language[props.state.language]}
                                mainPrompt = ""
                                name = "language"
                                values = {[Language[Language.En], Language[Language.Fr]]}
                                prompts = {[props.textProvider.english, props.textProvider.france]}
                                changeValue = {props.changeLanguage}
                            />
                        </div>
                        <div className="inputContainerSettings"></div>
                    </div>
                    <div className="coupleAreaSettings">
                        <div className="inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.login}</div>
                            <Input isActiveInput = {true} colorBottomBorder = {"#798F96;"} isStateValue = {true} autocomplete={false}  type = "common" errorPrompt={props.state.loginErrorPrompt} prompt={props.textProvider.firstName}  imgPrompt={""} value={props.state.login} changeValueAction={props.changeNameAction} name="l"/>
                        </div>
                        <div className="inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.email}</div>
                            <Input  isActiveInput = {true} colorBottomBorder = {"#798F96;"} isStateValue = {true} autocomplete={false}  type = "common" errorPrompt={props.state.emailErrorPrompt} prompt={props.textProvider.email}  imgPrompt={""} value={props.state.email} changeValueAction={props.changeEmailAction} name="e"/>
                        </div>
                    </div>
                    <div className="coupleAreaSettings">
                        <div className = "inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.sex}</div>
                            <Select changeValue = {props.changeSex} 
                                    nowValue = {(props.state.sex != Gender.NotFound) ? Gender[props.state.sex] : ""} 
                                    mainPrompt = {""} 
                                    name = "sex" 
                                    values = {[Gender[Gender.NotFound], Gender[Gender.Male], Gender[Gender.Female]]} 
                                    prompts = {[props.textProvider.notSpecified, props.textProvider.male, props.textProvider.female]}/>
                        </div>
                        <div className="inputContainerSettings mobileNotDisplay">
                            <div className="labelSettingsArea">{props.textProvider.birthDate}</div>
                            <DateInput
                                language = {TextProvider.GetLetterDesignation()}
                                nowValue = {props.state.birthDate}
                                changeDate = {props.changeBirthDate}
                            />
                        </div>
                    </div>
                    <div className="coupleAreaSettings">
                        <div className = "inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.femaleStatus}</div>
                            <Select changeValue = {props.changeFamilyStatus} 
                                    nowValue = {FamilyStatus[props.state.familyStatus]} 
                                    mainPrompt = {""} 
                                    name = "familyStatus" 
                            values = {[FamilyStatus[FamilyStatus.NotFound], FamilyStatus[FamilyStatus.CivilUnion], FamilyStatus[FamilyStatus.CommonLawMarriage], FamilyStatus[FamilyStatus.Divorced], 
                                       FamilyStatus[FamilyStatus.Married], FamilyStatus[FamilyStatus.Single], FamilyStatus[FamilyStatus.Widow]]} 
                                                    prompts = {[props.textProvider.notSpecified, props.textProvider.civilUnion, props.textProvider.commonLawMarriage, props.textProvider.divorced,
                                                                props.textProvider.married, props.textProvider.single, props.textProvider.widow]}/>
                        </div>
                        <div className="inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.countryOfResidence}</div>
                            <Select
                                changeValue = {props.changeCountry}
                                name = "country"
                                mainPrompt = ""
                                nowValue = {props.state.countryIso}
                                values = {allCountries.map((item, index) => item.countryCodeIso)}
                                prompts = {allCountries.map(item => {
                                    if(props.textProvider instanceof EnglishPhrase)
                                        return item.nameEn;
                                    if(props.textProvider instanceof FranchPhrase)
                                        return item.nameFr
                                    return item.nameRu
                                })}
                            />
                        </div>
                    </div>
                    <div className="coupleAreaSettings">
                        <div className = "inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.childCount}</div>
                            <Input
                                isActiveInput = {true}
                                colorBottomBorder = {"#798F96;"}
                                isStateValue = {true} 
                                autocomplete={false} 
                                type = "number" 
                                errorPrompt={""} 
                                prompt={props.textProvider.childCount}  
                                imgPrompt={""} 
                                value={props.state.childCount.toString()} 
                                changeValueAction={props.changeChildCount} 
                                name="login"
                            />
                        </div>
                        <div className="inputContainerSettings">
                            <div className="labelSettingsArea">{props.textProvider.timeZone}</div>
                            <Select
                                changeValue = {props.changeTimezone}
                                name = "timeZone"
                                mainPrompt = ""
                                nowValue = {props.state.timezoneId}
                                values = {allTimeZones.flatMap(item => {
                                    return item.timeZoneCollection.map(insideItem => insideItem.id)
                                })}
                                prompts = {allTimeZones.flatMap(item => {
                                    return item.timeZoneCollection.map(insideItem => {return "(" + insideItem.utc + ")" + " " + insideItem.timeZoneName})
                                })}
                            />
                        </div>
                    </div>

                    <div className = "settingsUserProfileButtons">
                        <SettingsButton
                            prompt = {props.textProvider.cancelBut}
                            clickAction = {props.rollbackChanges}
                            isAgree = {true}
                            background = {"white"}
                            colorText = {"black"}
                        />
                        <SettingsButton
                            prompt = {props.textProvider.save}
                            clickAction = {props.changeUserProfile}
                            isAgree = {(props.isValidValueAreas())}
                            background = {"#5F8BD9"}
                            colorText = {"white"}
                        />
                    </div>
                </div>
            </div>
            <DatascopeConnection 
                textProvider={props.textProvider}
                getLinkInfo={props.getLinkInfo}
                unLink={props.unLink}/>
            <div className = "settingsSystem">
                <div className="BlockNameSettings">{props.textProvider.chatBotEva}</div>
                <div  className="versionApp">{props.textProvider.programVersion + " 3.14.2"}</div>
                <p><a target = "_blank" style = {{color : "#5F8BD9", textDecoration : "none"}} href={AppConstants.getPolicyLink(props.textProvider.lang)}>{props.textProvider.appUseConditions}</a></p>
                <p>
                    <a target = "_blank" style = {{color : "#5F8BD9",textDecoration : "none"}} href={AppConstants.getPolicyLink(props.textProvider.lang)}>{props.textProvider.dataProtectionConditions}</a>
                </p>
                <div className="settingsButtons">
                    <div>
                        <div className = "buttonCallback">
                            <a className = "linkToEmailFeedback" href="mailto:support@whyeva.com">{props.textProvider.reportAboutError}</a>
                        </div>
                    </div>
                    <div>
                        <div className="buttonDeleteUser" onClick={deleteUserHandler}>{props.textProvider.deleteAccount}</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer
            text = {props.textProvider.intellectualProperty}
        />
      </div>);
}

export default SettingsUI;