import ArrayChartOption from "../../../Models/ChartModels/ArrayChartOption";
import ChartData from "../../../Models/ChartModels/ChartData";
import ChartOption from "../../../Models/ChartModels/ChartOption";
import ReportSentiment from "../../../Models/Report/ReportsSentiment";

class ReportSentimentToChartOption{
    public ConvertToChartOption(report : ReportSentiment, dates : Array<Date> , 
                                fullIndexes : Array<number> , nameChart : string ,
                                color : string, isFill : boolean) {
        let values : Array<number> = new Array<number>(dates.length);

        let valuesIndex = 0;

        if (dates.length > 0)
        {
            let startTime = new Date(dates[0].getFullYear(), dates[0].getMonth(), dates[0].getDate()).getTime();
            let endTime = new Date(dates[dates.length - 1].getFullYear(), dates[dates.length - 1].getMonth(), dates[dates.length - 1].getDate()).getTime();
            let sortedReportsValues = report.sentimentAnalysisValues
                                            .sort((a, b) => (new Date(a.postTime) <= new Date(b.postTime)) ? -1 : 1)
                                            .filter(item => {
                                                let itemTime = new Date(item.postTime).getTime();
                        
                                                return itemTime >= startTime && itemTime <= endTime;
                                            });
    
            for(let index = 0; index < dates.length; index++) {
                if(fullIndexes.find(insideItem => insideItem == index) != undefined){
                    values[index] = sortedReportsValues[valuesIndex].weatherValue;
                    valuesIndex++;
                }
                else
                    values[index] = NaN;
    
            }
        }

        return new ChartOption(new ChartData(nameChart, values), color, isFill);
    }

    private GetNotEmptyIndexes(report : ReportSentiment, datePeriod : Array<Date>) : Array<number> {
        let indexes : Array<number> = []; 
        report.sentimentAnalysisValues.forEach(item => {
            let nowDate = new Date(item.postTime);
            let nowIndex = datePeriod.findIndex(internalItem => {
                return internalItem.getFullYear() == nowDate.getFullYear() 
                       && internalItem.getMonth() == nowDate.getMonth() 
                       && internalItem.getDate() == nowDate.getDate() 
            });
            if(nowIndex != -1)
                indexes.push(nowIndex);
        });
        return indexes;
    }

    public ConvertToArrayChartOption(reports : Array<ReportSentiment>, dateArr : Array<Date>) {
        let arraysChartOption = reports.map(item => {
            let fillIndexes = this.GetNotEmptyIndexes(item, dateArr);
            return this.ConvertToChartOption(item, dateArr ,fillIndexes, "", "#92E3DA", true);
        });
        return new ArrayChartOption(arraysChartOption);
    }

}

export default ReportSentimentToChartOption;