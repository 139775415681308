import ReportMiddlewares from "../../ContainersMiddlewares/ReportMiddlewares/ReportMiddlewares";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import UserStateEnum from "../Report/UserStateEnum";
import UserState from "./UserState";

class UserStateContainer {

    private sunnyUserState : UserState;

    private stormyUserState : UserState;

    private cloudyUserState : UserState;

    private calmUserState : UserState;

    private partyCloudyState : UserState;

    constructor(textProvider : LanguagePhrase){
        let middleware = new ReportMiddlewares("");

        this.sunnyUserState = new UserState([textProvider.firstSunnyRecomendation, 
                                             textProvider.secondSunnyRecomendation, 
                                             textProvider.thirdSunnyRecomendation],
                                             textProvider.sunny,
                                             middleware.GetImage(UserStateEnum.Sunny) ?? "");
        
        this.stormyUserState = new UserState([textProvider.firstStormyRecomendation, 
                                             textProvider.secondStormyRecomendation, 
                                             textProvider.thirdStormyRecomendation],
                                             textProvider.stormy,
                                             middleware.GetImage(UserStateEnum.Stormy) ?? "");


        this.cloudyUserState = new UserState([textProvider.firstCloudyRecomendation, 
                                             textProvider.secondCloudyRecomendation, 
                                             textProvider.thirdCloudyRecomendation],
                                             textProvider.cloudy,
                                             middleware.GetImage(UserStateEnum.Cloudy) ?? "");

        this.calmUserState = new UserState([textProvider.firstCalmRecomendation, 
                                             textProvider.secondCalmRecomendation, 
                                             textProvider.thirdCalmRecomendation],
                                             textProvider.calm,
                                             middleware.GetImage(UserStateEnum.Calm) ?? "");

        this.partyCloudyState = new UserState([textProvider.firstPartlyCloudyRecomendation, 
                                             textProvider.secondPartlyCloudyRecomendation, 
                                             textProvider.thirdPartlyCloudyRecomendation],
                                             textProvider.partlyCloudy,
                                             middleware.GetImage(UserStateEnum.PartyCloudy) ?? "");
                                            
    }

    public GetUserState(userState : UserStateEnum) : UserState {
        switch(userState) {
            case UserStateEnum.Calm :
                return this.calmUserState;
            case UserStateEnum.Sunny :
                return this.sunnyUserState;
            case UserStateEnum.Cloudy :
                return this.cloudyUserState;
            case UserStateEnum.Stormy :
                return this.stormyUserState;
            case UserStateEnum.PartyCloudy :
                return this.partyCloudyState;

            default: throw new Error(`User state ${userState} not implemented`)
        }
    }

}

export default UserStateContainer;
