import Dictionary from "./Dictionary"
import Couple from "./Couple"
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import EnglishPhrase from "../../Helpers/LanguagePhrase/EnglishPhrase";
import FranchPhrase from "../../Helpers/LanguagePhrase/FranchPhrase";
import Language from "../Users/Language";

let languageDictionary:Dictionary<string,LanguagePhrase>=new Dictionary<string,LanguagePhrase>(
                                                                                               new Couple("fr",new FranchPhrase()),
                                                                                               new Couple("en",new EnglishPhrase()))
export let languageEnum: Dictionary<string,Language> = new Dictionary<string,Language>(new Couple("fr",Language.Fr), new Couple("en", Language.En));


export default languageDictionary;