import AuthUser from "./AuthUser";
import Language from "./Language";


class RegistrateUser extends AuthUser {
    constructor(id :string,password:string,userName:string,email:string, evaChatId : string ,isFirstStart : boolean = true ,langKey : Language = Language.Fr, circleImage : string = "") {
        super(email,password);
        this.employeeId = id;
        this.userName=userName;
        this.evaChatId = evaChatId;
        this.isFirstStart = isFirstStart;
        this.languageType = langKey;
        this.circleImage = circleImage;
    }

    public employeeId : string

    public userName:string

    public evaChatId : string

    public isFirstStart : boolean

    public circleImage : string;

    public languageType : Language

    public set LanguageType(value : Language){
        this.languageType = value;
    }
}

export default RegistrateUser;