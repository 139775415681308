import UserStateEnum from "./UserStateEnum";

class UserStateRecomendation {

    public userState : UserStateEnum;

    public recomendationNumber : number;

    constructor(userState : UserStateEnum, recNumber : number){
        this.userState = userState;
        this.recomendationNumber = recNumber;
    }

}

export default UserStateRecomendation;