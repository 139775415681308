import ChartData from "./ChartData";

class ChartOption{

    public data : ChartData;

    public color : string;

    public isFill : boolean

    constructor(data : ChartData, color : string, isFill : boolean = false){
        this.data = data;
        this.color = color;
        this.isFill = isFill;
    }

}

export default ChartOption;