import MonthPeriodChoiser from "../../Helpers/Strategies/LabelPeriod/MonthPeriodChoiser";
import WeekPeriodChoiser from "../../Helpers/Strategies/LabelPeriod/WeekPeriodChoiser";
import YearPeriodChoiser from "../../Helpers/Strategies/LabelPeriod/YearPeriodChoiser";
import MonthChoisePeriod from "../../Helpers/Strategies/Period/MonthChoisePeriod";
import WeekChoisePeriod from "../../Helpers/Strategies/Period/WeekChoisePeriod";
import YearChoisePeriod from "../../Helpers/Strategies/Period/YearChoisePeriod";
import ChartOptions from "../Chat/ChartOptions";
import Couple from "../Collections/Couple";
import Dictionary from "../Collections/Dictionary";
import DateType from "../Report/DateType";

let chartPeriodDictionary : Dictionary<DateType, ChartOptions> = new Dictionary<DateType, ChartOptions>(
    new Couple(DateType.ByWeek, new ChartOptions(new WeekChoisePeriod(), new WeekPeriodChoiser())),
    new Couple(DateType.ByMonth, new ChartOptions(new MonthChoisePeriod(), new MonthPeriodChoiser())),
    new Couple(DateType.OnYears, new ChartOptions(new YearChoisePeriod(), new YearPeriodChoiser())),
    )

export default chartPeriodDictionary;