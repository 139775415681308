import { HubConnectionState } from "@microsoft/signalr";
import BaseMessage from "../../Models/Chat/BaseMessage";
import Chat from "../../Models/Chat/Chat";
import Message from "../../Models/Chat/Message";
import PartMessage from "../../Models/Chat/PartMessages";
import ActionsConstants from "../ActionsConstants";
import BaseHubConnection, { IReconnectCallback } from "../BaseHubConnection";
import ActionChatHub from "./ActionsChatHub";
import ActionsChatHub from "./ActionsChatHub";
import IChatHub from "./IChatHub";

export interface IViewAction{
    (userName : string, message:Message):void
}


class ChatHub extends BaseHubConnection implements IChatHub{


    constructor(uri : string, token : string, reconnectCallback : IReconnectCallback){
        super(uri, token, reconnectCallback);
    }


    public async EnterChat(chatId : string) : Promise<string> {
        return await this.hubConnection.invoke<string>(ActionsChatHub.enterChat, chatId);
    }

    public async CreateChat(chat : Chat){
        return await this.hubConnection.invoke<string>(ActionChatHub.createChat, chat);
    }

    public async SendToChat(baseMessage : Message){
        return await this.hubConnection.invoke<string>(ActionsChatHub.sendToChat, baseMessage);
    }

    public async GetMessages(chatId : string){
        return await this.hubConnection.invoke<Array<Message>>(ActionsChatHub.getMessages, chatId);
    }

    public async LoadingOffsetMessages(partMessages : PartMessage){
        return await this.hubConnection.invoke<Array<BaseMessage>>(ActionsChatHub.loadingOffsetMessages, partMessages);
    }

    public async GetChats(){
        return await this.hubConnection.invoke<Array<Chat>>(ActionsChatHub.getChats);
    }

    public async GetMessageInRealTime(viewAction : IViewAction):Promise<void>{
        this.hubConnection.on(ActionsConstants.receive, (userName : string, message : Message) => {
            viewAction(userName, message);
        })
    }
}

export default ChatHub;