import RequestSignature from "../RequestProvider/RequestSignature";

class ReportsServicesConstants{

    public static readonly GetReportByWeek : RequestSignature = new RequestSignature("GET", "/api/v1.0/PersonalReport/GetReportDayOfWeek");

    public static readonly GetReportByMonth: RequestSignature = new RequestSignature("GET","/api/v1.0/PersonalReport/GetReportDayOfMonth");

    public static readonly GetReportDayOfYear : RequestSignature = new RequestSignature("GET", "​/api/v1.0/PersonalReport/GetReportDayOfYear");

}

export default ReportsServicesConstants;