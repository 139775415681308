import signalR, * as SignalR from "@microsoft/signalr";
import {MessagePackHubProtocol, MessagePackOptions} from "@microsoft/signalr-protocol-msgpack"
import Message from "../Models/Chat/Message";

import ActionsConstants from "./ActionsConstants";

export interface IErrorDelegate{
    (message : string):void
}

export interface INotifyDelegate {
    (notify : string) : void

}

export interface IReconnectCallback{
    () : Promise<void>
}

class BaseHubConnection{

    protected readonly hubConnection : SignalR.HubConnection;


    constructor(uriAddres : string, token : string, reconnectionCallback : IReconnectCallback ){
        this.hubConnection = this.GetHub(uriAddres, token);
        this.hubConnection.onclose(() => {
            setTimeout(reconnectionCallback,0);
        });
        
    }

    protected GetHub(uriAddres : string, myToken:string) : SignalR.HubConnection{
        let optionsHeader : SignalR.MessageHeaders = {
            "Bearer" : myToken
        }
        return new SignalR.HubConnectionBuilder()
            .withUrl(uriAddres, 
                {
                    skipNegotiation : true,
                    accessTokenFactory : () => myToken,
                    transport: SignalR.HttpTransportType.WebSockets,
                    headers : optionsHeader
                })
            //.withHubProtocol(new MessagePackHubProtocol())
            .build()
    }

    public async OpenConnection() : Promise<void> {
        try{
            await this.hubConnection.start();
        }
        catch(e){
            throw e;
        }
    }

    public Init(errorAction:IErrorDelegate,notifyAction:INotifyDelegate):void{
        this.hubConnection.on(ActionsConstants.error,errorAction);
        this.hubConnection.on(ActionsConstants.notify,notifyAction);
    }

    public async CloseConnection() : Promise<void>{
        return await this.hubConnection.stop();
    }

}

export default BaseHubConnection;