import BaseUser from "../Models/Users/BaseUser";
import LocalStorageKeys from "./LocalStorageKeys";

class UserWorkerStorage{

    public GetUserFromLocalStorage() : BaseUser {
        let userStr = localStorage.getItem(LocalStorageKeys.user);
        if(userStr){
            let user = JSON.parse(userStr) as BaseUser;
            return user;
        }
        else {
            throw new Error("user is not exist in local storage");
        }
    }

    public WriteUserToLocalStorage(user : BaseUser) : void {
        let userStr = JSON.stringify(user);
        localStorage.setItem(LocalStorageKeys.user, userStr);
    }

}

export default UserWorkerStorage;