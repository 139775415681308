import TextProvider from "../../Helpers/TextProvider";
import BaseUser from "./BaseUser";
import Country from "./Country";
import CountyTimeZones from "./CountyTimeZones";
import FamilyStatus from "./FamilyStatus";
import Gender from "./Gender";
import Language from "./Language";
import TimeZone from "./TimeZone";

class FullUserInfo extends BaseUser {

    constructor(id :string,password:string,userName:string,email:string, evaChatId : string , isFirstStart : boolean = true ,langKey : Language = Language.Fr,
                gender : Gender, birthDate : string, countryOfResidence : string, countryISO : string, timezoneUtc : string, timezoneId : string, familyStatus : FamilyStatus,
                numberOfChildren : number, circleImage : string = ""){
        super(id, password, userName, email, evaChatId, isFirstStart, langKey, circleImage);
        this.gender = gender;
        this.birthDate = birthDate;
        this.countryOfResidence = countryOfResidence;
        this.countryISO = countryISO;
        this.timezoneUtc = timezoneUtc;
        this.timezoneId = timezoneId;
        this.familyStatus = familyStatus;
        this.numberOfChildren = numberOfChildren;
    }

    public gender : Gender;

    public birthDate : string;

    public countryOfResidence : string;

    public countryISO : string;

    public timezoneUtc : string;

    public timezoneId : string;

    public familyStatus : FamilyStatus;

    public numberOfChildren : number;

    

    public static getFullUserInfoDefault() {
        let userISO = TextProvider.DeclareLanguage();
        if(userISO == "en")
            userISO = "gb";
        let defaultFullUser = new FullUserInfo("", "", "", "", "", false,
                                                Language.En, Gender.NotFound, new Date(0).toLocaleDateString(),
                                                "", 
                                                "",
                                                "", 
                                                "", 
                                                FamilyStatus.NotFound,
                                                0);
        return defaultFullUser;
    }

}

export default FullUserInfo;