import ChartOption from "./ChartOption";

class ArrayChartOption{

    private chartOptions : Array<ChartOption>;

    public get GetGhartOptions(){
        return this.chartOptions;
    }

    constructor(chartOptions : Array<ChartOption>){
        this.chartOptions = chartOptions;
    }

    public GetMaxDataValue() : number {
        let maxValues = this.chartOptions.map(item => {
            return item.data.GetMaxValue();
        });
        let sortArr = maxValues.sort((a, b) => {
            return (a < b) ? 1 : -1;
        });
        return sortArr[0];

    }

    public GetMinDataValue() : number {
        let minValues = this.chartOptions.map(item => {
            return item.data.GetMinValue();
        });
        let sortArr = minValues.sort((a, b) => {
            return (a > b) ? 1 : -1;
        });
        return sortArr[0];
    }

}

export default ArrayChartOption;