import LinkPart from "../../Models/Link/LinkPart";
import LinkText from "../../Models/Link/LinkText";
import LanguagePhrase from "./LanguagePhrase"
import AppConstants from "../AppConstants";
import Language from "../../Models/Users/Language";

export default class EnglishPhrase extends LanguagePhrase{

    lang = Language.En;

    account="account";

    widgets="widgets";

    analysis="analysis";

    methodology="methodology";

    database="database";

    emptyArea="The field must be filled";

    shortPassword='Password length must be over 8 characters'

    uncorrectPassword='Password must contain at least one uppercase letter and one number '

    uncorrectEmail='Invalid e-mail'

    uncorrectPhoneNumber="Invalid phone number"

    firstName="First Name*"

    lastName="Last Name*"

    email="E-mail*"

    phoneNumber="Phone Number*"

    login="User name*"

    password="Password*"

    privacyPolicyAgree= [
        new LinkText("I accept "),
        new LinkPart("the User agreement ", AppConstants.getPolicyLink(this.lang)),
        new LinkText("and "),
        new LinkPart("the data Protection policy", AppConstants.getPolicyLink(this.lang)),
        new LinkText(", and certify that I am of legal age")
    ]

    privacyDataAgree = [new LinkText("I expressly consent that my data may be used in the datasets of WhyEva")]

    appUseConditions ="Terms and conditions for the application use"

    dataProtectionConditions ="Personal data protection rules"

    unsuccsesAuth="Incorrect email or password"

    duplicateUserName="This user name is already taken"

    duplicateEmail="This E-mail is already taken"

    signUpBut="Sign up"

    signInBut="Sign in"

    authorization="Authorization"

    registration="Registration"

    tensity="Tension"

    anxiety = "Evolution of tension"

    forecast = "Forecast"

    period = "By period"

    currentState = "Current state"

    dashBoard = "Dashboard"

    settings = "Settings"

    export = "Export"

    logOut = "Sign out"

    byMonth= "Month"

    byDay = "Day"

    byYear = "Year"

    byWeek = "Week"

    reportPhrase = "Generate Report"

    dateReport = "Report date"

    reportNotLoad = "The report has not been processed yet"

    users = "Users"

    balance = "Balance"

    tariff = "Tariff"

    dataControl = "Data Control"

    dateActivity = "Date of activity"

    position = "Position"

    name = "User Name"

    addNewUser = "Add a new user"

    buyNewUser = "You have no more licenses available. Here you can buy additional licenses"

    cancel = "Cancel"

    configurePlan = "Configure the plan"

    tariffName = "Tariff name";

    changeTariff = "Change the tariff";

    additionally = "Additionally";

    monthVersion = "Month Version";

    addTariff = "Connect";

    count = "Count";

    employee = "Employee";

    licenceDescription ="In addition 1 user";

    storage = "Storage";

    storageDescription = "Data size";

    notes = "Notes";

    addedDataSets = "Added DataSets";

    addDataSet = "Add DataSet";

    update = "Update";

    delete = "Delete";

    diskSpace = "Disk Space";

    userCount = "Employee Count";

    invalidFormatFile = "Invalid Format File";

    connected = "Connected";

    inProcessing = "In Processing";

    err = "Error";

    processed = "Processed";

    notConnected = "Not Connected";

    structures = "Structures";

    addStructure = "Add Structure";

    enterNameStructure = "Enter name structure";

    create = "Create";

    subDepartments = "Sub-departments";

    mainDepartment = "Main Department";

    updateNote = "Update Note";

    getDemoVersion = "Get Demo Version";

    areYou = "You are:";

    sucsessRegistrate = "You have successfully registered, check your email";

    siret = "SIRET";

    legalAddress = "Address";

    nameCompany = "Company name";

    officialSite = "your website";

    officiaPhoneNumber = " official phone number";

    sizeCompany = "The size of the company";

    private = "Private";

    public = "Public";

    professionalSector = " Professional Sector ";

    activity = "Activity";

    commercialCompany = " Commercial Company ";

    stateAndPublicStructures = " State and public structures ";

    scienceAndEducation = " Science and education ";

    more = "More";

    reason = "reason of registration";

    howDoYouKnow = "How did you find out about us";

    uncorrectWebSite = "Invalid web-site URL";

    person = "Person";

    company = "Company";

    health = "Health ";

    expertise = "Expertise";

    counselling = "Counselling";

    facebook = "Facebook";

    linkedIn = "LinkedIn";

    less = "Less";

    moreThen = "More than";

    areYouSureLogOut = "Are you sure, you want to Log out?";

    yes = "Yes";

    no = "No";

    firstRegDate = "1st registration date";

    secondRegDate = "2nd registration date";

    actionDate  = "Action Date";

    action = "Action";

    info = "Info";

    canceled = "Canceled";

    confirmed = "Confirmed";

    request = "Request";

    open = "Open";

    confirmEmail = "Check your email to verify your account";

    resetPassword = "Forgot the password?";

    resetPasswordPrompt = "You can set a new password on the mailbox of the specified user";

    dataManagment = "Data Management";

    readyData = "Available";

    notExistData = "Not available";

    inProcessData = "In process";

    blockedData = "Blocked";

    notAssigned = "Not assigned";

    readyDateFullText = "Data exist, processed and is available for users";

    notExistDataFullText = "There is no index data in the system";

    inProcessDateFullText = "Data is processed and is not available ";

    blockedDateFullText = "Data exist but no user has access to it";

    notAssignedFullText = "Data exist but access to it is not assigned to any of the users";

    numberDepartment = "Department number";

    reports = "Reports";

    selectHierarchy = "Choose the organization chart";

    hierarchies = "Organization charts";

    almostReadyOrNotInsufficientData = "The report is partially ready. The data is not availible for some departements";

    volume = "Volume";

    obv = "OBV";

    optimistic = "Best case scenario";

    real = "Most likely scenario";

    pessimistic = "Worst case scenario";

    averageByDeps = "Average by department";

    sma = "SMA";

    chat = "Chat";

    you = "You";

    evaBot = "Chatbot Eva";

    //new

    choiseTheAction = "Select a tast";

    greeting = "Hello! My name is Eva. Nice to meet you.\nI am here to find out what you think about different aspects of your everyday work life, and bring your best ideas to life.\nTime to time we will meet here to talk about important topics. This surveys are formed specifically for you, so please be honest and write full answers, where possible. Our exchange is fully confidential!\nClick the reply message to start the first survey.";

    responcePrompt = "Hello. It is nice to see you again.\nLet's get to today’s survey!";

    viewCommandPrompt = "During our conversation you can use special tasks. To see what is available, click on the button, that looks like this:";

    desriptionCommand = "Tasks:\nBack – to get back to the previous question\nSurvey – to start the survey from the beginning\nHelp – to show the tasks descriptions";

    back = "Back";

    repeat = "Survey";

    stop = "/Stop";

    help = "Help";

    pressToStart = "Click here to start:";

    commands = ["/Back","/Survey", "/Help"];

    invalidName = "The name can contain only latin or russian letters or numbers without spaces";

    chatBotEva = "Chatbot Eva";

    personalReport = "Personal report";

    personalInfo = "Рersonal information";

    interfaceLanguage = "Interface language";

    cancelBut = "Cancel";

    save = "Save";

    chatbotSettings = "Réglages de Chatbot";

    programVersion = "Program version";

    hello = "Hello";

    lookAtResults = "Take a look at your results.";

    recomendation = "Recommendations";

    weatherHint = "This illustration indicates your current state of mind. The scale consists of 5 values:";

    partlyCloudy = "Partly cloudy";

    stormy = "Stormy";

    cloudy = "Cloudy";

    calm = "Calm";

    sunny = "Sunny";

    dynamicsOfResults = "Dynamics of results";

    reportGeneration = "Report generation is being processed, please wait";

    promptAd = "Assistance in solving psychological problems.";

    firstPromptAd = "Psychologist assistance. Wide experience. Individual approach. 100%confidentiality.";

    secondPromptAd = "Psychologist services. Individual approach, secure video communication, anonimously.";

    thirdPromptAd = "Online sessions with psychologist. Accessible from any device or any place.";

    servicesBrain = "Services of psychologist";

    firstStormyRecomendation = "Your emotional state became a matter of attention. Whenever you have symptoms of nervous exhaustion, tension or depression, contact a medical specialists, who will examine your condition in detail, provide support and treatment.";

    secondStormyRecomendation = "Currently you are feeling emotional pressure or decline of activity. You need to pay attention to your mental health, perhaps you should talk to a friend and tell about your mood. In any case, you should seek help from a specialist or doctor.";

    thirdStormyRecomendation = "You are trying to do multiple things at a time, when it is hard to focus on one thing at once. It seems that situation goes out of control and you feel like crying. You have to do so many things, that there is no time for the key problems. If you are about to have a meltdown, then contact a doctor or a specialist as soon as possible, to receive medical treatment.";

    firstCloudyRecomendation = "Familiar with anxiety, that does not depend on any other life situations, you are feeling physically and emotionally drained, tired after the weekend. It is hard to concentrate on work, because of poverty of attention. Probably you need some help. Do not stay alone in your troubles. Try to discuss it with your close friend, family, or even better contact a specialist.";

    secondCloudyRecomendation = "You are often in a bad mood, lack of interest in work, loss of vigour, extreme fatigue and apathy. Perhaps it is due to a headache, general weakness or struggle to relax after work. Think what became a reason of your state of health and  under no circumstances do not hide it from others. Take a break for a couple of days, have a picnic with family and friends, also it is good to contact a specialist.";

    thirdCloudyRecomendation = "Everything bores you and nothing makes you happy. It is not pleasant to be tired and depressed, but it is a normal part of life. To control your mood, you may need to consider an excessive workload, reconsider your daily activities and diversify your hobbies. Don't see everything that happens as a failure - life is beautiful in all its forms! It might be worth to discussing with a specialist or see a friend.";

    firstPartlyCloudyRecomendation = "At the moment you are having a mood swing. If you lack positive moments and change, then look around, maybe there is a possibility that you have never seen before. If you have people to trust, then listen to their opinion.Your friends' advice will be useful too !";

    secondPartlyCloudyRecomendation = "Life changes all the time, whenever there's bad, there's always good. At these times, you may have doubts how to deal with it : wait, make decisions or take everything that happens as reason to think. Ups and downs occur to everyone. There is no life without change. Keep in mind that tomorrow is better than today. Do not forget to move from work to personal life, spend a weekend with friends and family, discover new sensations and positive moments.";

    thirdPartlyCloudyRecomendation = "Changes are coming soon, but that doesn't mean you accept them with much enthusiasm. Listen to your inner desires, find real goals. Remember that improvement in life requires some changes. If you can't make a choice or a long-awaited decision, seek advice from people you trust. Spend more time with your friends and family, go for a run, listen to your inner voice!";

    firstCalmRecomendation = "Perhaps you are facing a decision that will require extra effort, attention, and time. If you feel that events are developing beyond your control and the result is not obvious to you, then try to take your mind off and diversify your life, for example, by meeting with friends. Demonstrate your expertise and skills, then very soon you will be able to enjoy the fruits of your labours!";

    secondCalmRecomendation = "There comes a time in your life when you set yourself a new task at work or start a project. Everything will work out, just be patient. Having overcome obstacles in business, do not forget to rest, let your success develop progressively and keep confidence.";

    thirdCalmRecomendation = "Your business is going well ! Your work brings fulfilment. Even if there are some doubts, yet you are bound to have a tremendous success in life and happiness about the progress made. Find time to be with people you care about, go for a dinner together or to see movies. Organize a meeting with friends, that you haven't seen in a while or have a lunch with your colleagues.";

    firstSunnyRecomendation = "You are at the top of your activity, inspired by new ideas and things to do. This is a very productive time for you. Now what you have been working hard on is ready to bear long-awaited fruit. Don't stop, keep moving and achieve your goals. Don't forget to have fun, switch to hobbies, sports, and relax with your loved ones.";

    secondSunnyRecomendation = " Life is full of sunshine ! All your tasks are done with ease and joy. You have new ideas and projects coming up. Take the time for self-fulfilment. You can now take on new tasks, help your junior colleagues or improve your presentation skills. Develop in personal life, discover new hobbies, do some running, cooking or dancing.";

    thirdSunnyRecomendation = "You have found your place in the sun and you know exactly what you want from life. It gives you energy and you truly enjoy it. Self-confident, you are ready for personal fulfilment both at work and in your personal life. Try new hobbies, such as blogging, new sports or drawing. Find time for education and self-development.";

    isAdult = "I confirm that I am over 18 years old";

    confirmPassword = "Password confirmation*";

    passwordsNotEqual = "Passwords do not match";

    daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    birthDate = "Date of birth";

    femaleStatus = "Marital status";

    civilUnion = "Civil union";

    commonLawMarriage = "Common-law marriage";

    divorced = "Divorced";

    married = "Married";

    single = "Single";

    childCount = "Number of children";

    nextQuestion = "/Next question";

    timeZone = "Time zone";

    countryOfResidence = "Country of residence";

    widow = "Widow/Widower";

    sex = "Gender";

    male = "Male";

    female = "Female";

    notFillSettings = "I noticed you have not completed your profile. Please, add more info about yourself, so I can work better.";

    firstPrompt = "My goal is to evaluate your quality of life at work and help you improve it.\nWhen you log in to the application I will ask you about the different aspects of your professional life. You will find my feedback in the \"Report\" tab.\nOur exchange is confidential. So be sincere with me, so that I can help you the best I can!";

    start = "Let’s begin, Eva.";

    notReadyRecomendation = "Guideline generation is being processed, please wait";

    notSpecified = "Not specified";

    reportAboutError = "Connect to WhyEva";

    mandatoryFilling = "*please fill in all the required fields for a better function of chatbot";

    intellectualProperty = "Copyright " + '\u00A9' + " " + new Date().getFullYear() + " - Intellectual property of WhyEva. All rights reserved";

    skip = "Skip";

    skipQuestion = "Skip a question";

    loadImage = "Upload a photo";

    confirm = "Confirm";

    backNotAction = "Back";

    //new

    fullImagePrompt = "The selected area will be shown in your settings.";

    choiseTheFile = "Select a file";

    circleImagePrompt = "Select an area for small photos. The selected thumbnail will be used in chats and header.";

    choisePeriod = "Choose a period";

    enterYourText = "Please, type your answer";

    deleteAccount = "Delete account";

    deleteAccountConfirm = "Are you sure you want to delete your account?";

    brakeLink = "break the connection"

    connectionDate = "Date of connection to the system";

    connectionToOrganization = "Company/Date of connection";

    profileStatus = "Profile status";

    unLinkPrompt = "Are you sure you want to break the link?"

    registrationDate = "Date of registration"

    dataExchangeAgreement = "Acceptance of data exchange"
}
