class Country {

    constructor(id : string, countryCodeIso : string, countryCodeIso3 : string, nameRu : string, nameEn : string, nameFr : string){
        this.id = id;
        this.countryCodeIso = countryCodeIso;
        this.countryCodeIso3 = countryCodeIso3;
        this.nameRu = nameRu;
        this.nameEn = nameEn;
        this.nameFr = nameFr;
    }

    public id : string

    public countryCodeIso : string

    public countryCodeIso3 : string 

    public nameRu : string

    public nameEn : string

    public nameFr : string

    //get

    public get Id(){
        return this.id;
    }

    public get CountryCodeIso(){
        return this.countryCodeIso;
    }

    public get CountryCodeIso3(){
        return this.countryCodeIso3;
    }

    public get NameRu() {
        return this.nameRu;
    }

    public get NameEn(){
        return this.nameEn;
    }

    public get NameFr(){
        return this.nameFr;
    }

}

export default Country;