class RoutesUrl {

    public static SignUp = "/SignUp";

    public static SignIn = "/SignIn";

    public static ResetPassword = "/ResetPassword";

    public static Chat = "/Chat";

    public static Settings = "/Settings";

    public static PersonalReport = "/PersonalReport";

}

export default RoutesUrl;