import IStateContainerCallback from "../../Actions/IStateContainerCallback";
import IUserAction from "../../Actions/UserActions/IUserAction";
import UserActions from "../../Actions/UserActions/UserActions";
import AddUserImage from "../../Helpers/Strategies/UserImage/AddUserImage";
import UpdateUserImage from "../../Helpers/Strategies/UserImage/UpdateUserImage";
import UserImageBaseClass from "../../Helpers/Strategies/UserImage/UserImageBaseClass";
import AddFullUserProfile from "../../Helpers/Strategies/UserProfile/AddFullUserProfile";
import IUserProfile from "../../Helpers/Strategies/UserProfile/IUserProfile";
import UpdateFullUserProfile from "../../Helpers/Strategies/UserProfile/UpdateFullUserProfile";
import UserWorkerStorage from "../../LocalStorage/UserWorkerStorage";
import Couple from "../../Models/Collections/Couple";
import Dictionary from "../../Models/Collections/Dictionary";
import DataScopeLink from "../../Models/DataScopeLink/DataScopeLink";
import Action from "../../Models/Delegate/Action";
import BaseUser from "../../Models/Users/BaseUser";
import FullUserInfo from "../../Models/Users/FullUserInfo";
import UserImage from "../../Models/Users/UserImage";
import IProfileServices from "../../Services/Profile/IProfileServices";
import ProfileServices from "../../Services/Profile/ProfileServices";
import RequestProvider from "../../Services/RequestProvider/RequestProvider";
import ISettingsMiddlewares, {ILoadData } from "./ISettingsMiddlewares";

interface IUpgradeUser {
    (user : FullUserInfo) : Promise<void>
}

class SettingsMiddlewares implements ISettingsMiddlewares{

    private _profileServices : IProfileServices;

    private _userActions : IUserAction;

    private _userLocalStorageWorker : UserWorkerStorage;

    private _userProfileAction : IUserProfile;

    private _userProfileImage : UserImageBaseClass;

    constructor(){
        this._profileServices = new ProfileServices(new RequestProvider());
        this._userActions = new UserActions();
        this._userProfileAction = new UpdateFullUserProfile();
        this._userProfileImage = new UpdateUserImage(this._profileServices);
        this._userLocalStorageWorker = new UserWorkerStorage();
    }

    public async ModifyUserImage(userImage : UserImage) : Promise<void> {
        this._userProfileImage.ModifyUserImage(userImage, () => {alert("Размер фотографии не может превышать 6МБ, пожалуйста выберить изображение более низкого разрешения")})
            .then(_ => {
                let user = this._userLocalStorageWorker.GetUserFromLocalStorage();
                this._userLocalStorageWorker.WriteUserToLocalStorage(new BaseUser(user.userId, user.password, user.userName, user.email, 
                                                                         user.evaChatId, user.isFirstStart, user.languageType, userImage.circleImageBytes));
                window.location.reload();
            })
    }

    public async GetAll(loadCallback : ILoadData) : Promise<void> {
        let responces = await Promise.all([this._userActions.GetCountries(), 
                                           this._userActions.GetCountriesTimeZones(),
                                           this._userActions.GetUserProfile(),
                                           this._userActions.GetFullProvile(new Dictionary<number, Action<unknown>>(
                                               new Couple(400, () => {this._userProfileAction = new AddFullUserProfile();}))),
                                           this._profileServices.GetUserImage(new Dictionary<number, Action<unknown>>(
                                               new Couple(400, () => {this._userProfileImage = new AddUserImage(this._profileServices)})))
                                        ]);
        loadCallback(responces[0], responces[1], responces[2], responces[3], responces[4]);
    }

    public async UpdateUserInfo(fullUser : FullUserInfo, baseUserErrorHandler : IStateContainerCallback) : Promise<void> {
        await Promise.all([this._userProfileAction.UpgradeAccount(fullUser, this._userActions), this._userActions.UpdateUserInfo(fullUser, baseUserErrorHandler)]);
        return Promise.resolve();
    }

    public async DeleteUserAsync() : Promise<boolean> {
        return await this._userActions.DeleteUser();
    }

    public async GetLinkInfo() : Promise<DataScopeLink> {
        return await this._userActions.GetLinkInfo();
    }

    public async UnLink() : Promise<boolean> {
        return await this._userActions.UnLink();
    }
}

export default SettingsMiddlewares;