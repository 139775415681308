import React from "react";
import UserActions from "../../Actions/UserActions/UserActions";
import RoutesUrl from "../../Helpers/RoutesUrl";
import TextProvider from "../../Helpers/TextProvider";
import LanguageWorkerStorage from "../../LocalStorage/LanguageWorkerStorage";
import LocalStorageKeys from "../../LocalStorage/LocalStorageKeys";
import BaseUser from "../../Models/Users/BaseUser";
import Language from "../../Models/Users/Language";
import BaseContainer from "../BaseContainer/BaseContainer";
import SettingsUI from "./SettingsUI";
import Validator from "../../Helpers/Validator"
import Preloader from "../../Components/Preloader/Preloader";
import BadRequestAnswer from "../../Models/Exceptions/BadRequestAnswer";
import RegistrationException from "../../Models/Exceptions/RegistrationException";
import UserNameExceptions from "../../Models/Exceptions/UserNameExceptions";
import Gender from "../../Models/Users/Gender";
import ISettingsMiddlewares from "../../ContainersMiddlewares/SettingsMiddlewares/ISettingsMiddlewares";
import SettingsMiddlewares from "../../ContainersMiddlewares/SettingsMiddlewares/SettingsMiddlewares";
import Country from "../../Models/Users/Country";
import CountyTimeZones from "../../Models/Users/CountyTimeZones";
import FamilyStatus from "../../Models/Users/FamilyStatus";
import FullUserInfo from "../../Models/Users/FullUserInfo";
import TimeZone from "../../Models/Users/TimeZone";
import GuidGenerator from "../../Helpers/GuidGenetator";
import AppConstants from "../../Helpers/AppConstants";
import { languageEnum } from "../../Models/Collections/LanguageDictionary";
import UserImage from "../../Models/Users/UserImage";

import StandartPersomImg from "../../Media/Img/person.png";
import MessageObject from "../../Models/Exceptions/MessageObject";
import DataScopeLink from "../../Models/DataScopeLink/DataScopeLink";
import { unlink } from "fs";

export interface ISettings{
    login : string;
    email:string
    sex : Gender
    birthDate : Date
    familyStatus : FamilyStatus
    countryIso : string
    childCount : number
    timezoneId : string
    userImage : UserImage
    emailErrorPrompt:string
    loginErrorPrompt:string
    chatId : string
    language : Language
    isLoad : boolean

    allCountries : Array<Country>
    allTimeZones : Array<CountyTimeZones>
    
    deleteUser : () => void;
}

class Settings extends BaseContainer<{}, ISettings>{

    private _user : BaseUser | null = null;

    private _fullUser : FullUserInfo | null = null;

    private readonly _userActions = new UserActions();

    private readonly _languageWorkerStorage = new LanguageWorkerStorage();

    private readonly _settingMiddlewares : ISettingsMiddlewares;

    private nowCountryIso : string = ""; 

    private _allCountries : Array<Country> = [];

    private _allTimeZones : Array<CountyTimeZones> = [];

    //

    getCountryByCountryCodeIso = (codeIso : string) => {
        let codeIsoLowerCase = codeIso.trim().toLowerCase();
        return this._allCountries.find(item => item.countryCodeIso.toLowerCase().trim() == codeIsoLowerCase);
    }

    getAllTimezonesInCountries = (codeIso : string) => {
        let codeIsoLowerCase = codeIso.trim().toLowerCase();
        return this._allTimeZones.find(item => item.countryCodeIso.toLowerCase().trim() == codeIsoLowerCase);
    }
    //

    changeLanguage = (languageStr : string) => {
        let language : Language = Language[languageStr as keyof typeof Language];
        this._languageWorkerStorage.ChangeLanguage(language);
        this._userActions.ChangeLanguageAsync(language).then(_ => window.location.reload());
    }


    fillUserProfile = (user : BaseUser) => {
        this._user = user;
        this.setState({
            login : user.userName,
            email : user.email,
            sex : Gender.Male,
            chatId : user.evaChatId,
            language : user.languageType,
            isLoad : true
        })
    }

    constructor(){
        super(0);
        let user = localStorage.getItem(LocalStorageKeys.user);
        if(user == null || user == "null")
            window.location.href = RoutesUrl.SignIn;
        this._settingMiddlewares = new SettingsMiddlewares();
        this.state = {
            login : "",
            email : "",
            chatId : "",
            sex : Gender.Male,
            birthDate : new Date(),
            familyStatus : FamilyStatus.Single,
            countryIso : "",
            childCount : 0,
            userImage : new UserImage("", "", "", ""),
            timezoneId : "",
            loginErrorPrompt : "",
            emailErrorPrompt : "",
            language : Language.En,
            isLoad : false,
            allCountries : [],
            allTimeZones : [],
            deleteUser: this.deleteUser,
        }
    }

    _validator:Validator=new Validator();

    componentDidMount = () => {
        this._settingMiddlewares.GetAll(this.fillData);
    }


    fillData = (countries : Array<Country>, 
                countriesTimeZones : Array<CountyTimeZones>,
                user : BaseUser,
                fullUserProfile : FullUserInfo,
                userImage : UserImage) => {

        this._allCountries = countries;

        this._allTimeZones = countriesTimeZones;

        const oldLanguage = TextProvider.GetEnumLanguage();
        const language = user.languageType;
        if (oldLanguage != language) {
            this._languageWorkerStorage.ChangeLanguage(language);
            const localUser = JSON.parse(localStorage.getItem('user') ?? '') as BaseUser;
            localUser.languageType = language;
            localStorage.setItem('user', JSON.stringify(localUser));

            window.location.reload();
        }

        let baseUser = (user != null && user != undefined) ? user 
                                                           : new BaseUser("","","","","", true, Language.En);
        
        this._user = baseUser;

        let fullUser : FullUserInfo;

        if(fullUserProfile != null && fullUserProfile != undefined){
            fullUser = fullUserProfile;
        }
        else{
            fullUser = FullUserInfo.getFullUserInfoDefault();
        }

        this._fullUser = fullUser;

        let nowTimeZones = this.getAllTimezonesInCountries(fullUser.countryISO);

        if(nowTimeZones && nowTimeZones.timeZoneCollection.length !=0 ){
            countriesTimeZones = [nowTimeZones];
        }

        let userImageModel = (userImage != null && userImage != undefined) ? userImage 
                                                                      : new UserImage("", user.userId,  StandartPersomImg, StandartPersomImg); 
        
        this.setState({allCountries : countries, 
                        allTimeZones : countriesTimeZones,
                        login : baseUser.userName,
                        email : baseUser.email,
                        sex : fullUser.gender,
                        chatId : baseUser.evaChatId,
                        language ,
                        userImage : userImageModel,
                        birthDate : new Date(fullUser.birthDate),
                        familyStatus : fullUser.familyStatus,
                        childCount : fullUser.numberOfChildren,
                        countryIso : fullUser.countryISO,
                        timezoneId : fullUser.timezoneId,
                        isLoad : true,  
                      });
    }

    changeName = (value:string,name:string) => {
        let errorPromptValue:string="";
        if(this._validator.isEmpty(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().emptyArea;
        if(!this._validator.isValidName(value))
            errorPromptValue = TextProvider.GetPhrase().invalidName;
        this.setState({login : value, loginErrorPrompt : errorPromptValue});
    }

    changeEmail=(value:string,name:string="email") => {
        let errorPromptValue:string="";
        if(!this._validator.IsEmailTemplate(value.trim()))
            errorPromptValue=TextProvider.GetPhrase().uncorrectEmail;
        this.setState({email : value, emailErrorPrompt : errorPromptValue});
    }

    changeSex = (sexStr: string , name : string = "") => {
        let sex : Gender =  Gender[sexStr as keyof typeof Gender];
        this.setState({sex});
    }

    changeBirthDate = (birthDate : Date) => {
        this.setState({birthDate});
    }

    changeFamilyStatus = (familyStatusStr : string, name : string = "") => {
        let familyStatus : FamilyStatus = FamilyStatus[familyStatusStr as keyof typeof FamilyStatus];
        this.setState({familyStatus});
    }

    changeCountry = (countryIso : string, name : string = "") => {
        let nowTimeZones = this.getAllTimezonesInCountries(countryIso);
        if(nowTimeZones){
            if(nowTimeZones.timeZoneCollection.length != 0){
                this.setState({countryIso, allTimeZones : [nowTimeZones], timezoneId : nowTimeZones.timeZoneCollection[0].id});
            }
            else{
                this.setState({countryIso, allTimeZones : this._allTimeZones, timezoneId : this._allTimeZones[0].id});
            }
        }
        else{
            this.setState({countryIso});
        }
    }

    changeChildCount = (childCountStr : string, name : string = "") => {
        let childCount = +childCountStr;
        this.setState({childCount})
    }

    changeTimezone = (value : string, name : string) => {
        this.setState({timezoneId : value});
    }

    isValidValueAreas = ():boolean => {
        let result:boolean=false;
        if(this.isEmptyErrorPrompts())
            if(!this.isFullValuesState())
                this.WarnUser();
            else
                result=true;
        return result;
    }

    isEmptyErrorPrompts = ():boolean => {
        return (this.state.emailErrorPrompt == "" 
            && this.state.loginErrorPrompt == "");
    }

    isFullValuesState=():boolean => {
        return (this.state.email.trim() !="" 
                && this.state.login.trim() !="");
    }

    WarnUser=() => {
        let textProvider=TextProvider.GetPhrase();
        if(this.state.email=="")
            this.setState({emailErrorPrompt:textProvider.emptyArea})
        if(this.state.login=="")
            this.setState({loginErrorPrompt:textProvider.emptyArea})
    }

    stateContainerCallback=(obj : unknown) => {
        this.setState({isLoad : true});
        let answer:BadRequestAnswer=obj as BadRequestAnswer;
        let regExp:RegistrationException | UserNameExceptions | MessageObject | string = answer.data;
        let textProvider=TextProvider.GetPhrase();

        let registrationException = regExp as RegistrationException;
        let userNameExceptions = regExp as UserNameExceptions;
        if(registrationException.DuplicateEmail!=undefined)
            this.setState({emailErrorPrompt:textProvider.duplicateEmail})
        if(registrationException.DuplicateUserName!=undefined)
            this.setState({loginErrorPrompt:textProvider.duplicateUserName})
        if(userNameExceptions.InvalidUserName != undefined)
            this.setState({loginErrorPrompt : userNameExceptions.InvalidUserName});
    }

    changeUserProfile = () => {
        if(this.isValidValueAreas()){
            let countryOfResidence = this.getCountryByCountryCodeIso(this.state.countryIso);
            let countryTimeZone = this.getAllTimezonesInCountries(this.state.countryIso);
            if(!countryTimeZone || countryTimeZone.timeZoneCollection.length == 0) 
                countryTimeZone = this._allTimeZones.find(item => item.id == new GuidGenerator().GenerateZeroGuid())
            let timeZoneUTC = countryTimeZone?.timeZoneCollection.find(item => item.id = this.state.timezoneId);
            let dif = this.state.birthDate.getTimezoneOffset();
            let date = new Date(this.state.birthDate.getFullYear(), this.state.birthDate.getMonth(), this.state.birthDate.getDate(), this.state.birthDate.getHours(),
                                this.state.birthDate.getMinutes() - dif);
            let user = new FullUserInfo(this._user?.userId as string, 
                this._user?.password as string, 
                this.state.login, 
                this.state.email, 
                this._user?.evaChatId as string,
                this._user?.isFirstStart, 
                this.state.language,
                this.state.sex,
                date.toUTCString(),
                countryOfResidence?.nameEn as string,
                this.state.countryIso,
                timeZoneUTC ? timeZoneUTC.utc : '',
                this.state.timezoneId,
                this.state.familyStatus,
                this.state.childCount, 
                this.state.userImage.circleImageBytes);
            this.setState({isLoad : false});
            this._settingMiddlewares.UpdateUserInfo(user, this.stateContainerCallback)
                    .finally(() => {
                        let userJSON = JSON.stringify(user);
                        localStorage.setItem(LocalStorageKeys.user, userJSON);
                        window.location.reload();
                    })
        }
    }

    rollbackChanges = () => {
        if(this.fillUserProfile != null){
            this.setState({
                sex: this._fullUser?.gender as number,
                chatId: this._fullUser?.evaChatId as string,
                birthDate: new Date(this._fullUser?.birthDate as string),
                familyStatus: this._fullUser?.familyStatus as number,
                childCount: this._fullUser?.numberOfChildren as number,
                countryIso: this._fullUser?.countryISO as string,
                timezoneId: this._fullUser?.timezoneId as string,
                emailErrorPrompt : "",
                loginErrorPrompt : ""
            })
        }
        this.setState({login : this._user?.userName as string, email : this._user?.email as string});
    }

    modifyImage = (userImage : UserImage) => {
        this.setState({isLoad : false});
        this._settingMiddlewares.ModifyUserImage(userImage);
    }

    deleteUser = () => {
        this._settingMiddlewares.DeleteUserAsync().then(result => {
            if (result)
                window.location.href = RoutesUrl.SignIn;
        })
    }

    getLinkInfo = () : Promise<DataScopeLink> => {
        return this._settingMiddlewares.GetLinkInfo();
    }

    unLink = () : Promise<boolean> => {
        return this._settingMiddlewares.UnLink();
    }

    render(){
        let content = <Preloader/>;
        if(this.state.isLoad)
            content = <SettingsUI
                    modifyUserAction = {this.modifyImage}
                    state = {this.state}
                    changeEmailAction={this.changeEmail}
                    changeNameAction = {this.changeName}
                    changeLanguage = {this.changeLanguage}
                    changeSex = {this.changeSex}
                    changeBirthDate = {this.changeBirthDate}
                    changeFamilyStatus = {this.changeFamilyStatus}
                    changeCountry = {this.changeCountry}
                    changeChildCount = {this.changeChildCount}
                    changeTimezone = {this.changeTimezone}
                    textProvider = {TextProvider.GetPhrase()}
                    backUri = {RoutesUrl.Chat + "/" + this._user?.evaChatId}
                    changeUserProfile = {this.changeUserProfile}
                    rollbackChanges = {this.rollbackChanges}
                    isValidValueAreas = {this.isValidValueAreas}
                    getLinkInfo = {this.getLinkInfo}
                    unLink = {this.unLink}
               />
        return content;
    }
}

export default Settings;