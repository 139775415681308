class ResponceCase{

    public id : string;

    public value : number

    public prompt : string;

    constructor(id : string, value : number, prompt : string ){
        this.id = id;
        this.value = value;
        this.prompt = prompt;
    }

}

export default ResponceCase;