import Couple from "../../Models/Collections/Couple";
import Dictionary from "../../Models/Collections/Dictionary";
import Action from "../../Models/Delegate/Action";
import Session from "../../Models/Session/Session";
import BaseUser from "../../Models/Users/BaseUser";
import BaseService from "../BaseService";
import RequestSettings from "../RequestProvider/RequestSettings";
import ISessionServices from "./ISessionServices";
import SessionServicesConstants from "./SessionServicesConstants";

class SessionServices extends BaseService implements ISessionServices{

    public async GetCurrentSession(chatId : string, actions : Dictionary<number, Action<unknown>>) : Promise<Session>{
        let requestSettings = new RequestSettings(
            SessionServicesConstants._getCurrentSession,
            new Dictionary<string,string>(new Couple("chatId", chatId)),
            null);

        let session = await this.RequestProvider.RequestWithResponceAsync(requestSettings, actions) as Session;
        return session;
    }

    public async UpdateSession(session : Session) : Promise<void>{
        let requestSettings = new RequestSettings(
            SessionServicesConstants._updateSession,
            new Dictionary<string,string>(),
            session);

        return await this.RequestProvider.RequestAsync(requestSettings);
    }

}

export default SessionServices;