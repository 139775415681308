import React from "react"

import "./Button.css"

interface IButton{
    prompt:string
    clickAction():void
    isAgree:boolean
}

function Button(props:IButton){
    let className:string="DisabledButton";
    if(props.isAgree)
        className="Button"
    
    const onClick=() => {
        if(props.isAgree)
            props.clickAction();
    }

    return(<div onClick={onClick} className={className}>
                {props.prompt}
          </div>)
}

export default Button;