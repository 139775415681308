import React, { useEffect } from "react"

import MessageBlock from "../MessageBlock/MessageBlock";
import SelectMessage from "../SelectMessage/SelectMessage";

import User from "../../Models/User";
import Message from "../../Models/Chat/Message";


import "./ChatArea.css"
import ResponceCase from "../../Models/Chat/ResponceCase";
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase";
import BaseUser from "../../Models/Users/BaseUser";
import ControlCommand from "../../Models/Chat/ControlCommand";
import CheckUserProfileModel from "../../Models/Users/CheckUserProfileModel";

interface IChatArea{
    checkUserProfileModel : CheckUserProfileModel
    preMessages : Array<Message>
    postMessages : Array<Message>
    messagesTape:Array<Message>
    commandActionHandler(command : ControlCommand, nameCommand : string): void
    user : BaseUser
    textProvider : LanguagePhrase
    isCommandAction : boolean
}

function ChatArea(props:IChatArea){

    let chatTape:Array<any>=[];

    const [isFirst,useisFirst]=React.useState(true);
    const [lastChatHeight,uselastChatHeight]=React.useState(0);


    let nowUserName:string= props.textProvider.evaBot;  

    let messages = props.preMessages.concat(props.messagesTape.concat(props.postMessages));


    messages.map((item:Message) => {
        let name:string='other';
        if(item.ownerId== props.user.userId){
            name='user';
            nowUserName = props.user.userName
        }
        else{
            nowUserName = props.textProvider.evaBot;
            name = "other";
        }

        chatTape.push(<MessageBlock
                        message={item}       
                        userName={nowUserName}
                        name={name}
                      />)
    });

        if(props.isCommandAction){
            const actions = [new ResponceCase("", ControlCommand.BackQuestion, props.textProvider.back ),
                            new ResponceCase("", ControlCommand.StartOver, props.textProvider.repeat),
                            new ResponceCase("", ControlCommand.Help, props.textProvider.help),]
            chatTape.push(<SelectMessage
                            headerSelectMessage = {props.textProvider.choiseTheAction}
                            cases = {actions}
                            choiseAction = {props.commandActionHandler}   
                        />)
            }

    const ScrollToDown=() => {
        let textAreas:HTMLCollectionOf<Element>=document.getElementsByClassName("chatArea");
        let textArea:HTMLDivElement=textAreas[0] as HTMLDivElement;
        if(isFirst || lastChatHeight!=textArea.scrollHeight){
            uselastChatHeight(textArea.scrollHeight);
            textArea.scrollTop=textArea.scrollHeight;
            useisFirst(false);
        }
    }

    useEffect(ScrollToDown);

    return(<div className='chatArea'>
            <div className="insideChatArea">
                    {chatTape}
            </div>
        </div>)
}

export default ChatArea;