import React from "react";

interface IChangeBoolState {
    (value : boolean) : void
  }

export interface IUIState {
    isOpenMenu : boolean
    changeIsOpenState :IChangeBoolState
}


  
export interface IAppState {
    uiState : IUIState
  }
  
  const startState : IAppState = {
    uiState : {
      isOpenMenu : false,
      changeIsOpenState : (value : boolean) => {}
    }
  }
  
  const AppContext:React.Context<IAppState>=React.createContext(startState);

  export default AppContext;

  