import React from "react";

import "./PersonalReportRecomendationBlock.css";

interface IPersonalReportRecomendationBlock{
    heading : string
    text : string
}

function PersonalReportRecomendationBlock(props : IPersonalReportRecomendationBlock){
    return(<div className = "PersonalReportRecomendationBlock">
                <div className = "HeadingPersonalReportRecomendationBlock">{props.heading}</div>
                <div className = "TextPersonalReportRecomendationBlock">{props.text}</div>
           </div>);
}

export default PersonalReportRecomendationBlock;