class ChangePasswordUser {

    public userId : string;

    public newPassword : string;

    public token : string;

    constructor(id : string, password : string, token : string){
        this.userId = id;
        this.newPassword = password;
        this.token = token;
    }

}

export default ChangePasswordUser;