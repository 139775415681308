import RequestSignature from "../RequestProvider/RequestSignature";

class ProfileServicesConstants {

    public static readonly GetImage : RequestSignature = new RequestSignature("GET", "/api/v1.0/Profile/UserProfilePhoto");

    public static readonly UpdateImage : RequestSignature = new RequestSignature("PUT", "/api/v1.0/Profile/UserProfilePhoto");

    public static readonly CreateImage : RequestSignature = new RequestSignature("POST", "/api/v1.0/Profile/UserProfilePhoto");

    public static readonly DeleteImage : RequestSignature = new RequestSignature("DELETE", "/api/v1.0/Profile/UserProfilePhoto");

}

export default ProfileServicesConstants;