import React from "react";

import "./CommandButton.css";

import ActionImg from "../../Media/Img/actions.png";
import CloseImg from "../../Media/Img/activeActions.png";
import ControlCommand from "../../Models/Chat/ControlCommand";

interface ClickAction {
    () : void
    
}

interface ICommnadButton{
    clickAction : ClickAction
    isActive : boolean
}

function CommandButton(props : ICommnadButton){

    let className = "actionImage";
    let image = ActionImg;

    if(!props.isActive){
        className = "closeImage";
        image = CloseImg;
    }

    return(<div onClick = {props.clickAction} className = "commandButton">
                
                <img className = {className} src={image} />
                
          </div>)
}

export default CommandButton;