import IStateContainerCallback from "../../../Actions/IStateContainerCallback";
import IUserAction from "../../../Actions/UserActions/IUserAction";
import Action from "../../../Models/Delegate/Action";
import Language from "../../../Models/Users/Language";

class CommonUserRegistrationStrategy{

    private readonly _userActions : IUserAction;

    constructor(userActions : IUserAction) {
        this._userActions = userActions;
    }

    public async Registrate(id : string,
        password:string,
        login:string,
        email:string,
        sucsessRegCallback : IStateContainerCallback,
        errorRegCallback : Action<unknown>,
        langKey : Language) : Promise<void> {
            return this._userActions.RegistrateAsync(id, password, login, email, sucsessRegCallback, errorRegCallback, langKey);
        }
}

export default CommonUserRegistrationStrategy;