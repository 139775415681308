import RequestSignature from "../RequestProvider/RequestSignature";

class SessionServicesConstants{

    public static readonly _getCurrentSession : RequestSignature = new RequestSignature("POST", "​/api/v1.0/Session/CurrentSession");

    public static readonly  _updateSession : RequestSignature = new RequestSignature("PUT", "/api/v1.0/Session/CurrentSession");

}

export default SessionServicesConstants;