import React,{FormEvent} from "react"
import "./Input.css"
import Eye from "../../Media/Img/eye.png"
import ClosedEye from "../../Media/Img/closedEye.png"

export interface IInput{
    prompt:string
    imgPrompt:string
    value:string
    name:string
    changeValueAction(name:string,value:string):void
    errorPrompt:string
    autocomplete : boolean
    type : string
    colorBottomBorder : string
    isStateValue : boolean
    isActiveInput : boolean
}

function Input(props:IInput){

    let autocomplete= (props.autocomplete) ? "on" : "new-password";

    const [isFocus,useisFocus]=React.useState(false);

    const[isOpen,useisOpen]=React.useState(false);

    const onClickEye=() => {
        useisOpen(!isOpen);
    }

    let eye:any=null;
    let type:string=props.type;

    if(type=="password" && props.isActiveInput){
        if(isOpen){
            eye=<div onClick={onClickEye} className="eyeInput"><img src={Eye}></img></div>;
            type="default"
        }
        else{
            eye=<div onClick={onClickEye} className="eyeInput"><img src={ClosedEye}></img></div>
            type='password'
        }
    }

    let inputPrompt=null;

    if(!isFocus && props.value == ""){
        inputPrompt=(<div className="InputPlaceholder">
                        
                        <div>{props.prompt}</div>
                    </div>);
    }

    const onFocus=() => {
        useisFocus(true);
    }

    const onBlur=() => {
        if(props.value=="")
            useisFocus(false);

    }

    const onChange=(e:FormEvent<HTMLInputElement>) => {
        let value=e.currentTarget.value;
        props.changeValueAction(value,props.name);
    }

    let inputClassName:string="InputArea";
    let errorPromptBlock=null;
    if(props.errorPrompt!=""){
        inputClassName="InValidInputArea"
        errorPromptBlock=<div id={props.name+"error"} className="ErrorPromptInput">{props.errorPrompt}</div>
    }

    let style : React.CSSProperties = {
        borderBottom : "0.1vw solid " + props.colorBottomBorder,
    }


    let inputTag = <input min = "0" disabled = {!(props.isActiveInput)} style={style} id= {props.name+"input"} autoComplete={autocomplete} type={type} value={props.value} className={inputClassName} onFocus={onFocus} onBlur={onBlur} onChange={onChange}></input>
    if(!props.isStateValue)
        inputTag = <input disabled = {!(props.isActiveInput)} style = {style} id= {props.name+"input"} autoComplete={autocomplete} type={type} className={inputClassName} onFocus={onFocus} onBlur={onBlur} onChange={onChange}></input>

    return (<React.Fragment>
            <div id={props.name+"div"} className={(props.isActiveInput) ? "Input" : "NotActiveInput"}>

                {eye}
                {inputPrompt}
                {inputTag}
                {errorPromptBlock}
            </div>
            </React.Fragment>)
}

export default Input;