import React from "react"

import Input from "../Input/Input"
import LanguagePhrase from "../../Helpers/LanguagePhrase/LanguagePhrase"

import "./DatascopeConnection.css"
import DataScopeLink from "../../Models/DataScopeLink/DataScopeLink"
import DataScopeLinkStatus from "../../Models/DataScopeLink/DataScopeLinkStatus"
import BaseUser from "../../Models/Users/BaseUser"

interface IDatascopeConnection {
    textProvider: LanguagePhrase,
    getLinkInfo() : Promise<DataScopeLink>,
    unLink() : Promise<boolean>,
}

function DatascopeConnection(props: IDatascopeConnection) {
    const getDate = (d : string) : string => {
        return `${d.substring(8, 10)}.${d.substring(5,7)}.${d.substring(0, 4)}`
    }

    props.getLinkInfo().then(result => {
        let button = document.getElementById('dataScopeLinkBreakButton');
        if (!result || result.status == DataScopeLinkStatus.Disconnected) {
            
            if (button) {
                button.style.color = 'lightgray'
            }
            return;
        }

        if (button)
            button.onclick = unLink;

        let companyDateinput = document.getElementById("dataScopeLinkCompanyDateinput");
        if (companyDateinput) {
            const name = result.companyName ? result.companyName + '/' : '';

            (companyDateinput as HTMLInputElement).value = name + getDate(result.postTime);
        }
    });

    const unLink = () : void => {
        if (!window.confirm(props.textProvider.unLinkPrompt))
            return;
        
        props.unLink().then(_ => {
            window.location.reload();
        })
    };

    return <div id="dataScopeLink" className="dataScopeLink">
                <div className="settingPageUserInfoContainer">
                    <div></div>
                    <div className="settingsPageForm">
                        <div className="dataScopeLinkHeader">{props.textProvider.profileStatus}</div>
                        <div className="coupleAreaSettings">
                            <div className = "inputContainerSettings dataScopeLinkSettings">
                                <div className="labelSettingsArea">{props.textProvider.registrationDate}</div>
                                <Input
                                    prompt = ""
                                    imgPrompt = ""
                                    value = {getDate((JSON.parse(localStorage.getItem('user') ?? "") as BaseUser).registrationDate)}
                                    name = "dataScopeLinkDate"
                                    changeValueAction = {(name:string,value:string):void => { }}
                                    errorPrompt = ""
                                    autocomplete = {false}
                                    type = ""
                                    colorBottomBorder = "#C4C4C4"
                                    isStateValue = {true}
                                    isActiveInput = {false} />
                            </div>
                            <div className = "inputContainerSettings dataScopeLinkSettings">
                                <div className="labelSettingsArea">{props.textProvider.dataExchangeAgreement}</div>
                                <Input
                                    prompt = ""
                                    imgPrompt = ""
                                    value = "-/-"
                                    name = "dataScopeLinkCompanyDate"
                                    changeValueAction = {(name:string,value:string):void => { }}
                                    errorPrompt = ""
                                    autocomplete = {false}
                                    type = ""
                                    colorBottomBorder = "#C4C4C4"
                                    isStateValue = {true}
                                    isActiveInput = {false} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dataScopeLinkBreakContainer">
                    <div id="dataScopeLinkBreakButton" className="dataScopeLinkBreak">{props.textProvider.brakeLink}</div>
                </div>
            </div>
}

export default DatascopeConnection