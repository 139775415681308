import ReportSentiment from "../../../../Models/Report/ReportsSentiment";
import IPeriodConverterStrategy from "./IPeriodConverterStrategy";

class MonthStrategy implements IPeriodConverterStrategy{

    public GetPeriod(report : ReportSentiment) : Array<Date> {

        let period : Array<Date> = [];

        var firstDate = new Date(report.firstDate);
        
        var secondDate = new Date(report.secondDate);


        if(firstDate < secondDate){
            let nowDate = firstDate;
            while(nowDate <= secondDate) {
                period.push(nowDate);
                nowDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1);
            };
        }

        return period;

    }

}

export default MonthStrategy;